import React, { Fragment, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  ArrowRight,
  ArrowLeft,
  Grid,
  Home,
  Paperclip,
  AlertOctagon,
  Table,
  Key,
  CreditCard,
  Lock,
  Image,
  Menu,
} from "react-feather";
import { Link, useParams } from "react-router-dom";
import configDB from "../../data/customizer/config";
import { DefaultLayout } from "../theme-customizer";
import { useTranslation } from "react-i18next";
import { FEATURE_LOCK_MESSAGE, TYPES, USER_ROLE } from "../../constant";
import { QrCode, RollerShadesSharp } from "@mui/icons-material";
import DatasetIcon from "@mui/icons-material/Dataset";
import WebAssetIcon from "@mui/icons-material/WebAsset";
import WebIcon from "@mui/icons-material/Web";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";

import CategoryIcon from "@mui/icons-material/Category";
import BroadcastOnHomeIcon from "@mui/icons-material/BroadcastOnHome";
import { Tooltip } from "@mui/material";
import { encryptNumber, encryptStr, setCookie } from "../../utils/helper";

const Sidebar = () => {
  const id = window.location.pathname.split("/").pop();
  const defaultLayout = Object.keys(DefaultLayout);
  const layout = id ? id : defaultLayout;

  const [margin, setMargin] = useState(0);
  const [width, setWidth] = useState(0);
  const { t } = useTranslation();
  const [sidebartoogle, setSidebartoogle] = useState(false);
  let { hwId } = useParams();

  const lockTabStyle = {
    background: "#d9d9d9",
  };

  const REDIRECT_PATH_NAMES = {
    POST_MAKER: "Post Maker",
    RESTAURANT_MENU: "Restaurant Menu",
    CRM: "CRM",
  };

  const MENUITEMS = [
    TYPES.BROKER === +localStorage.getItem("types") &&
    USER_ROLE.PAGE_USER === +localStorage.getItem("roleId")
      ? {
          menutitle: `Hello,` + localStorage.getItem("orgName"),

          Items: [
            {
              path: `${process.env.PUBLIC_URL}/${hwId}/BrokerPage/BrokerPage`,
              icon: BroadcastOnHomeIcon,
              title: "Broker",
              type: "link",
            },
            {
              path: `${process.env.PUBLIC_URL}/${hwId}/InquiryPage/InquiryPage`,
              icon: AlertOctagon,
              title: "Page Inquiry",
              type: "link",
            },
            {
              path: `${process.env.PUBLIC_URL}/${hwId}/BrokerIncentive/BrokerIncentive`,
              title: "Broker Incentive",
              type: "link",
            },
          ],
        }
      : TYPES.LANDING_PAGE === +localStorage.getItem("types") &&
        USER_ROLE.PAGE_USER === +localStorage.getItem("roleId")
      ? {
          menutitle: `Hello,` + localStorage.getItem("orgName"),

          Items: [
            {
              path: `${process.env.PUBLIC_URL}/${hwId}/PersonalPage/PersonalPage`,
              icon: Paperclip,
              title: "Landing Page",
              type: "link",
            },
            {
              path: `${process.env.PUBLIC_URL}/${hwId}/InquiryPage/InquiryPage`,
              icon: AlertOctagon,
              title: "Page Inquiry",
              type: "link",
            },
          ],
        }
      : 0 === +localStorage.getItem("isBrokerAllowed")
      ? {
          menutitle: `Hello,` + localStorage.getItem("orgName"),

          Items: [
            {
              path: `${process.env.PUBLIC_URL}/${hwId}/dashboard/ecommerce`,
              icon: Home,
              title: "Dashboard",
              type: "link",
            },
            {
              title: "QR / NFC",
              icon: QrCode,
              type: "sub",
              active: true,
              isAccess: localStorage.getItem("isQr"),
              children: [
                {
                  path: `${process.env.PUBLIC_URL}/${hwId}/PersonalPage/PersonalPage`,
                  title: localStorage.getItem("mainPageName")
                    ? localStorage.getItem("mainPageName")
                    : "Landing Page",
                  type: `${
                    localStorage.getItem("isQr") === "true" ? "link" : ""
                  }`,
                },
                {
                  path: `${process.env.PUBLIC_URL}/${hwId}/InquiryPage/InquiryPage`,
                  title: "Inquiry",
                  type: `${
                    localStorage.getItem("isQr") === "true" ? "link" : ""
                  }`,
                },
                // {
                //   path: `${process.env.PUBLIC_URL}/${hwId}/BrokerIncentive/BrokerIncentive`,
                //   title: "Broker Incentive",
                //   // type: `${
                //   //   localStorage.getItem("isQr") == "true" ? "link" : ""
                //   // }`,
                //   type: `link`,
                // },
              ],
            },
            {
              title: "Single Page Website",
              icon: CreditCard,
              type: "sub",
              active: true,
              isAccess: localStorage.getItem("isMiniWeb"),
              children: [
                {
                  path: `${process.env.PUBLIC_URL}/${hwId}/ByteCardBuilder`,
                  title: "Website Builder",
                  type: `${
                    localStorage.getItem("isMiniWeb") === "true" ? "link" : ""
                  }`,
                },
                {
                  path: `${process.env.PUBLIC_URL}/${hwId}/webInquiry`,
                  title: "Inquiry",
                  type: `${
                    localStorage.getItem("isMiniWeb") === "true" ? "link" : ""
                  }`,
                },
                {
                  path: `${process.env.PUBLIC_URL}/${hwId}/webOrder`,
                  title: "Orders",
                  type: `${
                    localStorage.getItem("isMiniWeb") === "true" ? "link" : ""
                  }`,
                },
              ],
            },
            {
              title: "Multi Page Website",
              icon: CreditCard,
              type: "sub",
              active: true,
              isAccess: localStorage.getItem("isMultiWeb"),
              children: [
                {
                  path: `${process.env.PUBLIC_URL}/${hwId}/multipage-ByteCardBuilder`,
                  title: "Multipage Website",
                  type: `${
                    localStorage.getItem("isMultiWeb") === "true" ? "link" : ""
                  }`,
                },
                {
                  path: `${process.env.PUBLIC_URL}/${hwId}/webInquiry`,
                  title: "Inquiry",
                  type: `${
                    localStorage.getItem("isMultiWeb") === "true" ? "link" : ""
                  }`,
                },
              ],
            },
            {
              title: REDIRECT_PATH_NAMES.POST_MAKER,
              icon: Image,
              type: "sub",
              active: true,
              isAccess: localStorage.getItem("isAutoPostMaker"),
              children: [
                {
                  path: "",
                  title: REDIRECT_PATH_NAMES.POST_MAKER,
                  type: `${
                    (localStorage.getItem("isAutoPostMaker") === "true"
                      ? "link"
                      : "",
                    20)
                  }`,
                },
              ],
            },
            {
              title: REDIRECT_PATH_NAMES.RESTAURANT_MENU,
              icon: Menu,
              type: "sub",
              active: true,
              isAccess: localStorage.getItem("isMenu"),
              children: [
                {
                  path: "",
                  title: REDIRECT_PATH_NAMES.RESTAURANT_MENU,
                  type: `${
                    localStorage.getItem("isMenu") === "true" ? "link" : ""
                  }`,
                },
                ,
                20,
              ],
            },
            {
              title: REDIRECT_PATH_NAMES.CRM,
              icon: Menu,
              type: "sub",
              active: true,
              isAccess: "true",
              children: [
                {
                  path: "",
                  title: REDIRECT_PATH_NAMES.CRM,
                  type: `${"true" === "true" ? "link" : ""}`,
                },
              ],
            },
            ,
            20,
          ],
        }
      : {
          menutitle: `Hello,` + localStorage.getItem("orgName"),

          Items: [
            {
              path: `${process.env.PUBLIC_URL}/${hwId}/dashboard/ecommerce`,
              icon: Home,
              title: "Dashboard",
              type: "link",
            },
            {
              title: "QR",
              icon: QrCode,
              type: "sub",
              active: true,
              children: [
                {
                  path: `${process.env.PUBLIC_URL}/${hwId}/PersonalPage/PersonalPage`,
                  title: localStorage.getItem("mainPageName")
                    ? localStorage.getItem("mainPageName")
                    : "Landing Page",
                  type: "link",
                },
                {
                  path: `${process.env.PUBLIC_URL}/${hwId}/BrokerPage/BrokerPage`,
                  title: localStorage.getItem("subPageName")
                    ? localStorage.getItem("subPageName")
                    : "Broker",
                  type: "link",
                },
                {
                  path: `${process.env.PUBLIC_URL}/${hwId}/InquiryPage/InquiryPage`,
                  title: "Inquiry",
                  type: "link",
                },
                {
                  path: `${process.env.PUBLIC_URL}/${hwId}/BrokerIncentive/BrokerIncentive`,
                  title: "Broker Incentive",
                  type: "link",
                },
              ],
            },
            {
              title: "Byte Card",
              icon: CreditCard,
              type: "sub",
              active: true,
              children: [
                {
                  path: `${process.env.PUBLIC_URL}/${hwId}/ByteCardBuilder`,
                  title: "ByteCard Builder",
                  type: "link",
                },
                {
                  path: `${process.env.PUBLIC_URL}/${hwId}/webInquiry`,
                  title: "Inquiry",
                  type: "link",
                },
                {
                  path: `${process.env.PUBLIC_URL}/${hwId}/webOrder`,
                  title: "Orders",
                  type: "link",
                },
              ],
            },
          ],
        },
  ];
  const SELLER_MENUITEMS = [
    {
      menutitle: "Hello, " + localStorage.getItem("orgName"),
      Items: [
        {
          path: `${process.env.PUBLIC_URL}/${hwId}/dashboard/ecommerce`,
          icon: Home,
          title: "Dashboard",
          type: "link",
        },
        {
          path: `${process.env.PUBLIC_URL}/${hwId}/Organization/Organization`,
          icon: Table,
          title: "Organization",
          type: "link",
        },
        {
          title: REDIRECT_PATH_NAMES.RESTAURANT_MENU,
          icon: Menu,
          type: "sub",
          active: true,
          isAccess: localStorage.getItem("isMenu"),
          children: [
            {
              path: "",
              title: REDIRECT_PATH_NAMES.RESTAURANT_MENU,
              type: `${
                localStorage.getItem("isMenu") === "true" ? "link" : ""
              }`,
            },
            ,
            20,
          ],
        },
        {
          title: REDIRECT_PATH_NAMES.POST_MAKER,
          icon: Image,
          type: "sub",
          active: true,
          isAccess: localStorage.getItem("isAutoPostMaker"),
          children: [
            {
              path: "",
              title: REDIRECT_PATH_NAMES.POST_MAKER,
              type: `${
                localStorage.getItem("isAutoPostMaker") === "true" ? "link" : ""
              }`,
            },
            ,
            20,
          ],
        },
        {
          title: REDIRECT_PATH_NAMES.CRM,
          icon: Menu,
          type: "sub",
          active: true,
          isAccess: localStorage.getItem("isMenu"),
          // isAccess: /** localStorage.getItem("isCRM") */ "true",
          children: [
            {
              path: "",
              title: REDIRECT_PATH_NAMES.CRM,
              type: `${
                localStorage.getItem("isMenu") === "true" ? "link" : ""
              }`,
            },
            ,
            20,
          ],
        },
        {
          title: "plan master",
          icon: DatasetIcon,
          type: "sub",
          active: true,
          children: [
            {
              path: `${process.env.PUBLIC_URL}/${hwId}/PlanMaster/PlanMaster`,
              title: "plan master",
              type: "link",
            },
            {
              path: `${process.env.PUBLIC_URL}/${hwId}/OrgPlan/OrgPlan`,
              title: "Org Plan",
              type: "link",
            },
          ],
        },
        {
          path: `${process.env.PUBLIC_URL}/${hwId}/PageItemMaster/PageItemMaster`,
          icon: CategoryIcon,
          title: "Page Item Master",
          type: "link",
        },

        {
          title: "Theme Master",
          icon: AutoAwesomeMotionIcon,
          type: "sub",
          active: true,
          children: [
            {
              path: `${process.env.PUBLIC_URL}/${hwId}/ThemeMap/ThemeMap`,
              title: "Theme Map",
              type: "link",
            },
            {
              path: `${process.env.PUBLIC_URL}/${hwId}/Theme/Theme`,
              title: "Theme",
              type: "link",
            },
          ],
        },
        {
          title: "Single Web",
          icon: WebAssetIcon,
          type: "sub",
          active: true,
          children: [
            {
              path: `${process.env.PUBLIC_URL}/${hwId}/SinglePage/CloneCategory`,
              title: "Clone category",
              type: "link",
            },
            {
              path: `${process.env.PUBLIC_URL}/${hwId}/SinglePage/WebCategoryMap`,
              title: "Web Category Map",
              type: "link",
            },
          ],
        },
        {
          title: "Multi Web",
          icon: WebIcon,
          type: "sub",
          active: true,
          children: [
            {
              path: `${process.env.PUBLIC_URL}/${hwId}/MultiPage/CloneCategory`,
              title: "Clone category",
              type: "link",
            },
            {
              path: `${process.env.PUBLIC_URL}/${hwId}/MultiPage/WebCategoryMap`,
              title: "Web Category Map",
              type: "link",
            },
            {
              path: `${process.env.PUBLIC_URL}/${hwId}/MultiPage/FontList`,
              title: "Font List",
              type: "link",
            },
          ],
        },
        {
          path: `${process.env.PUBLIC_URL}/${hwId}/PrivillageManager/PrivillageManager`,
          title: "Privillage Manager",
          icon: Key,
          type: "link",
        },
        {
          path: `${process.env.PUBLIC_URL}/${hwId}/ModualManager/ModualManager`,
          title: "Module Manager",
          icon: RollerShadesSharp,
          type: "link",
        },
      ],
    },
  ];
  const [mainmenu, setMainMenu] = useState(MENUITEMS, SELLER_MENUITEMS);
  const wrapper =
    useSelector((content) => content.Customizer.sidebar_types.type) ||
    configDB.data.settings.sidebar.type;
  const handleScroll = () => {
    if (window.scrollY > 400) {
      if (
        configDB.data.settings.sidebar.type.split(" ").pop() ===
          "material-type" ||
        configDB.data.settings.sidebar.type.split(" ").pop() ===
          "advance-layout"
      )
        document.querySelector(".sidebar-main").className =
          "sidebar-main hovered";
    } else {
      if (document.getElementById("sidebar-main"))
        document.querySelector(".sidebar-main").className = "sidebar-main";
    }
  };
  useEffect(() => {
    document.querySelector(".left-arrow").classList.add("d-none");

    window.addEventListener("resize", handleResize);
    handleResize();
    const currentUrl = window.location.pathname;
    MENUITEMS.map((items) => {
      items.Items.filter((Items) => {
        if (Items.path === currentUrl) setNavActive(Items);
        if (!Items.children) return false;
        Items.children.filter((subItems) => {
          if (subItems.path === currentUrl) setNavActive(subItems);
          if (!subItems.children) return false;
          subItems.children.filter((subSubItems) => {
            if (subSubItems.path === currentUrl) {
              setNavActive(subSubItems);
              return true;
            } else {
              return false;
            }
          });
          return subItems;
        });
        return Items;
      });
      return items;
    });

    SELLER_MENUITEMS.map((items) => {
      items.Items.filter((Items) => {
        if (Items.path === currentUrl) setNavActive(Items);
        if (!Items.children) return false;
        Items.children.filter((subItems) => {
          if (subItems.path === currentUrl) setNavActive(subItems);
          if (!subItems.children) return false;
          subItems.children.filter((subSubItems) => {
            if (subSubItems.path === currentUrl) {
              setNavActive(subSubItems);
              return true;
            } else {
              return false;
            }
          });
          return subItems;
        });
        return Items;
      });
      return items;
    });

    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [layout, mainmenu]);

  const handleResize = () => {
    setWidth(window.innerWidth - 500);
  };

  const setNavActive = (item) => {
    MENUITEMS.map((menuItems) => {
      menuItems.Items.filter((Items) => {
        if (Items !== item) {
          Items.active = false;
          document.querySelector(".bg-overlay1").classList.remove("active");
        }
        if (Items.children && Items.children.includes(item)) {
          Items.active = true;
          document.querySelector(".sidebar-link").classList.add("active");
        }
        if (Items.children) {
          Items.children.filter((submenuItems) => {
            if (submenuItems.children && submenuItems.children.includes(item)) {
              Items.active = true;
              submenuItems.active = true;
              return true;
            } else {
              return false;
            }
          });
        }
        return Items;
      });
      return menuItems;
    });
    SELLER_MENUITEMS.map((menuItems) => {
      menuItems.Items.filter((Items) => {
        if (Items !== item) {
          Items.active = false;
          document.querySelector(".bg-overlay1").classList.remove("active");
        }
        if (Items.children && Items.children.includes(item)) {
          Items.active = true;
          document.querySelector(".sidebar-link").classList.add("active");
        }
        if (Items.children) {
          Items.children.filter((submenuItems) => {
            if (submenuItems.children && submenuItems.children.includes(item)) {
              Items.active = true;
              submenuItems.active = true;
              return true;
            } else {
              return false;
            }
          });
        }
        return Items;
      });
      return menuItems;
    });
    item.active = !item.active;
    setMainMenu({ mainmenu: MENUITEMS || SELLER_MENUITEMS });
  };

  const toggletNavActive = (item) => {
    if (window.innerWidth <= 991) {
      document.querySelector(".page-header").className =
        "page-header close_icon";
      document.querySelector(".sidebar-wrapper").className =
        "sidebar-wrapper close_icon ";
      document
        .querySelector(".mega-menu-container")
        .classList.remove("d-block");
      if (item.type === "sub") {
        document.querySelector(".page-header").className = "page-header ";
        document.querySelector(".sidebar-wrapper").className =
          "sidebar-wrapper ";
      }
    }

    if (!item.active) {
      MENUITEMS.map((a) => {
        a.Items.filter((Items) => {
          if (a.Items.includes(item)) Items.active = false;
          if (!Items.children) return false;
          Items.children.forEach((b) => {
            if (Items.children.includes(item)) {
              b.active = false;
            }
            if (!b.children) return false;
            b.children.forEach((c) => {
              if (b.children.includes(item)) {
                c.active = false;
              }
            });
          });
          return Items;
        });
        return a;
      });
      SELLER_MENUITEMS.map((a) => {
        a.Items.filter((Items) => {
          if (a.Items.includes(item)) Items.active = false;
          if (!Items.children) return false;
          Items.children.forEach((b) => {
            if (Items.children.includes(item)) {
              b.active = false;
            }
            if (!b.children) return false;
            b.children.forEach((c) => {
              if (b.children.includes(item)) {
                c.active = false;
              }
            });
          });
          return Items;
        });
        return a;
      });
    }

    item.active = !item.active;
    setMainMenu({ mainmenu: MENUITEMS || SELLER_MENUITEMS });
  };

  const scrollToRight = () => {
    if (margin <= -2598 || margin <= -2034) {
      if (width === 492) {
        setMargin(-3570);
      } else {
        setMargin(-3464);
      }
      document.querySelector(".right-arrow").classList.add("d-none");
      document.querySelector(".left-arrow").classList.remove("d-none");
    } else {
      setMargin((margin) => (margin += -width));
      document.querySelector(".left-arrow").classList.remove("d-none");
    }
  };

  const scrollToLeft = () => {
    if (margin >= -width) {
      setMargin(0);
      document.querySelector(".left-arrow").classList.add("d-none");
      document.querySelector(".right-arrow").classList.remove("d-none");
    } else {
      setMargin((margin) => (margin += width));
      document.querySelector(".right-arrow").classList.remove("d-none");
    }
  };

  const closeOverlay = () => {
    document.querySelector(".bg-overlay1").classList.remove("active");
    document.querySelector(".sidebar-link").classList.remove("active");
  };

  const activeClass = (menuActiveClass) => {
    if (menuActiveClass === true) {
      document.querySelector(".sidebar-link").classList.add("active");
      document.querySelector(".bg-overlay1").classList.add("active");
    } else {
      document.querySelector(".sidebar-link").classList.remove("active");
      document.querySelector(".bg-overlay1").classList.remove("active");
    }
  };

  const openCloseSidebar = (toggle) => {
    if (toggle) {
      setSidebartoogle(!toggle);
      document.querySelector(".page-header").className =
        "page-header close_icon";
      document.querySelector(".sidebar-wrapper").className =
        "sidebar-wrapper close_icon ";
    } else {
      setSidebartoogle(!toggle);
      document.querySelector(".page-header").className = "page-header";
      document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper ";
    }
  };

  const responsiveSidebar = () => {
    document.querySelector(".page-header").className = "page-header close_icon";
    document.querySelector(".sidebar-wrapper").className =
      "sidebar-wrapper close_icon";
  };

  return (
    <Fragment>
      <div
        className={`bg-overlay1`}
        onClick={() => {
          closeOverlay();
        }}
      ></div>
      <div className="sidebar-wrapper" id="sidebar-wrapper">
        <div className="logo-wrapper">
          <Link
            to={`${process.env.PUBLIC_URL}/${hwId}/dashboard/ecommerce/${layout}`}
          >
            <img
              className="img-fluid for-light"
              src={`${process.env.REACT_APP_BUCKET_URL}/byte-nfc/bytenfc.png`}
              style={{ height: "32px" }}
              alt=""
            />
            <img
              className="img-fluid for-dark"
              src={`${process.env.REACT_APP_BUCKET_URL}/byte-nfc/bytenfc.png`}
              style={{ height: "32px" }}
              alt=""
            />
          </Link>
          <div className="back-btn" onClick={() => responsiveSidebar()}>
            <i className="fa fa-angle-left"></i>
          </div>
          <div
            className="toggle-sidebar"
            onClick={() => openCloseSidebar(sidebartoogle)}
          >
            <Grid className="status_toggle middle sidebar-toggle" />
          </div>
        </div>
        <div className="logo-icon-wrapper">
          <Link
            to={`${process.env.PUBLIC_URL}/${hwId}/dashboard/ecommerce/${layout}`}
          >
            <img
              className="img-fluid"
              src={require("../../assets/images/logo/logo-icon.png")}
              alt=""
            />
          </Link>
        </div>
        <nav className="sidebar-main" id="sidebar-main">
          <div className="left-arrow" onClick={scrollToLeft}>
            <ArrowLeft />
          </div>

          {USER_ROLE.SUP_ADMIN === +localStorage.getItem("roleId") ? (
            <div
              id="sidebar-menu"
              style={
                wrapper.split(" ").includes("horizontal-wrapper")
                  ? { marginLeft: margin + "px" }
                  : { margin: "0px" }
              }
            >
              <ul className="sidebar-links custom-scrollbar">
                <li className="back-btn">
                  <div className="mobile-back text-end">
                    <span>{"Back"}</span>
                    <i
                      className="fa fa-angle-right ps-2"
                      aria-hidden="true"
                    ></i>
                  </div>
                </li>
                {SELLER_MENUITEMS.map((Item, i) => (
                  <Fragment key={i}>
                    <li className="sidebar-main-title">
                      <div>
                        <h6 className="lan-1">{t(Item.menutitle)}</h6>
                        <p className="lan-2">{t(Item.menucontent)}</p>
                      </div>
                    </li>
                    {Item.Items.map((menuItem, i) => (
                      <li className="sidebar-list" key={i}>
                        {menuItem.type === "sub" ? (
                          <a
                            href="javascript"
                            className={` ${
                              menuItem.active && menuItem?.isAccess === "true"
                                ? "sidebar-link sidebar-title active"
                                : "sidebar-link active"
                            }`}
                            style={
                              menuItem?.isAccess === "false" ? lockTabStyle : {}
                            }
                            onClick={(event) => {
                              event.preventDefault();
                              setNavActive(menuItem);
                              activeClass(menuItem.active);
                            }}
                          >
                            <menuItem.icon />
                            {menuItem?.isAccess === "false" ? (
                              <Tooltip title={FEATURE_LOCK_MESSAGE}>
                                <span>
                                  {t(menuItem.title)}{" "}
                                  {menuItem?.isAccess === "false" ? (
                                    <Lock />
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </Tooltip>
                            ) : (
                              <span>{t(menuItem.title)} </span>
                            )}
                            {menuItem.badge ? (
                              <label className={menuItem.badge}>
                                {menuItem.badgetxt}
                              </label>
                            ) : (
                              ""
                            )}
                            <div className="according-menu">
                              {menuItem.active ? (
                                <i className="fa fa-angle-down"></i>
                              ) : (
                                <i className="fa fa-angle-right"></i>
                              )}
                            </div>
                          </a>
                        ) : (
                          ""
                        )}

                        {menuItem.type === "link" ? (
                          <Link
                            to={menuItem.path + "/" + layout}
                            className={`sidebar-link sidebar-title link-nav  ${
                              menuItem.active ? "active" : ""
                            }`}
                            onClick={() => toggletNavActive(menuItem)}
                          >
                            <menuItem.icon />
                            <span>{t(menuItem.title)}</span>
                            {menuItem.badge ? (
                              <label className={menuItem.badge}>
                                {menuItem.badgetxt}
                              </label>
                            ) : (
                              ""
                            )}
                          </Link>
                        ) : (
                          ""
                        )}

                        {menuItem.children && menuItem?.isAccess !== "false" ? (
                          <ul
                            className="sidebar-submenu"
                            style={
                              menuItem.active
                                ? sidebartoogle
                                  ? {
                                      opacity: 1,
                                      transition: "opacity 500ms ease-in",
                                    }
                                  : { display: "block" }
                                : { display: "none" }
                            }
                          >
                            {menuItem.children.map((childrenItem, index) => {
                              return (
                                <li key={index}>
                                  {childrenItem.type === "sub" ? (
                                    <a
                                      href="javascript"
                                      className={`${
                                        childrenItem.active ? "active" : ""
                                      }`}
                                      onClick={(event) => {
                                        event.preventDefault();
                                        toggletNavActive(childrenItem);
                                      }}
                                    >
                                      {t(childrenItem.title)}
                                      <span className="sub-arrow">
                                        <i className="fa fa-chevron-right"></i>
                                      </span>
                                      <div className="according-menu">
                                        {childrenItem.active ? (
                                          <i className="fa fa-angle-down"></i>
                                        ) : (
                                          <i className="fa fa-angle-right"></i>
                                        )}
                                      </div>
                                    </a>
                                  ) : (
                                    ""
                                  )}
                                  {childrenItem.type === "link" &&
                                  menuItem.title !==
                                    REDIRECT_PATH_NAMES.POST_MAKER &&
                                  menuItem.title !==
                                    REDIRECT_PATH_NAMES.RESTAURANT_MENU &&
                                  menuItem.title !== REDIRECT_PATH_NAMES.CRM ? (
                                    <Link
                                      to={childrenItem.path + "/" + layout}
                                      className={`${
                                        childrenItem.active ? "active" : ""
                                      }`}
                                      onClick={() =>
                                        toggletNavActive(childrenItem)
                                      }
                                    >
                                      {t(childrenItem.title)}
                                    </Link>
                                  ) : (
                                    <a
                                      href="#"
                                      className={`${
                                        childrenItem.active ? "active" : ""
                                      }`}
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setCookie(
                                          "token",
                                          localStorage.getItem("token"),
                                          "",
                                          +process.env.REACT_APP_IS_LOCAL_URL
                                            ? "localhost"
                                            : `.${localStorage.getItem(
                                                "domain"
                                              )}`,
                                          20
                                        );
                                        setCookie(
                                          "domain",
                                          localStorage.getItem("domain"),
                                          "",
                                          +process.env.REACT_APP_IS_LOCAL_URL
                                            ? "localhost"
                                            : `.${localStorage.getItem(
                                                "domain"
                                              )}`,
                                          20
                                        );
                                        setCookie(
                                          "roleId",
                                          localStorage.getItem("roleId"),
                                          "",
                                          +process.env.REACT_APP_IS_LOCAL_URL
                                            ? "localhost"
                                            : `.${localStorage.getItem(
                                                "domain"
                                              )}`,
                                          20
                                        );
                                        setCookie(
                                          "hwId",
                                          localStorage.getItem("hwId"),
                                          "",
                                          +process.env.REACT_APP_IS_LOCAL_URL
                                            ? "localhost"
                                            : `.${localStorage.getItem(
                                                "domain"
                                              )}`,
                                          20
                                        );
                                        if (
                                          menuItem.title ===
                                          REDIRECT_PATH_NAMES.RESTAURANT_MENU
                                        ) {
                                          let menuDomain =
                                            process.env
                                              .REACT_APP_RESTAURANT_ADMIN;
                                          window.open(
                                            +process.env.REACT_APP_IS_LOCAL_URL
                                              ? `http://${
                                                  window.location.hostname
                                                }:5173/${hwId}/${encryptNumber(
                                                  +localStorage.getItem(
                                                    "orgId"
                                                  ),
                                                  +process.env
                                                    .REACT_APP_ENCRYPT_KEY
                                                )}`
                                              : `${menuDomain}${localStorage.getItem(
                                                  "domain"
                                                )}/${hwId}/${encryptNumber(
                                                  +localStorage.getItem(
                                                    "orgId"
                                                  ),
                                                  +process.env
                                                    .REACT_APP_ENCRYPT_KEY
                                                )}`,
                                            "_blank"
                                          );
                                        } else if (
                                          menuItem.title ===
                                          REDIRECT_PATH_NAMES.POST_MAKER
                                        ) {
                                          let postifyDomain =
                                            process.env.REACT_APP_POSTIFY;
                                          window.open(
                                            +process.env.REACT_APP_IS_LOCAL_URL
                                              ? `http://${window.location.hostname}:3001`
                                              : `${postifyDomain}${localStorage.getItem(
                                                  "domain"
                                                )}`,
                                            "_blank"
                                          );
                                        } else if (
                                          menuItem.title ===
                                          REDIRECT_PATH_NAMES.CRM
                                        ) {
                                          let crmDomain =
                                            process.env.REACT_APP_CRM;
                                          window.open(
                                            +process.env.REACT_APP_IS_LOCAL_URL
                                              ? `http://${
                                                  window.location.hostname
                                                }:5173/${encryptNumber(
                                                  +localStorage.getItem(
                                                    "orgId"
                                                  ),
                                                  +process.env
                                                    .REACT_APP_CRM_ENCRYPT_KEY
                                                )}`
                                              : `${crmDomain}${localStorage.getItem(
                                                  "domain"
                                                )}/${encryptNumber(
                                                  +localStorage.getItem(
                                                    "orgId"
                                                  ),
                                                  +process.env
                                                    .REACT_APP_CRM_ENCRYPT_KEY
                                                )}`,
                                            "_blank"
                                          );
                                        }
                                      }}
                                    >
                                      {t(childrenItem.title)}
                                    </a>
                                  )}

                                  {childrenItem.children ? (
                                    <ul
                                      className="nav-sub-childmenu submenu-content"
                                      style={
                                        childrenItem.active
                                          ? { display: "block" }
                                          : { display: "none" }
                                      }
                                    >
                                      {childrenItem.children.map(
                                        (childrenSubItem, key) => (
                                          <li key={key}>
                                            {childrenSubItem.type === "link" ? (
                                              <Link
                                                to={
                                                  childrenSubItem.path +
                                                  "/" +
                                                  layout
                                                }
                                                className={`${
                                                  childrenSubItem.active
                                                    ? "active"
                                                    : ""
                                                }`}
                                                onClick={() =>
                                                  toggletNavActive(
                                                    childrenSubItem
                                                  )
                                                }
                                              >
                                                {t(childrenSubItem.title)}
                                              </Link>
                                            ) : (
                                              ""
                                            )}
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </li>
                              );
                            })}
                          </ul>
                        ) : (
                          ""
                        )}
                      </li>
                    ))}
                  </Fragment>
                ))}
              </ul>
            </div>
          ) : (
            <div
              id="sidebar-menu"
              style={
                wrapper.split(" ").includes("horizontal-wrapper")
                  ? { marginLeft: margin + "px" }
                  : { margin: "0px" }
              }
            >
              <ul className="sidebar-links custom-scrollbar">
                <li className="back-btn">
                  <div className="mobile-back text-end">
                    <span>{"Back"}</span>
                    <i
                      className="fa fa-angle-right ps-2"
                      aria-hidden="true"
                    ></i>
                  </div>
                </li>
                {MENUITEMS.map((Item, i) => (
                  <Fragment key={i}>
                    <li className="sidebar-main-title">
                      <div>
                        <h6 className="lan-1">{t(Item.menutitle)}</h6>
                        <p className="lan-2">{t(Item.menucontent)}</p>
                      </div>
                    </li>
                    {Item.Items.map((menuItem, i) => (
                      <li className="sidebar-list" key={i}>
                        {menuItem.type === "sub" ? (
                          <a
                            href="javascript"
                            className={` ${
                              menuItem.active && menuItem?.isAccess === "true"
                                ? "sidebar-link sidebar-title active"
                                : "sidebar-link active"
                            }`}
                            style={
                              menuItem?.isAccess === "false" ? lockTabStyle : {}
                            }
                            onClick={(event) => {
                              event.preventDefault();
                              setNavActive(menuItem);
                              activeClass(menuItem.active);
                            }}
                          >
                            <menuItem.icon />
                            {menuItem?.isAccess === "false" ? (
                              <Tooltip title={FEATURE_LOCK_MESSAGE}>
                                <span>
                                  {t(menuItem.title)}{" "}
                                  {menuItem?.isAccess === "false" ? (
                                    <Lock />
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </Tooltip>
                            ) : (
                              <span>{t(menuItem.title)} </span>
                            )}
                            {menuItem.badge ? (
                              <label className={menuItem.badge}>
                                {menuItem.badgetxt}
                              </label>
                            ) : (
                              ""
                            )}
                            <div className="according-menu">
                              {menuItem.active ? (
                                <i className="fa fa-angle-down"></i>
                              ) : (
                                <i className="fa fa-angle-right"></i>
                              )}
                            </div>
                          </a>
                        ) : (
                          ""
                        )}

                        {menuItem.type === "link" ? (
                          <Link
                            to={menuItem.path + "/" + layout}
                            className={`sidebar-link sidebar-title link-nav  ${
                              menuItem.active ? "active" : ""
                            }`}
                            onClick={() => toggletNavActive(menuItem)}
                          >
                            <menuItem.icon />
                            <span>{t(menuItem.title)}</span>
                            {menuItem.badge ? (
                              <label className={menuItem.badge}>
                                {menuItem.badgetxt}
                              </label>
                            ) : (
                              ""
                            )}
                          </Link>
                        ) : (
                          ""
                        )}

                        {menuItem.children && menuItem?.isAccess !== "false" ? (
                          <ul
                            className="sidebar-submenu"
                            style={
                              menuItem.active
                                ? sidebartoogle
                                  ? {
                                      opacity: 1,
                                      transition: "opacity 500ms ease-in",
                                    }
                                  : { display: "block" }
                                : { display: "none" }
                            }
                          >
                            {menuItem.children.map((childrenItem, index) => {
                              return (
                                <li key={index}>
                                  {childrenItem.type === "sub" ? (
                                    <a
                                      href="javascript"
                                      className={`${
                                        childrenItem.active ? "active" : ""
                                      }`}
                                      onClick={(event) => {
                                        event.preventDefault();
                                        toggletNavActive(childrenItem);
                                      }}
                                    >
                                      {t(childrenItem.title)}
                                      <span className="sub-arrow">
                                        <i className="fa fa-chevron-right"></i>
                                      </span>
                                      <div className="according-menu">
                                        {childrenItem.active ? (
                                          <i className="fa fa-angle-down"></i>
                                        ) : (
                                          <i className="fa fa-angle-right"></i>
                                        )}
                                      </div>
                                    </a>
                                  ) : (
                                    ""
                                  )}
                                  {childrenItem.type === "link" &&
                                  menuItem.title !==
                                    REDIRECT_PATH_NAMES.POST_MAKER &&
                                  menuItem.title !==
                                    REDIRECT_PATH_NAMES.RESTAURANT_MENU &&
                                  menuItem.title !== REDIRECT_PATH_NAMES.CRM ? (
                                    <Link
                                      to={childrenItem.path + "/" + layout}
                                      className={`${
                                        childrenItem.active ? "active" : ""
                                      }`}
                                      onClick={() =>
                                        toggletNavActive(childrenItem)
                                      }
                                    >
                                      {t(childrenItem.title)}
                                    </Link>
                                  ) : (
                                    <a
                                      href="#"
                                      className={`${
                                        childrenItem.active ? "active" : ""
                                      }`}
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setCookie(
                                          "token",
                                          localStorage.getItem("token"),
                                          "",
                                          +process.env.REACT_APP_IS_LOCAL_URL
                                            ? "localhost"
                                            : `.${localStorage.getItem(
                                                "domain"
                                              )}`,
                                          20
                                        );
                                        setCookie(
                                          "domain",
                                          localStorage.getItem("domain"),
                                          "",
                                          +process.env.REACT_APP_IS_LOCAL_URL
                                            ? "localhost"
                                            : `.${localStorage.getItem(
                                                "domain"
                                              )}`,
                                          20
                                        );
                                        setCookie(
                                          "roleId",
                                          localStorage.getItem("roleId"),
                                          "",
                                          +process.env.REACT_APP_IS_LOCAL_URL
                                            ? "localhost"
                                            : `.${localStorage.getItem(
                                                "domain"
                                              )}`,
                                          20
                                        );
                                        setCookie(
                                          "hwId",
                                          localStorage.getItem("hwId"),
                                          "",
                                          +process.env.REACT_APP_IS_LOCAL_URL
                                            ? "localhost"
                                            : `.${localStorage.getItem(
                                                "domain"
                                              )}`,
                                          20
                                        );
                                        if (
                                          menuItem.title ===
                                          REDIRECT_PATH_NAMES.RESTAURANT_MENU
                                        ) {
                                          let menuDomain =
                                            process.env
                                              .REACT_APP_RESTAURANT_ADMIN;
                                          window.open(
                                            +process.env.REACT_APP_IS_LOCAL_URL
                                              ? `http://${
                                                  window.location.hostname
                                                }:5173/${hwId}/${encryptNumber(
                                                  +localStorage.getItem(
                                                    "orgId"
                                                  ),
                                                  +process.env
                                                    .REACT_APP_ENCRYPT_KEY
                                                )}`
                                              : `${menuDomain}${localStorage.getItem(
                                                  "domain"
                                                )}/${hwId}/${encryptNumber(
                                                  +localStorage.getItem(
                                                    "orgId"
                                                  ),
                                                  +process.env
                                                    .REACT_APP_ENCRYPT_KEY
                                                )}`,
                                            "_blank"
                                          );
                                        } else if (
                                          menuItem.title ===
                                          REDIRECT_PATH_NAMES.POST_MAKER
                                        ) {
                                          let postifyDomain =
                                            process.env.REACT_APP_POSTIFY;
                                          window.open(
                                            +process.env.REACT_APP_IS_LOCAL_URL
                                              ? `http://${window.location.hostname}:3001`
                                              : `${postifyDomain}${localStorage.getItem(
                                                  "domain"
                                                )}`,
                                            "_blank"
                                          );
                                        } else if (
                                          menuItem.title ===
                                          REDIRECT_PATH_NAMES.CRM
                                        ) {
                                          let crmDomain =
                                            process.env.REACT_APP_CRM;
                                          window.open(
                                            +process.env.REACT_APP_IS_LOCAL_URL
                                              ? `http://${
                                                  window.location.hostname
                                                }:5173/${encryptNumber(
                                                  +localStorage.getItem(
                                                    "orgId"
                                                  ),
                                                  +process.env
                                                    .REACT_APP_CRM_ENCRYPT_KEY
                                                )}`
                                              : `${crmDomain}${localStorage.getItem(
                                                  "domain"
                                                )}/${encryptNumber(
                                                  +localStorage.getItem(
                                                    "orgId"
                                                  ),
                                                  +process.env
                                                    .REACT_APP_CRM_ENCRYPT_KEY
                                                )}`,
                                            "_blank"
                                          );
                                        }
                                      }}
                                    >
                                      {t(childrenItem.title)}
                                    </a>
                                  )}

                                  {childrenItem.children ? (
                                    <ul
                                      className="nav-sub-childmenu submenu-content"
                                      style={
                                        childrenItem.active
                                          ? { display: "block" }
                                          : { display: "none" }
                                      }
                                    >
                                      {childrenItem.children.map(
                                        (childrenSubItem, key) => (
                                          <li key={key}>
                                            {childrenSubItem.type === "link" ? (
                                              <Link
                                                to={
                                                  childrenSubItem.path +
                                                  "/" +
                                                  layout
                                                }
                                                className={`${
                                                  childrenSubItem.active
                                                    ? "active"
                                                    : ""
                                                }`}
                                                onClick={() =>
                                                  toggletNavActive(
                                                    childrenSubItem
                                                  )
                                                }
                                              >
                                                {t(childrenSubItem.title)}
                                              </Link>
                                            ) : (
                                              ""
                                            )}
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </li>
                              );
                            })}
                          </ul>
                        ) : (
                          ""
                        )}
                      </li>
                    ))}
                  </Fragment>
                ))}
              </ul>
            </div>
          )}
          <div className="right-arrow" onClick={scrollToRight}>
            <ArrowRight />
          </div>
        </nav>
      </div>
    </Fragment>
  );
};

export default Sidebar;
