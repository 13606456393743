export const Location = "Location";
// import ImageBanner from "../components ByteCard/components/MultipageByteCardBuilder/imageBanner/ImageBanner";

export const New = "New";

export const TodayTotalSale = "Today Total sale";

export const Name = "Name";

export const ContactUs = "Contact Us";
export const Email = "Email";

export const Phone = "Phone";
export const EmailAddress = "Email ID";

export const Password = "Password";
export const LogOut = "Log Out";
export const Create = "Create";
export const Section = "Section";

export const Pagi_Nations = "Pagination";
export const PaginationWithIcons = "Pagination with icons";
export const PaginationAlignment = "Pagination alignment";
export const PaginationActiveDisabled = "Pagination with active and disabled";
export const PaginationColor = "Pagination Color";
export const PaginationSizing = "Pagination sizing";
export const Previous = "Previous";
export const Next = "Next";

// pages
export const RememberPassword = "Remember password";
export const ForgotPassword = "Forgot password?";
export const CreateAccount = "Create Account";
export const AUTH0 = "AUTH0";
export const JWT = "JWT";
export const LoginWithJWT = "Login";

// layout
export const Loading = "Loading...";

export const Admin = "Admin";
export const Account = "Account";
export const SINGLE_PAGE = "SinglePage";

export const IMAGE_URL =
  "https://mcode.fra1.digitaloceanspaces.com/byte-nfc/bytenfc.png";

export const DATA_NOT_FOUND =
  "https://img.freepik.com/free-vector/no-data-concept-illustration_114360-536.jpg?w=900&t=st=1667466035~exp=1667466635~hmac=fb00d8e365338a2998c49c4661d0abb17de576ce3efd265331b8a98d6877bc5b";

export const ENDPOINT_IMAGE = "/byte-nfc/bytenfc.png";

/** lock tab message */
export const FEATURE_LOCK_MESSAGE = "Go Premium for Access !";

// <<<<<<<<<<<<<<<--------------USER ROLE WISE ACCESS------------------->>>>>>>>>>>>>

export const ProfileKey = {
  ProductId: "c9e92390-331c-4b0e-8b74-8f2a3516ff30",
  category: "d93ddd94-df9d-43d2-8af1-ef3898318c74",
  whatsappCc: "+91",
  mobileCc: "+91",
};

export const PersonalPageDrop = [
  {
    name: "Whatsapp",
    value: "Whatsapp",
  },
  {
    name: "Call",
    value: "Call",
  },
  {
    name: "Link",
    value: "Link",
  },
  {
    name: "Location",
    value: "Location",
  },
  {
    name: "Email",
    value: "Email",
  },
  {
    name: "Download",
    value: "Download",
  },
  {
    name: "Save Contact",
    value: "Save Contact",
  },
  {
    name: "Reviews",
    value: "Reviews",
  },
  {
    name: "Website",
    value: "Website",
  },
  {
    name: "Text",
    value: "Text",
  },
];

export const hwStatusCode = {
  statusCode: 1,
  statusCodeTrue: "200",
};

export const USER_ROLE = {
  SUP_ADMIN: 1,
  PAGE_ADMIN: 2,
  PAGE_USER: 3,
};

export const NFC_GUNRATE_STATUS = {
  CREATED: 1,
  QR_REQUESTED: 2,
  QR_GENERATED: 3,
  FAILED: 4,
};

export const QR_GUNRATE_STATUS = {
  CREATED: 1,
  QR_REQUESTED: 2,
  QR_GENERATED: 3,
  FAILED: 4,
};

export const ORDER_STATUS = {
  PENDING: 1,
  COMPLETE: 2,
};

export const ORDER_TYPE = {
  BUY: 1,
  SELL: 2,
};

export const Role = [
  {
    id: 1,
    name: "super admin",
  },
  {
    id: 2,
    name: "Page admin",
  },
  {
    id: 3,
    name: "Page User",
  },
];

export const Style = {
  color: "white",
  width: "4rem",
  height: "4rem",
  borderRadius: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};

export const WEBSITE_BASE = "https://byteqr.com/";

export const PAGE_ITEM_TYPE = {
  ABOUT_ME: "aboutme",
  POLICY: "policy",
  DOWNLOAD: "download",
  CALL: "call",
  WHATSAPP: "WhatsApp",
  WHATSAPP_SOCIAL: "whatsappSocial",
  WECHAT: "wechat",
  REVIEW: "Review",
  PAYMENT: "Payment",
  WEBSITE: "website",
  WEBSITE_SOCIAL: "websiteSocial",
  PINTEREST: "pinterest",
  INDIAMART: "Indiamart",
  JUSTDIAL: "Justdial",
  UPI: "upi",
  MENU: "menu",
  LOCATION: "location",
  EMAIL: "email",
  GALLAYRY: "gallary",
  SLIDER: "slider",
  LOCATION_IFRAME: "locationIframe",
  SAVE_CONTACT: "saveContact",
  DIGITALCARD: "DigitalCard",
  CUSTOM: "custom",
  INQUIRY: "inquiry",
  VIDEO: "video",
  QR_CODE: "qrCode",
  SHARE: "share",
  YOUTUBE: "youtube",
  INSTAGRAM: "instagram",
  LINKEDIN: "linkedin",
  FACEBOOK: "Facebook",
  TWITTER: "twiter",
  LINKS: "Links",
  ANDROID: "android",
  APPLE: "ios",
  TELEGRAM: "telegram",
  GALLARY_POPUP: "Gallary",
};

export const SocialTypes = {
  custom: "11",
  whatsapp: "9",
};

export const SORTING_ORDER = {
  ASC: "ASC",
  DSC: "DSC",
};

export const FILE_TYPE = {
  CLONE_CATEGORY_MINI: 6,
  CLONE_CATEGORY_MULTI: 27,
  ORGANIZATION_LOGO: 1,
  PAGE_ITEM: 2,
  DOWNLOADE: 3,
  GALLERY: 4,
  SLIDER_FOR_LANDING: 5,
  BYTE_SLIDER: 6,
  MULTIPAGE: 7,
  ITEM_FEATURE: 1,
  GALLERY_IS: 2,
  OUR_TEAM: 3,
  VIDEO_BANNER: 4,
  IMAGE_BANNER: 5,
  FAQS: 6,
  BLOG: 7,
  TESTIMONIAL: 8,
  SERVICE_CATEGORY: 9,
  PACKAGE: 10,
  STEP: 11,
  SERVICE_COUNTER: 12,
  SEO: 13,
  LATEST_NEWS: 14,
  CAREER: 15,
  CONTACT_US: 16,
  OUR_CLIENT: 17,
  SLIDER: 18,
  DETAIL_SECTION: 19,
  PAGE_TITLE: 20,
  ABOUT_US: 21,
  PRODUCT: 22,
  INQUIRY: 23,
  VIDEO: 24,
  POPUP_DIALOG: 25,
  SOCIAL_MEDIA: 26,
  CUSTOM_FORM: 26,
  LOADER: 28,
};

export const OPTION = [
  {
    value: 1,
    name: "READ",
  },
  {
    value: 2,
    name: "WRITE",
  },
  {
    value: 3,
    name: "UPDATE",
  },
  {
    value: 4,
    name: "DELETE",
  },
  {
    value: 5,
    name: "DOWNLOAD",
  },
];

export const image_Not_found =
  "https://t4.ftcdn.net/jpg/04/70/29/97/240_F_470299797_UD0eoVMMSUbHCcNJCdv2t8B2g1GVqYgs.jpg";

export const TITLES = {
  ADD_PERSONAL_PAGE: "Add Personal Page",
  EDIT_PERSONAL_PAGE: "Edit Personal Page",
  PAGE_ITEMS: "Page Items",
  CLONE_PROFILE: "Clone Profile",
  COMPLITE_YOUR_PROFILE: "Complete Your Profile",
  Edit_YOUR_PROFILE: "Edit Your Profile",
  LANDING_PAGE: "Landing Page",
  MENU_PAGE: "Menu List",
  PRODUCTS_LIST: "Products List",
  TAGS_LIST: "Tags List",
  CATEGORY_LIST: "Category List",
  ADD_ON_PRODUCTS_LIST: "Add on Product List",
};
export const THEME = {
  THEME_NAME: "Theme List",
  ADD_THEME: "Add Theme",
  EDIT_THEME: "Edit Theme",
  THEME_MAP_NAME: "Theme Map List",
  ADD_THEME_MAP: "Add Theme Map",
  EDIT_THEME_MAP: "Edit Theme Map",
};
export const CLONE_CATEGORY = {
  THEME_NAME: "Clone Category List",
  ADD_THEME: "Add Clone Category",
  EDIT_THEME: "Edit Clone Category",
};
export const FONT_LIST = {
  THEME_NAME: "Font List",
  ADD_THEME: "Add Font Family",
  EDIT_THEME: "Edit Font Family",
};
export const WEB_CATEGORY_MAP = {
  THEME_NAME: "Web Category Map List",
  ADD_THEME: "Add Web Category Map",
  EDIT_THEME: "Edit Web Category Map",
};
export const ORG_ADD = {
  ORG_NAME: "Org Name",
  LANDING_PAGE_NAME: "Landing Page Name",
  BROKER_PAGE_NAME: "Broker Page Name",
  PAGE_LIMIT: "Page Limit",
  DOMAIN: "Domain",
  SUBMIT: "Submit",
  ORGANIZATION: "Organization",
  BROKER: "Broker",
  BROKER_LIMIT: "Broker Limit ...",
};

export const PLAN_MASTER = {
  PLAN_MASTER_NAME: "Plan Master",
  ADD_THEME: "Add Plan Master",
  EDIT_THEME: "Edit Plan Master",
};
export const PLAN_MASTER_TYPE = [
  {
    value: 1,
    label: "ORG",
  },
  {
    value: 2,
    label: "RE_SELLER",
  },
];

export const PRODUCTS_TYPE = [
  {
    key: "Veg",
    value: "veg",
  },
  {
    key: "Non Veg",
    value: "non veg",
  },
  {
    key: "Vegan",
    value: "vegan",
  },
  {
    key: "Pure Veg",
    value: "pure veg",
  },
  {
    key: "Jain",
    value: "jain",
  },
];

export const PAGE_ITEM_MASTER_ADD = {
  TYPE: "Type",
  LABEL: "Label",
  HAYPER_LINK: "Hyper link",
  CATEGORY: "Category",
  ACTION: "Action",
  IS_CALL_TO_ACTION: "Is Call-to-action",
  PAGE_ITEM: "Page Item",
};

export const INQUARY_LIST = {
  INQUIRY_PAGE: "Inquiry Page",
  INQUIRY: "Inquiry",
  SUBMIT: "Submit",
  NAME: "Name*",
  PHONE: "Phone*",
  EMAIL: "Email*",
  SUBJECT: "Subject",
  DESCRIPTION: "Description",
};

export const PAGE_ITEM_MASTER = {
  PAGE_ITEM_MASTER_PERENT: "Page Item Master",
  ADD_PAGE_ITEM_MASTER: "Add Page Item Master",
};

export const QR_POPUP = {
  QR_CODE: "Qr Code",
};

export const SHARE_POPUP = {
  SHARE_POP: "Share on Social Media",
};

export const baseUrl = `${process.env.REACT_APP_BUCKET_URL}/${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`; //"https://mcode.fra1.digitaloceanspaces.com/byte-qr-nfc/";

export const https = "https://";

/**
 * type of landing or broker
 */

export const TYPES = {
  LANDING_PAGE: 1,
  BROKER: 2,
  WEB_INQUIRY: 3,
  WEB_ORDER: 4,
};

/**
 * broker constant
 */

export const BROKER = {
  PERENT: "Broker Page",
  BROKER_PROFILE: "Broker Profile",
  EMAIL: "Email",
  BROKER_TITLE: "Broker Title",
  CONTACT_US: "ContactUs",
  LOCATION: "Location",
  COUNTRY: "Country",
  STATE: "State",
  CITY: "City",
  ADD_BROEKR_PAGE: "Add Broker Page",
  EDIT_BROEKR_PAGE: "Edit Broker Page",
  BROKER_PAGEITEM: "Broker Page Item",
  CLONE_BROKER: "Clone Broker",
  COMPLETE_BROKER_PROFILE: "Complite Broker Profile",
  EDIT_BROKER_PROFILE: "Edit Broker Profile",
};

export const BRANCH = {
  BRANCH: "1",
};

export const PERSONAL_PAGE_INCENTIVE = {
  TOTAL_INCENTIVE: "Total Incentive",
};

/**
 * Brokerpage tOtal incentive
 */
export const INCENTIVE = {
  TOTAL_INCENTIVE: "Total Incentive",
  BROKER_INCENTIVE: "Broker Incentive",
  EDIT_BROKER_INCENTIVE: "Edit Broker Incentive",
  ADD_BROKER_INCENTIVE: "Add Broker Incentive",
};

/**
 * page limit and broker limit constant
 */

export const LIMIT = {
  TOTAL_PAGE_LIMIT: "Total Page Limit",
  AVAILABLE_PAGE_LIMIT: "Available Page Limit",
  TOTAL_BROKER_LIMIT: "Total Broker Limit",
  AVAILABLE_BROKER_LIMIT: "Available Broker Limit",
};

/**
 * disabled delete button
 */

export const DELETE = {
  color_disabled: "#c99b9b",
  color: "red",
};

/**
 * moment formate constant
 */

export const MOMENT_SETTING = {
  FORMATE: "YYYY-MM-DD",

  FORMATE_WITH_TIME: "DD MMM, YYYY HH:MM",
  TIME_ZONE: "+05:30:00",
};

/**
 * Theme select dropdown data
 */

export const THEME_SELECT = [
  {
    label: "Theme 1 (Colour Selection)",
    value: 1,
  },
  {
    label: "Theme 2 (Grid View)",
    value: 2,
  },
  {
    label: "Theme 3 (Grid View With Slider) ",
    value: 3,
  },
  {
    label: "Theme 4 (List View) ",
    value: 4,
  },
  {
    label: "Theme 5 (Scan tap) ",
    value: 5,
  },
  {
    label: "Theme 6 (List View) ",
    value: 6,
  },
  {
    label: "Theme 7 (List View with Image and Text) ",
    value: 7,
  },
  {
    label: "Theme 8",
    value: 8,
  },
  {
    label: "Theme 9",
    value: 9,
  },
  {
    label: "Theme 10",
    value: 10,
  },
  {
    label: "Theme 11",
    value: 11,
  },
];

export const TMPLATE = {
  new: 2,
  old: 1,
};

export const titleUpdateKey = {
  isAboutUs: "isAboutUs",
  About_us: "About Us",
  isProduct: "isProduct",
  Product_Service: "Product & Services",
  Product: "Product",
  isDownload: "isDownload",
  Downloads: "Downloads",
  isGallery: "isGallery",
  Certificates: "Certificates",
  isPayment: "isPayment",
  Payments: "payments",
  isContactUs: "isContactUs",
  Contact_Details: "Contact Details",
  isTestimonial: "isTestimonial",
  Testimonials: "Testimonials",
  isPopupDialog: "isPopupDialog",
};

export const Position = [
  {
    position: "absolute",
  },
  {
    position: "fixed",
  },
  {
    position: "inherit",
  },
  {
    position: "initial",
  },
  {
    position: "relative",
  },
  {
    position: "revert",
  },
  {
    position: "static",
  },
  {
    position: "sticky",
  },
  {
    position: "unset",
  },
];

export const socialMedia = [
  {
    socialMedia: "Facebook",
  },
  {
    socialMedia: "Instagram",
  },
  {
    socialMedia: "Linkedin",
  },
  {
    socialMedia: "YouTube",
  },
  {
    socialMedia: "Twitter",
  },
];

export const fontFamily = [
  {
    "font-family": "FontAwesome",
  },
  {
    "font-family": "auto",
  },
  {
    "font-family": "cursive",
  },
  {
    "font-family": "emoji",
  },
  {
    "font-family": "fangsong",
  },
  {
    "font-family": "fantasy",
  },
  {
    "font-family": "inherit",
  },
  {
    "font-family": "initial",
  },
  {
    "font-family": "math",
  },
  {
    "font-family": "monospace",
  },
  {
    "font-family": "none",
  },
  {
    "font-family": "revert",
  },
  {
    "font-family": "sans-serif",
  },
  {
    "font-family": "serif",
  },
  {
    "font-family": "system-ui",
  },
  {
    "font-family": "ui-monospace",
  },
  {
    "font-family": "ui-rounded",
  },
  {
    "font-family": "ui-sans-serif",
  },
  {
    "font-family": "ui-serif",
  },
  {
    "font-family": "unset",
  },
  {
    "font-family": "-webkit-body",
  },
];

export const fontWeight = [
  {
    "font-weight": "normal",
  },
  {
    "font-weight": "bold",
  },
  {
    "font-weight": 100,
  },
  {
    "font-weight": 200,
  },
  {
    "font-weight": 300,
  },
  {
    "font-weight": 400,
  },
  {
    "font-weight": 500,
  },
  {
    "font-weight": 600,
  },
  {
    "font-weight": 700,
  },
  {
    "font-weight": 800,
  },
  {
    "font-weight": 900,
  },
  {
    "font-weight": "lighter",
  },
  {
    "font-weight": "bolder",
  },
  {
    "font-weight": "inherit",
  },
  {
    "font-weight": "initial",
  },
  {
    "font-weight": "revert",
  },
  {
    "font-weight": "revert-layer",
  },
  {
    "font-weight": "unset",
  },
];
export const TextTransform = [
  { "text-transform": "capitalize" },
  { "text-transform": "inherit" },
  { "text-transform": "initial" },
  { "text-transform": "lowercase" },
  { "text-transform": "math-auto" },
  { "text-transform": "none" },
  { "text-transform": "revert" },
  { "text-transform": "unset" },
  { "text-transform": "uppercase" },
];

export const Appearance = [
  { appearance: "auto" },
  { appearance: "none" },
  { appearance: "inherit" },
  { appearance: "initial" },
  { appearance: "revert" },
  { appearance: "unset" },
];
export const Float = [
  {
    float: "left",
  },
  {
    float: "right",
  },
  {
    float: "inherit",
  },
  {
    float: "initial",
  },
  {
    float: "inline-end",
  },
  {
    float: "inline-start",
  },
  {
    float: "none",
  },
  {
    float: "unset",
  },
  {
    float: "revert",
  },
];
export const TextAlign = [
  {
    "text-align": "start",
  },
  {
    "text-align": "end",
  },
  {
    "text-align": "left",
  },
  {
    "text-align": "right",
  },
  {
    "text-align": "center",
  },
  {
    "text-align": "justify",
  },
  {
    "text-align": "justify-all",
  },
  {
    "text-align": "match-parent",
  },
  {
    "text-align": "inherit",
  },
  {
    "text-align": "initial",
  },
  {
    "text-align": "revert",
  },
  {
    "text-align": "revert-layer",
  },
  {
    "text-align": "unset",
  },
];
export const textDecoration = [
  {
    "text-decoration": "auto",
  },
  {
    "text-decoration": "blink",
  },
  {
    "text-decoration": "dashed",
  },
  {
    "text-decoration": "dotted",
  },
  {
    "text-decoration": "double",
  },
  {
    "text-decoration": "inherit",
  },
  {
    "text-decoration": "line-through",
  },
  {
    "text-decoration": "none",
  },
  {
    "text-decoration": "overline",
  },
  {
    "text-decoration": "revert",
  },
  {
    "text-decoration": "solid",
  },
  {
    "text-decoration": "underline",
  },
  {
    "text-decoration": "unset",
  },
  {
    "text-decoration": "wavy",
  },
];

export const Display = [
  {
    display: "block",
  },
  {
    display: "contents",
  },
  {
    display: "flex",
  },
  {
    display: "flow-root",
  },
  {
    display: "grid",
  },
  {
    display: "inherit",
  },
  {
    display: "inline",
  },
  {
    display: "inline-block",
  },
  {
    display: "inline-flex",
  },
  {
    display: "inline-grid",
  },
  {
    display: "inline-table",
  },
  {
    display: "list-item",
  },
  {
    display: "none",
  },
  {
    display: "revert",
  },
  {
    display: "table",
  },
  {
    display: "table-caption",
  },
  {
    display: "table-cell",
  },
  {
    display: "table-column",
  },
  {
    display: "table-column-group",
  },
  {
    display: "table-footer-group",
  },
  {
    display: "table-header-group",
  },
  {
    display: "table-row",
  },
  {
    display: "table-row-group",
  },
  {
    display: "unset",
  },
  {
    display: "-webkit-box",
  },
  {
    display: "-webkit-inline-box",
  },
];

export const Border = [
  {
    border: "solid",
  },
  {
    border: "dotted",
  },
];

export const fileUploadSizes = {
  image: 1 * 1024 * 1024,
  download: 50 * 1024 * 1024,
};

export const MultiFileAllowUpload = [
  "png",
  "PNG",
  "JPG",
  "jpg",
  "JPEG",
  "jpeg",
  "gif",
];

export const serviceTheme = {
  theme1: 1,
  theme2: 2,
  theme3: 3,
  theme4: 4,
  theme5: 5,
  theme6: 6,
  theme7: 7,
  theme8: 8,
  theme9: 9,
  theme10: 10,
  theme11: 11,
  theme12: 12,
  theme13: 13,
  theme14: 14,
  theme15: 15,
};
export const OurClientTheme = {
  theme1: 1,
  theme2: 2,
};
export const BlogTheme = {
  theme1: 1,
};
export const OurTeamTheme = {
  theme1: 1,
  theme2: 2,
};
export const TestimonialTheme = {
  theme1: 1,
  theme2: 2,
  theme3: 3,
};
export const ServiceCounterTheme = {
  theme1: 1,
  theme2: 2,
};

export const serviceAndCategoryTheme1 = {
  ".service-section": {
    padding: "100px 0",
    "background-color": "rgb(255 255 255 / 100)",
    width: "",
    margin: "",
    "border-radius": "",
  },
  ".service-section.bg-image": {
    "background-image": "",
  },
  ".service-section.bg-image::before": {
    "background-color": "",
  },
  ".service-section .section-title-area": {
    "margin-bottom": "50px",
  },
  ".service-section .section-title-area .section-subtitle": {
    "font-size": "16px",
    "font-weight": "600",
    color: "rgb(255 73 124 / 100)",
    "background-color": "rgb(255 73 124 / 0.2)",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "border-radius": "15px",
    "margin-bottom": "15px",
  },
  ".service-section .section-title-area .section-title": {
    "font-size": "44px",
    "font-weight": "600",
    color: "rgb(0 0 0 / 100)",
  },
  ".service-section .servicediv": {
    margin: "15px",
    padding: "20px",
    outline: "none",
    outPx: "1px",
    outThik: "solid",
    outColor: "rgb(255 255 255 / 100)",
    "background-color": "rgb(255 255 255 / 100)",
    "box-shadow": "0 5px 10px 0 rgb(7 28 26 / 0.1)",
    bs1: "0px",
    bs2: "5px",
    bs3: "10px",
    bs4: "0px",
    shadowColor: "rgb(7 28 26 / 0.1)",
    "border-radius": "0px",
  },
  ".service-section .servicediv .service-icon": {
    "margin-bottom": "20px",
    "max-width": "70px",
  },
  ".service-section .servicediv h4": {
    color: "rgb(0 0 0 / 100)",
    "font-weight": "600",
    "font-size": "20px",
    "margin-bottom": "15px",
    "text-align": "justify",
  },
  ".service-section .servicediv p": {
    color: "rgb(0 0 0 / 100)",
    "font-weight": "400",
    "font-size": "14px",
    "line-height": "1.6",
    "margin-bottom": "15px",
    "text-align": "justify",
  },
  ".service-section .servicediv .links": {
    "font-size": "16px",
    "font-weight": "500",
    color: "rgb(255 73 124 / 100)",
  },
  ".service-slider .slick-arrow:before": {
    color: "rgb(255 73 124 / 100)",
  },
  ".service-slider .service-box": {
    position: "relative",
    "border-radius": "10px",
    margin: "10px",
  },
  ".service-slider .service-box .servicediv": {
    "background-color": "rgba(255, 255, 255, 0.9)",
    "border-radius": "10px",
  },
  ".service-slider .service-box .service-img": {
    "border-radius": "10px",
  },
  ".service-detail-section": {
    padding: "60px 0",
    "background-color": "rgb(255 255 255 / 100)",
  },
  ".service-detail-section.bg-image": {
    "background-image": "",
  },
  ".service-detail-section.bg-image::before": {
    "background-color": "",
  },
  ".detail-sidebar": {
    display: "inline-block",
    width: "100%",
  },
  ".detail-sidebar .other-link li a": {
    "background-color": "rgb(244 244 245 / 100)",
    color: "rgb(31 39 50 / 100)",
    padding: "15px 20px",
    p1: "15px",
    p2: "20px",
    "font-size": "16px",
    "font-weight": "normal",
    border: "1px solid rgb(215 226 230 / 100)",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: "rgb(215 226 230 / 100)",
  },
  ".detail-sidebar .other-link li .fa": {
    "margin-right": "10px",
  },
  ".detail-sidebar .other-link li a:hover, .detail-sidebar .other-link li.active a":
    {
      color: "rgb(255 73 124 / 100)",
      "border-left": "5px solid rgb(255 73 124 / 100)",
      borderLfPx: "5px",
      borderLfThik: "solid",
      borderLfColor: "rgb(255 73 124 / 100)",
    },
  ".detail-sidebar .widget-form": {
    display: "inline-block",
    width: "100%",
    padding: "25px 35px",
    p1: "25px",
    p2: "35px",
    "background-color": "rgb(31 39 50 / 100)",
  },
  ".detail-sidebar .widget-form .form-control": {
    padding: "13px",
  },
  ".detail-sidebar .widget-form .btn-submit": {
    "background-color": "rgb(255 73 124 / 100)",
    border: "2px solid rgb(255 73 124 / 100)",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: "rgb(255 73 124 / 100)",
    color: "rgb(255 255 255 / 100)",
    "font-size": "16px",
    "font-weight": "600",
    padding: "8px 25px",
    p1: "8px",
    p2: "25px",
    width: "100%",
  },
  ".service-section .more-btn": {
    color: "rgb(255 255 255 / 100)",
    "font-size": "16px",
    "font-weight": "normal",
    "border-radius": "15px",
    padding: "5px 20px",
    "background-color": "rgb(255 73 124 / 100)",
  },
  ".service-section .more-btn:hover": {
    "background-color": "rgb(255 73 124 / 100)",
    color: "rgb(255 255 255 / 100)",
  },
  ".pagination-positions": {
    "justify-content": "center",
  },
  ".pagination .page-link": {
    color: "rgb(255 73 124 / 100)",
  },
  ".pagination .page-item.active .page-link": {
    "background-color": "rgb(255 73 124 / 100)",
    "border-color": "rgb(255 73 124 / 100)",
  },
  ".service-section .slick-dots li button:before": {
    "font-size": "12px",
    color: "rgb(255 73 124 / 100)",
    opacity: "0.25",
  },
  ".service-section .slick-dots li.slick-active button:before": {
    color: "rgb(255 73 124 / 100)",
  },
};
export const serviceAndCategoryTheme2 = {
  ".service2-section": {
    padding: "100px 0",
    "background-color": "rgb(255 255 255 / 100)",
    width: "",
    margin: "",
    "border-radius": "",
  },
  ".service2-section.bg-image": {
    "background-image": "",
  },
  ".service2-section.bg-image::before": {
    "background-color": "",
  },
  ".service2-section .section-title-area": {
    "margin-bottom": "50px",
  },
  ".service2-section .section-title-area .section-subtitle": {
    "font-size": "16px",
    "font-weight": "600",
    color: "rgb(255 73 124 / 100)",
    "background-color": "rgb(255 73 124 / 0.2)",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "border-radius": "15px",
    "margin-bottom": "15px",
  },
  ".service2-section .section-title-area .section-title": {
    "font-size": "44px",
    "font-weight": "600",
    color: "rgb(0 0 0 / 100)",
  },
  ".service2-section .servicediv": {
    margin: "15px",
    padding: "20px",
    outline: "none",
    outPx: "1px",
    outThik: "solid",
    outColor: "rgb(255 255 255 / 100)",
    "background-color": "rgb(255 255 255 / 100)",
    "box-shadow": "0 5px 10px 0 rgb(7 28 26 / 0.1)",
    bs1: "0px",
    bs2: "5px",
    bs3: "10px",
    bs4: "0px",
    shadowColor: "rgb(7 28 26 / 0.1)",
  },
  // ".service2-section .servicediv .service-icon": {
  //   "margin-bottom": "20px",
  //   "max-width": "70px",
  // },
  ".service2-section .servicediv h4": {
    color: "rgb(0 0 0 / 100)",
    "font-weight": "600",
    "font-size": "20px",
    "margin-bottom": "15px",
    "text-align": "justify",
  },
  ".service2-section .servicediv p": {
    color: "rgb(0 0 0 / 100)",
    "font-weight": "400",
    "font-size": "14px",
    "line-height": "1.6",
    "margin-bottom": "15px",
    "text-align": "justify",
  },
  ".service2-section .servicediv .links": {
    "font-size": "16px",
    "font-weight": "500",
    color: "rgb(255 73 124 / 100)",
  },
  ".service-slider .slick-arrow::before": {
    color: "rgb(255 73 124 / 100)",
  },
  ".service-slider .service-box": {
    position: "relative",
    "border-radius": "10px",
    margin: "10px",
  },
  ".service-slider .service-box .servicediv": {
    "background-color": "rgba(255, 255, 255, 0.9)",
    "border-radius": "10px",
  },
  ".service-slider .service-box .service-img": {
    "border-radius": "10px",
  },
  ".service-detail-section": {
    padding: "60px 0",
    "background-color": "rgb(255 255 255 / 100)",
  },
  ".service-detail-section.bg-image": {
    "background-image": "",
  },
  ".service-detail-section.bg-image::before": {
    "background-color": "",
  },
  ".detail-sidebar": {
    display: "inline-block",
    width: "100%",
  },
  ".detail-sidebar .other-link li a": {
    "background-color": "rgb(244 244 245 / 100)",
    color: "rgb(31 39 50 / 100)",
    padding: "15px 20px",
    p1: "15px",
    p2: "20px",
    "font-size": "16px",
    "font-weight": "normal",
    border: "1px solid rgb(215 226 230 / 100)",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: "rgb(215 226 230 / 100)",
  },
  ".detail-sidebar .other-link li .fa": {
    "margin-right": "10px",
  },
  ".detail-sidebar .other-link li a:hover, .detail-sidebar .other-link li.active a":
    {
      color: "rgb(255 73 124 / 100)",
      "border-left": "5px solid rgb(255 73 124 / 100)",
      borderLfPx: "5px",
      borderLfThik: "solid",
      borderLfColor: "rgb(255 73 124 / 100)",
    },
  ".detail-sidebar .widget-form": {
    display: "inline-block",
    width: "100%",
    padding: "25px 35px",
    p1: "25px",
    p2: "35px",
    "background-color": "rgb(31 39 50 / 100)",
  },
  ".detail-sidebar .widget-form .form-control": {
    padding: "13px",
  },
  ".detail-sidebar .widget-form .btn-submit": {
    "background-color": "rgb(255 73 124 / 100)",
    border: "2px solid rgb(255 73 124 / 100)",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: "rgb(255 73 124 / 100)",
    color: "rgb(255 255 255 / 100)",
    "font-size": "16px",
    "font-weight": "600",
    padding: "8px 25px",
    p1: "8px",
    p2: "25px",
    width: "100%",
  },
  ".service2-section .more-btn": {
    color: "rgb(255 255 255 / 100)",
    "font-size": "16px",
    "font-weight": "normal",
    "border-radius": "15px",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "background-color": "rgb(255 73 124 / 100)",
  },
  ".service2-section .more-btn:hover": {
    "background-color": "rgb(223 42 93 / 100)",
    color: "rgb(255 255 255 / 100)",
  },
  ".pagination-positions": {
    "justify-content": "center",
  },
  ".pagination .page-link": {
    color: "rgb(255 73 124 / 100)",
  },
  ".pagination .page-item.active .page-link": {
    "background-color": "rgb(255 73 124 / 100)",
    "border-color": "rgb(255 73 124 / 100)",
  },
  ".service2-section .slick-dots li button:before": {
    "font-size": "12px",
    color: "rgb(255 73 124 / 100)",
    opacity: "0.25",
  },
  ".service2-section .slick-dots li.slick-active button:before": {
    color: "rgb(255 73 124 / 100)",
  },
};

export const serviceAndCategoryTheme3 = {
  ".vision-section": {
    padding: "100px 0",
    "background-color": "rgb(255 255 255 / 100)",
    width: "",
    margin: "",
    "border-radius": "",
  },
  ".vision-section.bg-image": {
    "background-image": "",
  },
  ".vision-section.bg-image::before": {
    "background-color": "",
  },
  ".vision-section .section-title-area": {
    "margin-bottom": "50px",
  },
  ".vision-section .section-title-area .section-subtitle": {
    "font-size": "16px",
    "font-weight": "600",
    color: "rgb(255 73 124 / 100)",
    "background-color": "rgb(255 73 124 / 0.2)",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "border-radius": "15px",
    "margin-bottom": "15px",
  },
  ".vision-section .section-title-area .section-title": {
    "font-size": "44px",
    "font-weight": "600",
    color: "rgb(0 0 0 / 100)",
  },
  ".vision-section .visiondiv1": {
    "background-color": "rgb(255 255 255 / 100)",
    padding: "60px 40px",
    p1: "60px",
    p2: "40px",
    margin: "15px 0",
    "border-radius": "15px",
    border: "1px solid rgb(255 255 255 / 100)",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: "rgb(255 255 255 / 100)",
    "box-shadow": "5px 5px 15px rgb(0 0 0 / 0.08)",
    bs1: "5px",
    bs2: "5px",
    bs3: "15px",
    shadowColor: "rgb(0 0 0 / 0.08)",
    "text-align": "center",
  },
  ".vision-section .visiondiv1:hover": {
    "box-shadow": "0 10px 20px 0 rgb(19 19 19 / 0.102)",
    bs1: "0px",
    bs2: "10px",
    bs3: "20px",
    bs4: "0px",
    shadowColor: "rgb(19 19 19 / 0.102)",
  },
  ".vision-section .visiondiv1 img": {
    "margin-bottom": "30px",
    "max-width": "70px",
  },
  ".vision-section .visiondiv1 h4": {
    color: "rgb(0 0 0 / 100)",
    "font-weight": "600",
    "font-size": "22px",
    "margin-bottom": "15px",
  },
  ".vision-section .visiondiv1 p": {
    color: "rgb(0 0 0 / 100)",
    "font-weight": "400",
    "font-size": "14px",
    "line-height": "1.6",
  },
  ".service-detail-section": {
    padding: "60px 0",
    "background-color": "rgb(255 255 255 / 100)",
  },
  ".service-detail-section.bg-image": {
    "background-image": "",
  },
  ".service-detail-section.bg-image::before": {
    "background-color": "",
  },
  ".detail-sidebar": {
    display: "inline-block",
    width: "100%",
  },
  ".detail-sidebar .other-link li a": {
    "background-color": "rgb(244 244 245 / 100)",
    color: "rgb(31 39 50 / 100)",
    padding: "15px 20px",
    p1: "15px",
    p2: "20px",
    "font-size": "16px",
    "font-weight": "normal",
    border: "1px solid rgb(215 226 230 / 100)",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: "rgb(215 226 230 / 100)",
  },
  ".detail-sidebar .other-link li .fa": {
    "margin-right": "10px",
  },
  ".detail-sidebar .other-link li a:hover, .detail-sidebar .other-link li.active a":
    {
      color: "rgb(255 73 124 / 100)",
      "border-left": "5px solid rgb(255 73 124 / 100)",
      borderLfPx: "5px",
      borderLfThik: "solid",
      borderLfColor: "rgb(255 73 124 / 100)",
    },
  ".detail-sidebar .widget-form": {
    display: "inline-block",
    width: "100%",
    padding: "25px 35px",
    p1: "25px",
    p2: "35px",
    "background-color": "rgb(31 39 50 / 100)",
  },
  ".detail-sidebar .widget-form .form-control": {
    padding: "13px",
  },
  ".detail-sidebar .widget-form .btn-submit": {
    "background-color": "rgb(255 73 124 / 100)",
    border: "2px solid rgb(255 73 124 / 100)",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: "rgb(255 73 124 / 100)",
    color: "rgb(255 255 255 / 100)",
    "font-size": "16px",
    "font-weight": "600",
    padding: "8px 25px",
    p1: "8px",
    p2: "25px",
    width: "100%",
  },
  ".vision-section .more-btn": {
    color: "rgb(255 255 255 / 100)",
    "font-size": "16px",
    "font-weight": "normal",
    "border-radius": "15px",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "background-color": "rgb(255 73 124 / 100)",
  },
  ".vision-section .more-btn:hover": {
    "background-color": "rgb(255 73 124 / 100)",
    color: "rgb(255 255 255 / 100)",
  },
  ".pagination-positions": {
    "justify-content": "center",
  },
  ".pagination .page-link": {
    color: "rgb(255 73 124 / 100)",
  },
  ".pagination .page-item.active .page-link": {
    "background-color": "rgb(255 73 124 / 100)",
    "border-color": "rgb(255 73 124 / 100)",
  },
};

export const serviceAndCategoryTheme4 = {
  ".vision2-section": {
    padding: "100px 0",
    "background-color": "rgb(255 255 255 / 100)",
    width: "",
    margin: "",
    "border-radius": "",
  },
  ".vision2-section.bg-image": {
    "background-image": "",
  },
  ".vision2-section.bg-image::before": {
    "background-color": "",
  },
  ".vision2-section .section-title-area": {
    "margin-bottom": "50px",
  },
  ".vision2-section .section-title-area .section-subtitle": {
    "font-size": "16px",
    "font-weight": "600",
    color: "rgb(255 73 124 / 100)",
    "background-color": "rgb(255 73 124 / 0.2)",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "border-radius": "15px",
    "margin-bottom": "15px",
  },
  ".vision2-section .section-title-area .section-title": {
    "font-size": "44px",
    "font-weight": "600",
    color: "rgb(0 0 0 / 100)",
  },
  ".vision2-section .visiondiv2": {
    padding: "25px",
    "border-radius": "15px",
    "text-align": "center",
  },
  ".vision2-section .visiondiv2:hover": {
    transform: "-8px)",
  },
  ".vision2-section .visiondiv2 img": {
    "margin-bottom": "20px",
    "max-width": "70px",
  },
  ".vision2-section .visiondiv2 h4": {
    color: "rgb(0 0 0 / 100)",
    "font-weight": "600",
    "font-size": "24px",
    "margin-bottom": "15px",
  },
  ".vision2-section .visiondiv2 p": {
    color: "rgb(0 0 0 / 100)",
    "font-weight": "300",
    "font-size": "16px",
    "line-height": "1.8",
  },
  ".service-detail-section": {
    padding: "60px 0",
    "background-color": "rgb(255 255 255 / 100)",
  },
  ".service-detail-section.bg-image": {
    "background-image": "",
  },
  ".service-detail-section.bg-image::before": {
    "background-color": "",
  },
  ".detail-sidebar": {
    display: "inline-block",
    width: "100%",
  },
  ".detail-sidebar .other-link li a": {
    "background-color": "rgb(244 244 245 / 100)",
    color: "rgb(31 39 50 / 100)",
    padding: "15px 20px",
    p1: "15px",
    p2: "20px",
    "font-size": "16px",
    "font-weight": "normal",
    border: "1px solid rgb(215 226 230 / 100)",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: "rgb(215 226 230 / 100)",
  },
  ".detail-sidebar .other-link li .fa": {
    "margin-right": "10px",
  },
  ".detail-sidebar .other-link li a:hover, .detail-sidebar .other-link li.active a":
    {
      color: "rgb(255 73 124 / 100)",
      "border-left": "5px solid rgb(255 73 124 / 100)",
      borderLfPx: "5px",
      borderLfThik: "solid",
      borderLfColor: "rgb(255 73 124 / 100)",
    },
  ".detail-sidebar .widget-form": {
    display: "inline-block",
    width: "100%",
    padding: "25px 35px",
    p1: "25px",
    p2: "35px",
    "background-color": "rgb(31 39 50 / 100)",
  },
  ".detail-sidebar .widget-form .form-control": {
    padding: "13px",
  },
  ".detail-sidebar .widget-form .btn-submit": {
    "background-color": "rgb(255 73 124 / 100)",
    border: "2px solid rgb(255 73 124 / 100)",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: "rgb(255 73 124 / 100)",
    color: "rgb(255 255 255 / 100)",
    "font-size": "16px",
    "font-weight": "600",
    padding: "8px 25px",
    p1: "8px",
    p2: "25px",
    width: "100%",
  },
  ".vision2-section .more-btn": {
    color: "rgb(255 255 255 / 100)",
    "font-size": "16px",
    "font-weight": "normal",
    "border-radius": "15px",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "background-color": "rgb(255 73 124 / 100)",
  },
  ".vision2-section .more-btn:hover": {
    "background-color": "rgb(255 73 124 / 100)",
    color: "rgb(255 255 255 / 100)",
  },
  ".pagination-positions": {
    "justify-content": "center",
  },
  ".pagination .page-link": {
    color: "rgb(255 73 124 / 100)",
  },
  ".pagination .page-item.active .page-link": {
    "background-color": "rgb(255 73 124 / 100)",
    "border-color": "rgb(255 73 124 / 100)",
  },
};

export const serviceAndCategoryTheme6 = {
  ".vision-section6": {
    padding: "100px 0",
    "background-color": "rgb(255 255 255 / 100)",
    width: "",
    margin: "",
    "border-radius": "",
  },
  ".vision-section6.bg-image": {
    "background-image": "",
  },
  ".vision-section6.bg-image::before": {
    "background-color": "",
  },
  ".vision-section6 .section-title-area": {
    "margin-bottom": "50px",
  },
  ".vision-section6 .section-title-area .section-subtitle": {
    "font-size": "16px",
    "font-weight": "600",
    color: "rgb(255 73 124 / 100)",
    "background-color": "rgb(255 73 124 / 0.2)",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "border-radius": "15px",
    "margin-bottom": "15px",
  },
  ".vision-section6 .section-title-area .section-title": {
    "font-size": "44px",
    "font-weight": "600",
    color: "rgb(0 0 0 / 100)",
  },
  ".vision-section6 .visiondiv1": {
    "background-color": "rgb(255 255 255 / 100)",
    margin: "15px 0",
    "border-radius": "15px",
    border: "1px solid rgb(255 255 255 / 100)",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: "rgb(255 255 255 / 100)",
    "box-shadow": "5px 5px 15px rgb(0 0 0 / 0.08)",
    bs1: "5px",
    bs2: "5px",
    bs3: "15px",
    shadowColor: "rgb(0 0 0 / 0.08)",
    "text-align": "center",
  },
  ".vision-section6 .visiondiv1:hover": {
    "box-shadow": "0 10px 20px 0 rgb(19 19 19 / 0.102)",
    bs1: "0px",
    bs2: "10px",
    bs3: "20px",
    bs4: "0px",
    shadowColor: "rgb(19 19 19 / 0.102)",
  },
  // ".vision-section6 .visiondiv_imgs": {
  //   padding: 0,
  //   overflow: "hidden",
  // },
  // ".vision-section6 .visiondiv_imgs img": {
  //   "max-width": "100%",
  //   "margin-bottom": 0,
  // },
  ".vision-section6 .visiondiv_imgs .visiondiv_sub": {
    padding: "20px 25px",
    p1: "20px",
    p2: "25px",
  },
  ".vision-section6 .visiondiv1 h4": {
    color: "rgb(0 0 0 / 100)",
    "font-weight": "600",
    "font-size": "22px",
    "margin-bottom": "15px",
  },
  ".vision-section6 .visiondiv1 p": {
    color: "rgb(0 0 0 / 100)",
    "font-weight": "400",
    "font-size": "15px",
    "line-height": "1.6",
  },
  ".vision-section6 .visiondiv_sub .btn": {
    color: "rgb(255 73 124 / 100)",
    "font-weight": "400",
    "font-size": "16px",
  },
  ".service-detail-section": {
    padding: "60px 0",
    "background-color": "rgb(255 255 255 / 100)",
  },
  ".service-detail-section.bg-image": {
    "background-image": "",
  },
  ".service-detail-section.bg-image::before": {
    "background-color": "",
  },
  ".detail-sidebar": {
    display: "inline-block",
    width: "100%",
  },
  ".detail-sidebar .other-link li a": {
    "background-color": "rgb(244 244 245 / 100)",
    color: "rgb(31 39 50 / 100)",
    padding: "15px 20px",
    p1: "15px",
    p2: "20px",
    "font-size": "16px",
    "font-weight": "normal",
    border: "1px solid rgb(215 226 230 / 100)",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: "rgb(215 226 230 / 100)",
  },
  ".detail-sidebar .other-link li .fa": {
    "margin-right": "10px",
  },
  ".detail-sidebar .other-link li a:hover, .detail-sidebar .other-link li.active a":
    {
      color: "rgb(255 73 124 / 100)",
      "border-left": "5px solid rgb(255 73 124 / 100)",
      borderLfPx: "5px",
      borderLfThik: "solid",
      borderLfColor: "rgb(255 73 124 / 100)",
    },
  ".detail-sidebar .widget-form": {
    display: "inline-block",
    width: "100%",
    padding: "25px 35px",
    p1: "25px",
    p2: "35px",
    "background-color": "rgb(31 39 50 / 100)",
  },
  ".detail-sidebar .widget-form .form-control": {
    padding: "13px",
  },
  ".detail-sidebar .widget-form .btn-submit": {
    "background-color": "rgb(255 73 124 / 100)",
    border: "2px solid rgb(255 73 124 / 100)",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: "rgb(255 73 124 / 100)",
    color: "rgb(255 255 255 / 100)",
    "font-size": "16px",
    "font-weight": "600",
    padding: "8px 25px",
    p1: "8px",
    p2: "25px",
    width: "100%",
  },
  ".vision-section6 .more-btn": {
    color: "rgb(255 255 255 / 100)",
    "font-size": "16px",
    "font-weight": "normal",
    "border-radius": "15px",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "background-color": "rgb(255 73 124 / 100)",
  },
  ".vision-section6 .more-btn:hover": {
    "background-color": "rgb(255 73 124 / 100)",
    color: "rgb(255 255 255 / 100)",
  },
  ".pagination-positions": {
    "justify-content": "center",
  },
  ".pagination .page-link": {
    color: "rgb(255 73 124 / 100)",
  },
  ".pagination .page-item.active .page-link": {
    "background-color": "rgb(255 73 124 / 100)",
    "border-color": "rgb(255 73 124 / 100)",
  },
};

export const serviceAndCategoryTheme7 = {
  ".boxes1-section": {
    padding: "60px 0",
    "background-color": "rgb(35 50 83 / 100)",
  },
  ".boxes1-section .section-title-area": {
    "margin-bottom": "50px",
  },
  ".boxes1-section .section-title-area .section-subtitle": {
    "font-size": "16px",
    "font-weight": "600",
    color: "rgb(255 73 124 / 100)",
    "background-color": "rgb(255 73 124 / 0.2)",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "border-radius": "15px",
    "margin-bottom": "15px",
  },
  ".boxes1-section .section-title-area .section-title": {
    "font-size": "44px",
    "font-weight": "600",
    color: "rgb(0 0 0 / 100)",
  },
  ".boxes1-section .cus-boxes": {
    background: "rgb(255 255 255 / 100)",
    "border-radius": "4px",
    padding: "42px 15px 45px",
    p1: "42px",
    p2: "15px",
    p3: "45px",
    margin: "10px 0",
    m1: "10px",
    m2: "0px",
  },
  ".boxes1-section .cus-boxes:before": {
    height: "4px",
    "background-color": "rgb(255 73 124 / 100)",
  },
  ".boxes1-section .flaticon": {
    width: "90px",
    height: "90px",
    "border-radius": "50%",
    margin: "0 auto",
    background: "rgb(83 97 132 / 100)",
  },
  ".boxes1-section .cus-boxes:hover .flaticon": {
    background: "rgb(255 255 255 / 100)",
  },
  ".boxes1-section .subtitle": {
    color: "rgb(35 50 83 / 100)",
    "font-size": "18px",
    "line-height": "1.5",
    "font-weight": 600,
    margin: "20px 0 0",
    m1: "20px",
    m2: "0px",
    m3: "0px",
  },
  ".boxes1-section .cus-boxes:hover .subtitle": {
    color: "rgb(255 255 255 / 100)",
  },
  ".service-detail-section": {
    padding: "60px 0",
    "background-color": "rgb(255 255 255 / 100)",
  },
  ".service-detail-section.bg-image": {
    "background-image": "",
  },
  ".service-detail-section.bg-image::before": {
    "background-color": "",
  },
  ".detail-sidebar": {
    display: "inline-block",
    width: "100%",
  },
  ".detail-sidebar .other-link li a": {
    "background-color": "rgb(244 244 245 / 100)",
    color: "rgb(31 39 50 / 100)",
    padding: "15px 20px",
    p1: "15px",
    p2: "20px",
    "font-size": "16px",
    "font-weight": "normal",
    border: "1px solid rgb(215 226 230 / 100)",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: "rgb(215 226 230 / 100)",
  },
  ".detail-sidebar .other-link li .fa": {
    "margin-right": "10px",
  },
  ".detail-sidebar .other-link li a:hover, .detail-sidebar .other-link li.active a":
    {
      color: "rgb(255 73 124 / 100)",
      "border-left": "5px solid rgb(255 73 124 / 100)",
      borderLfPx: "5px",
      borderLfThik: "solid",
      borderLfColor: "rgb(255 73 124 / 100)",
    },
  ".detail-sidebar .widget-form": {
    display: "inline-block",
    width: "100%",
    padding: "25px 35px",
    p1: "25px",
    p2: "35px",
    "background-color": "rgb(31 39 50 / 100)",
  },
  ".detail-sidebar .widget-form .form-control": {
    padding: "13px",
  },
  ".detail-sidebar .widget-form .btn-submit": {
    "background-color": "rgb(255 73 124 / 100)",
    border: "2px solid rgb(255 73 124 / 100)",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: "rgb(255 73 124 / 100)",
    color: "rgb(255 255 255 / 100)",
    "font-size": "16px",
    "font-weight": "600",
    padding: "8px 25px",
    p1: "8px",
    p2: "25px",
    width: "100%",
  },
  ".boxes1-section .more-btn": {
    color: "rgb(255 255 255 / 100)",
    "font-size": "16px",
    "font-weight": "normal",
    "border-radius": "15px",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "background-color": "rgb(255 73 124 / 100)",
  },
  ".boxes1-section .more-btn:hover": {
    "background-color": "rgb(255 73 124 / 100)",
    color: "rgb(255 255 255 / 100)",
  },
  ".pagination-positions": {
    "justify-content": "center",
  },
  ".pagination .page-link": {
    color: "rgb(255 73 124 / 100)",
  },
  ".pagination .page-item.active .page-link": {
    "background-color": "rgb(255 73 124 / 100)",
    "border-color": "rgb(255 73 124 / 100)",
  },
};

export const serviceAndCategoryTheme8 = {
  ".boxes2-section": {
    padding: "60px 0",
    "background-color": "rgb(249 249 249 / 100)",
  },
  ".boxes2-section .section-title-area": {
    "margin-bottom": "50px",
  },
  ".boxes2-section .section-title-area .section-subtitle": {
    "font-size": "16px",
    "font-weight": "600",
    color: "rgb(255 73 124 / 100)",
    "background-color": "rgba(255, 73, 124, 0.2)",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "border-radius": "15px",
    "margin-bottom": "15px",
  },
  ".boxes2-section .section-title-area .section-title": {
    "font-size": "44px",
    "font-weight": "600",
    color: "rgb(0 0 0 / 100)",
  },
  ".boxes2-section .cus-boxes": {
    "border-radius": "4px",
    padding: "30px 15px",
    p1: "30px",
    p2: "15px",
    margin: "10px 0",
  },
  ".boxes2-section .cus-boxes:hover": {
    "background-color": "rgb(255 255 255 / 100)",
    "box-shadow": "0 0 15px rgba(121, 115, 115, 0.348)",
    bs1: "0px",
    bs2: "0px",
    bs3: "15px",
    shadowColor: "rgba(121 115 115 / 0.348)",
  },
  ".boxes2-section .flaticon": {
    "max-width": "100%",
  },
  ".boxes2-section .subtitle": {
    color: "rgb(35 50 83 / 100)",
    "font-size": "18px",
    "line-height": "1.5",
    "font-weight": "600",
    margin: "10px 0 0",
    m1: "10px",
    m2: "0px",
    m3: "0px",
  },
  ".service-detail-section": {
    padding: "60px 0",
    "background-color": "rgb(255 255 255 / 100)",
  },
  ".service-detail-section.bg-image": {
    "background-image": "",
  },
  ".service-detail-section.bg-image::before": {
    "background-color": "",
  },
  ".detail-sidebar": {
    display: "inline-block",
    width: "100%",
  },
  ".detail-sidebar .other-link li a": {
    "background-color": "rgb(244 244 245 / 100)",
    color: "rgb(31 39 50 / 100)",
    padding: "15px 20px",
    p1: "15px",
    p2: "20px",
    "font-size": "16px",
    "font-weight": "normal",
    border: "1px solid rgb(215 226 230 / 100)",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: "rgb(215 226 230 / 100)",
  },
  ".detail-sidebar .other-link li .fa": {
    "margin-right": "10px",
  },
  ".detail-sidebar .other-link li a:hover, .detail-sidebar .other-link li.active a":
    {
      color: "rgb(255 73 124 / 100)",
      "border-left": "5px solid rgb(255 73 124 / 100)",
      borderLfPx: "5px",
      borderLfThik: "solid",
      borderLfColor: "rgb(255 73 124 / 100)",
    },
  ".detail-sidebar .widget-form": {
    display: "inline-block",
    width: "100%",
    padding: "25px 35px",
    p1: "25px",
    p2: "35px",
    "background-color": "rgb(31 39 50 / 100)",
  },
  ".detail-sidebar .widget-form .form-control": {
    padding: "13px",
  },
  ".detail-sidebar .widget-form .btn-submit": {
    "background-color": "rgb(255 73 124 / 100)",
    border: "2px solid rgb(255 73 124 / 100)",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: "rgb(255 73 124 / 100)",
    color: "rgb(255 255 255 / 100)",
    "font-size": "16px",
    "font-weight": "600",
    padding: "8px 25px",
    p1: "8px",
    p2: "25px",
    width: "100%",
  },
  ".boxes2-section .more-btn": {
    color: "rgb(255 255 255 / 100)",
    "font-size": "16px",
    "font-weight": "normal",
    "border-radius": "15px",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "background-color": "rgb(255 73 124 / 100)",
  },
  ".boxes2-section .more-btn:hover": {
    "background-color": "rgb(255 73 124 / 100)",
    color: "rgb(255 255 255 / 100)",
  },
  ".pagination-positions": {
    "justify-content": "center",
  },
  ".pagination .page-link": {
    color: "rgb(255 73 124 / 100)",
  },
  ".pagination .page-item.active .page-link": {
    "background-color": "rgb(255 73 124 / 100)",
    "border-color": "rgb(255 73 124 / 100)",
  },
};

export const serviceAndCategoryTheme9 = {
  ".boxes3-section": {
    padding: "60px 0",
    "background-color": "rgb(249 249 249 / 100)",
  },
  ".boxes3-section .section-title-area": {
    "margin-bottom": "50px",
  },
  ".boxes3-section .section-title-area .section-subtitle": {
    display: "inline-block",
    "font-size": "16px",
    "font-weight": "600",
    color: "rgb(255 73 124 / 100)",
    "background-color": "rgba(255, 73, 124, 0.2)",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "border-radius": "15px",
    "margin-bottom": "15px",
  },
  ".boxes3-section .section-title-area .section-title": {
    "font-size": "44px",
    "font-weight": "600",
    color: "rgb(0 0 0 / 100)",
  },
  ".boxes3-section .cus-boxes": {
    display: "block",
    width: "100%",
    overflow: "hidden",
    "border-radius": "4px",
    padding: "40px 15px",
    p1: "40px",
    p2: "15px",
    margin: "10px 0",
    "background-color": "rgb(255 255 255 / 100)",
    border: "1px solid rgb(238 238 238 / 100)",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: "rgb(238 238 238 / 100)",
  },
  ".boxes3-section .flaticon": {
    "max-width": "100%",
  },
  ".boxes3-section .subtitle": {
    color: "rgb(35 50 83 / 100)",
    "font-size": "18px",
    "line-height": "1.5",
    "font-weight": "600",
    margin: "10px 0 0",
    m1: "10px",
    m2: "0px",
    m3: "0px",
  },
  ".service-detail-section": {
    padding: "60px 0",
    "background-color": "rgb(255 255 255 / 100)",
  },
  ".service-detail-section.bg-image": {
    "background-image": "",
  },
  ".service-detail-section.bg-image::before": {
    "background-color": "",
  },
  ".detail-sidebar": {
    display: "inline-block",
    width: "100%",
  },
  ".detail-sidebar .other-link li a": {
    "background-color": "rgb(244 244 245 / 100)",
    color: "rgb(31 39 50 / 100)",
    padding: "15px 20px",
    p1: "15px",
    p2: "20px",
    "font-size": "16px",
    "font-weight": "normal",
    border: "1px solid rgb(215 226 230 / 100)",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: "rgb(215 226 230 / 100)",
  },
  ".detail-sidebar .other-link li .fa": {
    "margin-right": "10px",
  },
  ".detail-sidebar .other-link li a:hover, .detail-sidebar .other-link li.active a":
    {
      color: "rgb(255 73 124 / 100)",
      "border-left": "5px solid rgb(255 73 124 / 100)",
      borderLfPx: "5px",
      borderLfThik: "solid",
      borderLfColor: "rgb(255 73 124 / 100)",
    },
  ".detail-sidebar .widget-form": {
    display: "inline-block",
    width: "100%",
    padding: "25px 35px",
    p1: "25px",
    p2: "35px",
    "background-color": "rgb(31 39 50 / 100)",
  },
  ".detail-sidebar .widget-form .form-control": {
    padding: "13px",
  },
  ".detail-sidebar .widget-form .btn-submit": {
    "background-color": "rgb(255 73 124 / 100)",
    border: "2px solid rgb(255 73 124 / 100)",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: "rgb(255 73 124 / 100)",
    color: "rgb(255 255 255 / 100)",
    "font-size": "16px",
    "font-weight": "600",
    padding: "8px 25px",
    p1: "8px",
    p2: "25px",
    width: "100%",
  },
  ".boxes3-section .more-btn": {
    color: "rgb(255 255 255 / 100)",
    "font-size": "16px",
    "font-weight": "normal",
    "border-radius": "15px",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "background-color": "rgb(255 73 124 / 100)",
  },
  ".boxes3-section .more-btn:hover": {
    "background-color": "rgb(255 73 124 / 100)",
    color: "rgb(255 255 255 / 100)",
  },
  ".pagination-positions": {
    "justify-content": "center",
  },
  ".pagination .page-link": {
    color: "rgb(255 73 124 / 100)",
  },
  ".pagination .page-item.active .page-link": {
    "background-color": "rgb(255 73 124 / 100)",
    "border-color": "rgb(255 73 124 / 100)",
  },
};

export const serviceAndCategoryTheme10 = {
  ".boxes4-section": {
    padding: "60px 0",
    "background-color": "rgb(249 249 249 / 100)",
  },
  ".boxes4-section .section-title-area": {
    "margin-bottom": "50px",
  },
  ".boxes4-section .section-title-area .section-subtitle": {
    display: "inline-block",
    "font-size": "16px",
    "font-weight": "600",
    color: "rgb(255 73 124 / 100)",
    "background-color": "rgba(255, 73, 124, 0.2)",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "border-radius": "15px",
    "margin-bottom": "15px",
  },
  ".boxes4-section .section-title-area .section-title": {
    "font-size": "44px",
    "font-weight": "600",
    color: "rgb(0 0 0 / 100)",
  },
  ".boxes4-section .cus-boxes": {
    "border-radius": "4px",
    padding: "40px 15px",
    p1: "40px",
    p2: "15px",
    margin: "10px 0",
    background:
      "linear-gradient(145deg, rgb(226 232 236 / 100), rgb(255 255 255 / 100))",
    backColorOne: "rgb(226 232 236 / 100)",
    backColorTwo: "rgb(255 255 255 / 100)",
    "box-shadow": "5px 5px 15px rgb(209 217 230 / 100)",
    bs1: "5px",
    bs2: "5px",
    bs3: "15px",
    shadowColor: "rgb(209 217 230 / 100)",
  },
  ".boxes4-section .flaticon": {
    "max-width": "100%",
  },
  ".boxes4-section .subtitle": {
    color: "rgb(35 50 83 / 100)",
    "font-size": "18px",
    "line-height": "1.5",
    "font-weight": "600",
    margin: "10px 0 0",
    m1: "10px",
    m2: "0px",
    m3: "0px",
  },
  ".service-detail-section": {
    padding: "60px 0",
    "background-color": "rgb(255 255 255 / 100)",
  },
  ".service-detail-section.bg-image": {
    "background-image": "",
  },
  ".service-detail-section.bg-image::before": {
    "background-color": "",
  },
  ".detail-sidebar": {
    display: "inline-block",
    width: "100%",
  },
  ".detail-sidebar .other-link li a": {
    "background-color": "rgb(244 244 245 / 100)",
    color: "rgb(31 39 50 / 100)",
    padding: "15px 20px",
    p1: "15px",
    p2: "20px",
    "font-size": "16px",
    "font-weight": "normal",
    border: "1px solid rgb(215 226 230 / 100)",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: "rgb(215 226 230 / 100)",
  },
  ".detail-sidebar .other-link li .fa": {
    "margin-right": "10px",
  },
  ".detail-sidebar .other-link li a:hover, .detail-sidebar .other-link li.active a":
    {
      color: "rgb(255 73 124 / 100)",
      "border-left": "5px solid rgb(255 73 124 / 100)",
      borderLfPx: "5px",
      borderLfThik: "solid",
      borderLfColor: "rgb(255 73 124 / 100)",
    },
  ".detail-sidebar .widget-form": {
    display: "inline-block",
    width: "100%",
    padding: "25px 35px",
    p1: "25px",
    p2: "35px",
    "background-color": "rgb(31 39 50 / 100)",
  },
  ".detail-sidebar .widget-form .form-control": {
    padding: "13px",
  },
  ".detail-sidebar .widget-form .btn-submit": {
    "background-color": "rgb(255 73 124 / 100)",
    border: "2px solid rgb(255 73 124 / 100)",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: "rgb(255 73 124 / 100)",
    color: "rgb(255 255 255 / 100)",
    "font-size": "16px",
    "font-weight": "600",
    padding: "8px 25px",
    p1: "8px",
    p2: "25px",
    width: "100%",
  },
  ".boxes4-section .more-btn": {
    color: "rgb(255 255 255 / 100)",
    "font-size": "16px",
    "font-weight": "normal",
    "border-radius": "15px",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "background-color": "rgb(255 73 124 / 100)",
  },
  ".boxes4-section .more-btn:hover": {
    "background-color": "rgb(255 73 124 / 100)",
    color: "rgb(255 255 255 / 100)",
  },
  ".pagination-positions": {
    "justify-content": "center",
  },
  ".pagination .page-link": {
    color: "rgb(255 73 124 / 100)",
  },
  ".pagination .page-item.active .page-link": {
    "background-color": "rgb(255 73 124 / 100)",
    "border-color": "rgb(255 73 124 / 100)",
  },
};

export const serviceAndCategoryTheme11 = {
  ".left-boxes1-section": {
    padding: "60px 0",
    "background-color": "rgb(243 243 243 / 100)",
  },
  ".left-boxes1-section .cus-boxes": {
    margin: "10px 0",
    padding: "25px 15px",
    p1: "25px",
    p2: "15px",
    "background-color": "rgb(255 255 255 / 100)",
    "box-shadow": "0px 14px 70px 0px rgb(244 245 253 / 100)",
    bs1: "0px",
    bs2: "14px",
    bs3: "70px",
    bs4: "0px",
    shadowColor: "rgb(244 245 253 / 100)",
    border: "1px solid rgb(238 238 238 / 100)",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: "rgb(238 238 238 / 100)",
    "border-radius": "10px",
  },
  ".left-boxes1-section .flaticon": {
    width: "70px",
    height: "70px",
    "margin-right": "10px",
    "border-radius": "50%",
    "background-color": "rgba(255, 73, 124, 0.2)",
    border: "1px solid rgb(255 73 124 / 100)",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: "rgb(255 73 124 / 100)",
  },
  ".left-boxes1-section .subdiv h4": {
    color: "rgb(255 73 124 / 100)",
    "font-size": "22px",
    "line-height": "1.3",
    "font-weight": "600",
  },
  ".left-boxes1-section .subdiv p": {
    color: "rgb(0 0 0 / 100)",
    "font-size": "16px",
    "line-height": "1.4",
    "font-weight": "normal",
  },
  ".service-detail-section": {
    padding: "60px 0",
    "background-color": "rgb(255 255 255 / 100)",
  },
  ".service-detail-section.bg-image": {
    "background-image": "",
  },
  ".service-detail-section.bg-image::before": {
    "background-color": "",
  },
  ".detail-sidebar": {
    display: "inline-block",
    width: "100%",
  },
  ".detail-sidebar .other-link li a": {
    "background-color": "rgb(244 244 245 / 100)",
    color: "rgb(31 39 50 / 100)",
    padding: "15px 20px",
    p1: "15px",
    p2: "20px",
    "font-size": "16px",
    "font-weight": "normal",
    border: "1px solid rgb(215 226 230 / 100)",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: "rgb(215 226 230 / 100)",
  },
  ".detail-sidebar .other-link li .fa": {
    "margin-right": "10px",
  },
  ".detail-sidebar .other-link li a:hover, .detail-sidebar .other-link li.active a":
    {
      color: "rgb(255 73 124 / 100)",
      "border-left": "5px solid rgb(255 73 124 / 100)",
      borderLfPx: "5px",
      borderLfThik: "solid",
      borderLfColor: "rgb(255 73 124 / 100)",
    },
  ".detail-sidebar .widget-form": {
    display: "inline-block",
    width: "100%",
    padding: "25px 35px",
    p1: "25px",
    p2: "35px",
    "background-color": "rgb(31 39 50 / 100)",
  },
  ".detail-sidebar .widget-form .form-control": {
    padding: "13px",
  },
  ".detail-sidebar .widget-form .btn-submit": {
    "background-color": "rgb(255 73 124 / 100)",
    border: "2px solid rgb(255 73 124 / 100)",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: "rgb(255 73 124 / 100)",
    color: "rgb(255 255 255 / 100)",
    "font-size": "16px",
    "font-weight": "600",
    padding: "8px 25px",
    p1: "8px",
    p2: "25px",
    width: "100%",
  },
  ".left-boxes1-section .more-btn": {
    color: "rgb(255 255 255 / 100)",
    "font-size": "16px",
    "font-weight": "normal",
    "border-radius": "15px",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "background-color": "rgb(255 73 124 / 100)",
  },
  ".left-boxes1-section .more-btn:hover": {
    "background-color": "rgb(255 73 124 / 100)",
    color: "rgb(255 255 255 / 100)",
  },
  ".pagination-positions": {
    "justify-content": "center",
  },
  ".pagination .page-link": {
    color: "rgb(255 73 124 / 100)",
  },
  ".pagination .page-item.active .page-link": {
    "background-color": "rgb(255 73 124 / 100)",
    "border-color": "rgb(255 73 124 / 100)",
  },
};
export const serviceAndCategoryTheme12 = {
  ".left-boxes2-section": {
    padding: "60px 0",
    "background-color": "rgb(255 248 239 / 100)",
  },
  ".left-boxes2-section .cus-boxes": {
    margin: "10px 0",
    padding: "15px",
    "border-right": "1px solid rgb(238 238 238 / 100)",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: "rgb(238 238 238 / 100)",
  },
  ".left-boxes2-section .flaticon": {
    width: "70px",
    "margin-right": "10px",
  },
  ".left-boxes2-section .subdiv h4": {
    color: "rgb(255 73 124 / 100)",
    "font-size": "22px",
    "line-height": "1.3",
    "font-weight": "600",
    "margin-bottom": "5px",
  },
  ".left-boxes2-section .subdiv p": {
    color: "rgb(0 0 0 / 100)",
    "font-size": "16px",
    "line-height": "1.4",
    "font-weight": "normal",
  },
  ".service-detail-section": {
    padding: "60px 0",
    "background-color": "rgb(255 255 255 / 100)",
  },
  ".service-detail-section.bg-image": {
    "background-image": "",
  },
  ".service-detail-section.bg-image::before": {
    "background-color": "",
  },
  ".detail-sidebar": {
    display: "inline-block",
    width: "100%",
  },
  ".detail-sidebar .other-link li a": {
    "background-color": "rgb(244 244 245 / 100)",
    color: "rgb(31 39 50 / 100)",
    padding: "15px 20px",
    p1: "15px",
    p2: "20px",
    "font-size": "16px",
    "font-weight": "normal",
    border: "1px solid rgb(215 226 230 / 100)",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: "rgb(215 226 230 / 100)",
  },
  ".detail-sidebar .other-link li .fa": {
    "margin-right": "10px",
  },
  ".detail-sidebar .other-link li a:hover, .detail-sidebar .other-link li.active a":
    {
      color: "rgb(255 73 124 / 100)",
      "border-left": "5px solid rgb(255 73 124 / 100)",
      borderLfPx: "5px",
      borderLfThik: "solid",
      borderLfColor: "rgb(255 73 124 / 100)",
    },
  ".detail-sidebar .widget-form": {
    display: "inline-block",
    width: "100%",
    padding: "25px 35px",
    p1: "25px",
    p2: "35px",
    "background-color": "rgb(31 39 50 / 100)",
  },
  ".detail-sidebar .widget-form .form-control": {
    padding: "13px",
  },
  ".detail-sidebar .widget-form .btn-submit": {
    "background-color": "rgb(255 73 124 / 100)",
    border: "2px solid rgb(255 73 124 / 100)",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: "rgb(255 73 124 / 100)",
    color: "rgb(255 255 255 / 100)",
    "font-size": "16px",
    "font-weight": "600",
    padding: "8px 25px",
    p1: "8px",
    p2: "25px",
    width: "100%",
  },
  ".left-boxes2-section .more-btn": {
    color: "rgb(255 255 255 / 100)",
    "font-size": "16px",
    "font-weight": "normal",
    "border-radius": "15px",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "background-color": "rgb(255 73 124 / 100)",
  },
  ".left-boxes2-section .more-btn:hover": {
    "background-color": "rgb(255 73 124 / 100)",
    color: "rgb(255 255 255 / 100)",
  },
  ".pagination-positions": {
    "justify-content": "center",
  },
  ".pagination .page-link": {
    color: "rgb(255 73 124 / 100)",
  },
  ".pagination .page-item.active .page-link": {
    "background-color": "rgb(255 73 124 / 100)",
    "border-color": "rgb(255 73 124 / 100)",
  },
};

export const serviceAndCategoryTheme13 = {
  ".left-boxes3-section": {
    padding: "60px 0",
    "background-color": "rgb(249 249 249 / 100)",
  },
  ".left-boxes3-section .boxdiv": {
    padding: "35px 30px",
    p1: "35px",
    p2: "30px",
    border: "1px solid rgb(221 221 221 / 100)",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: "rgb(221 221 221 / 100)",
    "background-color": "rgb(255 255 255 / 100)",
  },
  ".left-boxes3-section .boxdiv:hover": {
    "background-color": "rgb(44 63 105 / 100)",
  },
  ".left-boxes3-section .boxdiv h5": {
    color: "rgb(0 0 0 / 100)",
    "font-weight": "600",
    "font-size": "20px",
    "margin-bottom": "15px",
  },
  ".left-boxes3-section .boxdiv:hover h5": {
    color: "rgb(255 255 255 / 100)",
  },
  ".left-boxes3-section .boxdiv p": {
    color: "rgb(0 0 0 / 100)",
    "font-weight": "400",
    "font-size": "14px",
    "line-height": "1.6",
    "margin-bottom": "15px",
  },
  ".left-boxes3-section .boxdiv:hover p": {
    color: "rgb(255 255 255 / 100)",
  },
  ".left-boxes3-section .boxdiv .links": {
    "font-size": "16px",
    "font-weight": "500",
    color: "rgb(255 73 124 / 100)",
  },
  ".left-boxes3-section .boxdiv:hover .links": {
    color: "rgb(255 255 255 / 100)",
  },
  ".service-detail-section": {
    padding: "60px 0",
    "background-color": "rgb(255 255 255 / 100)",
  },
  ".service-detail-section.bg-image": {
    "background-image": "",
  },
  ".service-detail-section.bg-image::before": {
    "background-color": "",
  },
  ".detail-sidebar": {
    display: "inline-block",
    width: "100%",
  },
  ".detail-sidebar .other-link li a": {
    "background-color": "rgb(244 244 245 / 100)",
    color: "rgb(31 39 50 / 100)",
    padding: "15px 20px",
    p1: "15px",
    p2: "20px",
    "font-size": "16px",
    "font-weight": "normal",
    border: "1px solid rgb(215 226 230 / 100)",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: "rgb(215 226 230 / 100)",
  },
  ".detail-sidebar .other-link li .fa": {
    "margin-right": "10px",
  },
  ".detail-sidebar .other-link li a:hover, .detail-sidebar .other-link li.active a":
    {
      color: "rgb(255 73 124 / 100)",
      "border-left": "5px solid rgb(255 73 124 / 100)",
      borderLfPx: "5px",
      borderLfThik: "solid",
      borderLfColor: "rgb(255 73 124 / 100)",
    },
  ".detail-sidebar .widget-form": {
    display: "inline-block",
    width: "100%",
    padding: "25px 35px",
    p1: "25px",
    p2: "35px",
    "background-color": "rgb(31 39 50 / 100)",
  },
  ".detail-sidebar .widget-form .form-control": {
    padding: "13px",
  },
  ".detail-sidebar .widget-form .btn-submit": {
    "background-color": "rgb(255 73 124 / 100)",
    border: "2px solid rgb(255 73 124 / 100)",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: "rgb(255 73 124 / 100)",
    color: "rgb(255 255 255 / 100)",
    "font-size": "16px",
    "font-weight": "600",
    padding: "8px 25px",
    p1: "8px",
    p2: "25px",
    width: "100%",
  },
  ".left-boxes3-section .more-btn": {
    color: "rgb(255 255 255 / 100)",
    "font-size": "16px",
    "font-weight": "normal",
    "border-radius": "15px",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "background-color": "rgb(255 73 124 / 100)",
  },
  ".left-boxes3-section .more-btn:hover": {
    "background-color": "rgb(255 73 124 / 100)",
    color: "rgb(255 255 255 / 100)",
  },
  ".pagination-positions": {
    "justify-content": "center",
  },
  ".pagination .page-link": {
    color: "rgb(255 73 124 / 100)",
  },
  ".pagination .page-item.active .page-link": {
    "background-color": "rgb(255 73 124 / 100)",
    "border-color": "rgb(255 73 124 / 100)",
  },
};

export const serviceAndCategoryTheme14 = {
  ".table-list-wrapper .table-list .list-head": {
    "background-color": "rgb(112 48 160 / 100)",
    border: "6px solid rgb(255 217 102 / 100)",
    borderPx: "6px",
    borderThik: "solid",
    borderColor: "rgb(255 217 102 / 100)",
    padding: "10px",
    "margin-bottom": "20px",
  },
  ".table-list-wrapper .table-list .list-head h2": {
    color: "rgb(255 217 102 / 100)",
    "font-size": "36px",
    "font-family": "'Poppins'",
  },
  ".table-list-wrapper .table-list .body-ttl": {
    border: "4px solid rgb(0 0 0 / 100)",
    borderPx: "4px",
    borderThik: "solid",
    borderColor: "rgb(0 0 0 / 100)",
    padding: "10px",
    "margin-bottom": "16px",
    "font-family": "'Poppins'",
  },
  ".table-list-wrapper .table-list .list-body": {
    border: "4px solid #000000",
    borderPx: "4px",
    borderThik: "solid",
    borderColor: "rgb(0 0 0 / 100)",
    padding: "8px 16px 24px",
    p1: "8px",
    p2: "16px",
    p3: "24px",
  },
  ".table-list-wrapper .table-list .list-body ul": {
    "font-size": "18px",
    "list-style-type": "disc",
    "padding-left": "20px",
  },
  ".table-list-wrapper .table-list .list-body ul li": {
    padding: "6px 20px 6px 6px",
    p1: "6px",
    p2: "20px",
    p3: "6px",
    p4: "6px",
  },
  ".table-list-wrapper .table-list .list-body ul li a": {
    color: "#000000",
  },
  ".service-detail-section": {
    padding: "60px 0",
    "background-color": "rgb(255 255 255 / 100)",
  },
  ".service-detail-section.bg-image": {
    "background-image": "",
  },
  ".service-detail-section.bg-image::before": {
    "background-color": "",
  },
  ".detail-sidebar": {
    display: "inline-block",
    width: "100%",
  },
  ".detail-sidebar .other-link li a": {
    "background-color": "rgb(244 244 245 / 100)",
    color: "rgb(31 39 50 / 100)",
    padding: "15px 20px",
    p1: "15px",
    p2: "20px",
    "font-size": "16px",
    "font-weight": "normal",
    border: "1px solid rgb(215 226 230 / 100)",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: "rgb(215 226 230 / 100)",
  },
  ".detail-sidebar .other-link li .fa": {
    "margin-right": "10px",
  },
  ".detail-sidebar .other-link li a:hover, .detail-sidebar .other-link li.active a":
    {
      color: "rgb(255 73 124 / 100)",
      "border-left": "5px solid rgb(255 73 124 / 100)",
      borderLfPx: "5px",
      borderLfThik: "solid",
      borderLfColor: "rgb(255 73 124 / 100)",
    },
  ".detail-sidebar .widget-form": {
    display: "inline-block",
    width: "100%",
    padding: "25px 35px",
    p1: "25px",
    p2: "35px",
    "background-color": "rgb(31 39 50 / 100)",
  },
  ".detail-sidebar .widget-form .form-control": {
    padding: "13px",
  },
  ".detail-sidebar .widget-form .btn-submit": {
    "background-color": "rgb(255 73 124 / 100)",
    border: "2px solid rgb(255 73 124 / 100)",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: "rgb(255 73 124 / 100)",
    color: "rgb(255 255 255 / 100)",
    "font-size": "16px",
    "font-weight": "600",
    padding: "8px 25px",
    p1: "8px",
    p2: "25px",
    width: "100%",
  },
  ".table-list-wrapper .more-btn": {
    color: "rgb(255 255 255 / 100)",
    "font-size": "16px",
    "font-weight": "normal",
    "border-radius": "15px",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "background-color": "rgb(255 73 124 / 100)",
  },
  ".table-list-wrapper .more-btn:hover": {
    "background-color": "rgb(255 73 124 / 100)",
    color: "rgb(255 255 255 / 100)",
  },
  ".pagination-positions": {
    "justify-content": "center",
  },
  ".pagination .page-link": {
    color: "rgb(255 73 124 / 100)",
  },
  ".pagination .page-item.active .page-link": {
    "background-color": "rgb(255 73 124 / 100)",
    "border-color": "rgb(255 73 124 / 100)",
  },
};

export const ImageTheme1 = {
  ".photo-gallery-section": {
    padding: "100px 0",
    "background-color": "rgb(255 255 255 / 100)",
  },
  ".photo-gallery-section.bg-image": {
    "background-image": "",
  },
  ".photo-gallery-section.bg-image::before": {
    "background-color": "",
  },
  ".photo-gallery-section .section-title-area": {
    "margin-bottom": "50px",
  },
  ".photo-gallery-section .section-title-area .section-subtitle": {
    "font-size": "16px",
    "font-weight": "600",
    color: "rgb(255 73 124 / 100)",
    "background-color": "rgb(255 73 124 / 20%)",
    padding: "5px 20px",
    "border-radius": "15px",
    "margin-bottom": "15px",
  },
  ".photo-gallery-section .section-title-area .section-title": {
    "font-size": "44px",
    "font-weight": "600",
    color: "rgb(0 0 0 / 100)",
  },
  ".photo-gallery-section .gallery-catlist": {
    "text-align": "center",
    "margin-bottom": "30px",
  },
  ".photo-gallery-section .gallery-catlist .btn": {
    border: "2px solid rgb(255 73 124 / 100)",
    color: "rgb(255 73 124 / 100)",
    padding: "6px 20px",
    margin: "5px",
    "box-shadow": "none",
  },
  ".photo-gallery-section .gallery-catlist .active": {
    "background-color": "rgb(255 73 124 / 100)",
    color: "rgb(255 255 255 / 100)",
  },
  ".photo-gallery-section .gallery-catdata .box": {
    "margin-bottom": "15px",
    outline: "none",
    "background-color": "rgb(255 255 255 / 100)",
    "box-shadow": "0 5px 10px 0 rgba(7, 28, 31, 0.1)",
    "border-radius": "0px",
  },
  ".photo-gallery-section .gallery-catdata .gallery-img::before": {
    "font-family": "FontAwesome",
    color: "rgb(255 255 255 / 100)",
    "font-size": "30px",
  },
  ".photo-gallery-section .gallery-catdata .box:hover .gallery-img::before": {
    opacity: "1",
  },
  ".photo-gallery-section .gallery-catdata .gallery-img .title-text": {
    "background-color": "rgb(255 73 124 / 100)",
    "font-size": "16px",
    "font-weight": "600",
    color: "rgb(255 255 255 / 100)",
    "text-align": "center",
    padding: "14px",
  },
  ".photo-gallery-section .more-btn": {
    color: "rgb(255 255 255 / 100)",
    "font-size": "16px",
    "font-weight": "normal",
    "border-radius": "15px",
    padding: "5px 20px",
    "background-color": "rgb(255 73 124 / 100)",
  },
  ".photo-gallery-section .more-btn:hover": {
    "background-color": "rgb(255 73 124 / 100)",
    color: "rgb(255 255 255 / 100)",
  },
  ".pagination-positions": {
    "justify-content": "center",
  },
  ".pagination .page-link": {
    color: "rgb(255 73 124 / 100)",
  },
  ".pagination .page-item.active .page-link": {
    "background-color": "rgb(255 73 124 / 100)",
    "border-color": "rgb(255 73 124 / 100)",
  },
  showItem: 4,
};

export const ImageTheme2 = {
  ".certificates-section": {
    padding: "100px 0",
    "background-color": "rgb(255 255 255 / 100)",
  },
  ".certificates-section.bg-image": {
    "background-image": "",
  },
  ".certificates-section.bg-image::before": {
    "background-color": "",
    // "background-color": "#000000CC",
  },
  ".certificates-section .section-title-area": {
    "margin-bottom": "50px",
  },
  ".certificates-section .section-title-area .section-subtitle": {
    "font-size": "16px",
    "font-weight": "600",
    color: "rgb(255 73 124 / 100)",
    "background-color": "rgb(255 73 124 / 0.2)",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "border-radius": "15px",
    "margin-bottom": "15px",
  },
  ".certificates-section .section-title-area .section-title": {
    "font-size": "44px",
    "font-weight": "600",
    color: "rgb(0 0 0 / 100)",
  },
  ".certificates-section .certificates-list .box": {
    "margin-bottom": "20px",
    outline: "none",
    "background-color": "rgb(255 255 255 / 100)",
    "box-shadow": "0 5px 10px 0 rgba(7, 28, 31, 0.1)",
    bxs1: "5px",
    bxs2: "10px",
    bxsColor: "rgba(7, 28, 31, 0.1)",
    "border-radius": "0px",
  },
  ".certificates-section .certificates-list .certificates-img:before": {
    opacity: "0",
    color: "rgb(255 255 255 / 100)",
    "font-size": "30px",
  },
  ".certificates-section .certificates-list .box:hover .certificates-img:before":
    {
      opacity: "1",
    },
  ".certificates-section .certificates-list .title-text": {
    "background-color": "rgb(255 73 124 / 100)",
    "font-size": "18px",
    "font-weight": "600",
    color: "rgb(255 255 255 / 100)",
    "text-align": "center",
    padding: "15px",
  },
  ".gallery-catlist": {
    "text-align": "center",
    "margin-bottom": "30px",
  },
  ".gallery-catlist .btn": {
    border: "2px solid rgb(255 73 124 / 100)",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: "rgb(255 73 124 / 100)",
    color: "rgb(255 73 124 / 100)",
    padding: "6px 20px",
    p1: "6px",
    p2: "20px",
    margin: "5px",
    "box-shadow": "none",
  },
  ".gallery-catlist .active": {
    "background-color": "rgb(255 73 124 / 100)",
    color: "rgb(255 255 255 / 100)",
  },
  ".certificates-section .more-btn": {
    color: "rgb(255 255 255 / 100)",
    "font-size": "16px",
    "font-weight": "normal",
    "border-radius": "15px",
    padding: "5px 20px",
    "background-color": "rgb(255 73 124 / 100)",
  },
  ".certificates-section .more-btn:hover": {
    "background-color": "rgb(255 73 124 / 100)",
    color: "rgb(255 255 255 / 100)",
  },
  ".pagination-positions": {
    "justify-content": "center",
  },
  ".pagination .page-link": {
    color: "rgb(255 73 124 / 100)",
  },
  ".pagination .page-item.active .page-link": {
    "background-color": "rgb(255 73 124 / 100)",
    "border-color": "rgb(255 73 124 / 100)",
  },
  galleryImgId: "",
  showItem: 4,
};

export const ourClientTheme1 = {
  ".clients-section": {
    padding: "100px 0",
    "background-color": "rgb(255 255 255 / 100)",
  },
  ".clients-section.bg-image": {},
  ".clients-section.bg-image::before": {
    // { r: 0, g: 0, b: 0, a: 0.8 }
  },
  ".clients-section .section-title-area .section-subtitle": {
    "font-size": "16px",
    "font-weight": "600",
    color: "rgb(255 73 124 / 100)",
    "background-color": "rgb(255 73 124 / 0.2)",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "border-radius": "15px",
    "margin-bottom": "15px",
  },
  ".clients-section .section-title-area .section-title": {
    "font-size": "44px",
    "font-weight": "600",
    color: "rgb(0 0 0 / 100)",
    "margin-bottom": "10px",
  },
  ".clients-slider .slick-arrow:before": {
    color: "rgb(255 73 124 / 100)",
  },
  ".clients-slider .client-img": {
    padding: "10px",
    "max-width": "300px",
    "max-height": "120px",
  },
  ourClientId: "",
};

export const ourClientTheme2 = {
  ".partner-section": {
    padding: "40px 0",
    "background-color": "rgb(255 255 255 / 100)",
  },
  ".partner-section .imgdiv": {
    padding: "30px 15px",
    p1: "30px",
    p2: "15px",
    "border-right": "1px solid rgb(221 221 221 / 100)",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: "rgb(221 221 221 / 100)",
  },
};

export const serviceCounterTheme1 = {
  ".counter-section": {
    padding: "100px 0",
    "background-color": "rgb(0 0 0 / 100)",
  },
  ".counter-section.bg-image": {
    "background-image": "",
  },
  ".counter-section.bg-image::before": {
    "background-color": "",
  },
  ".counter-section .section-title-area": {
    "margin-bottom": "50px",
  },
  ".counter-section .section-title-area .section-subtitle": {
    "font-size": "16px",
    "font-weight": "600",
    color: "rgb(255 73 124 / 100)",
    "background-color": "rgb(255 73 124 / 0.2)",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "border-radius": "15px",
    "margin-bottom": "15px",
  },
  ".counter-section .section-title-area .section-title": {
    "font-size": "44px",
    "font-weight": "600",
    color: "rgb(255 255 255 / 100)",
  },
  ".counter-section .countdiv": {
    "text-align": "center",
    "margin-bottom": "30px",
  },
  ".counter-section .countdiv .counter": {
    color: "rgb(255 73 124 / 100)",
    "font-size": "40px",
    "font-weight": "600",
  },
  ".counter-section .countdiv .divider": {
    width: "80px",
    height: "4px",
    margin: "15px auto",
    "border-radius": "3px",
    "background-color": "rgb(255 255 255 / 100)",
  },
  ".counter-section .countdiv .counter-title": {
    color: "rgb(255 255 255 / 100)",
    "font-size": "22px",
    "font-weight": "500",
  },
};
export const serviceCounterTheme2 = {
  ".counter1-section": {
    padding: "50px 0",
    "background-color": "rgb(29 33 51 / 100)",
  },
  ".counter1-section .countdiv": {
    "padding-left": "70px",
    margin: "10px 0",
  },
  ".counter1-section .countdiv .counternumb": {
    "font-size": "40px",
    "font-weight": "600",
    "line-height": "1.1",
    color: "rgb(255 255 255 / 100)",
  },
  ".counter1-section .countdiv .counter-title": {
    "font-size": "18px",
    "font-weight": "500",
    "line-height": "1.2",
    color: "rgb(255 255 255 / 100)",
  },
};
export const ourTeamTheme2 = {
  ".ourteam-section": {
    padding: "100px 0",
    "background-color": "rgb(249 249 249 / 100)",
  },
  ".ourteam-section .section-title-area": {
    "margin-bottom": "50px",
  },
  ".ourteam-section .section-title-area .section-subtitle": {
    "font-size": "16px",
    "font-weight": "600",
    color: "rgb(255 73 124 / 100)",
    "background-color": "rgba(255, 73, 124, 0.2)",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "border-radius": "15px",
    "margin-bottom": "15px",
  },
  ".ourteam-section .section-title-area .section-title": {
    "font-size": "44px",
    "font-weight": "600",
    color: "rgba(0, 0, 0, 100)",
  },
  ".ourteam-section .member-data": {
    border: "10px solid rgb(255 255 255 / 100)",
    borderPx: "10px",
    borderThik: "solid",
    borderColor: "rgb(255 255 255 / 100)",
    margin: "10px 0",
    "border-radius": "5px",
    "background-color": "rgb(255 255 255 / 100)",
    "box-shadow": "0 5px 10px 0 rgba(7, 28, 31, 0.1)",
    bs1: "0px",
    bs2: "5px",
    bs3: "10px",
    bs4: "0px",
    shadowColor: "rgba(7, 28, 31, 0.1)",
  },
  ".ourteam-section .member-data:hover": {
    "border-color": "rgb(255 73 124 / 100)",
  },
  ".ourteam-section .member-data .member-img": {
    "border-radius": "5px",
    "background-color": "rgb(238 238 238 / 100)",
  },
  ".ourteam-section .member-data .member-info": {
    padding: "30px 15px",
    p1: "30px",
    p2: "15px",
  },
  ".ourteam-section .member-data .name a": {
    color: "rgb(0 0 0 / 100)",
    "font-size": "20px",
    "font-weight": "600",
  },
  ".ourteam-section .member-data .position": {
    color: "rgb(153 153 153 / 100)",
    "font-size": "18px",
    "font-weight": "500",
    margin: "10px 0",
  },
  ".ourteam-section .member-data .socia-list a": {
    padding: "8px",
    margin: "1px",
    width: "40px",
    height: "40px",
    color: "rgb(102 102 102 / 100)",
    "font-size": "20px",
    border: "2px solid rgb(238 238 238 / 100)",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: "rgb(238 238 238 / 100)",
    "border-radius": "20px",
  },
  ".ourteam-section .member-data .socia-list a:hover": {
    color: "rgb(255 255 255 / 100)",
    "background-color": "rgb(255 73 124 / 100)",
    "border-color": "rgb(255 73 124 / 100)",
  },

  ".team-detail-section": {
    padding: "100px 0",
    "background-color": "rgb(255 255 255 / 100)",
    width: "",
    margin: "",
    "border-radius": "",
  },
  ".team-detail-section.bg-image": {
    "background-image": "",
  },
  ".team-detail-section.bg-image::before": {
    "background-color": "",
  },
  ".team-detail-section .single-team-img": {
    "background-color": "rgb(244 244 245 / 100)",
    "margin-bottom": "20px",
  },
  ".team-detail-section .team-member-info .team-title": {
    "font-weight": 600,
    "font-size": "24px",
  },
  ".team-detail-section .team-member-info .designation": {
    color: "rgb(164 164 170 / 100)",
  },
  ".team-detail-section .team-member-info .social-list a": {
    padding: "9px",
    margin: "1px",
    width: "40px",
    height: "40px",
    "text-align": "center",
    color: "rgb(255 255 255 / 100)",
    "font-size": "20px",
    "border-radius": "20px",
    "background-color": "#1f2732",
  },
  ".team-detail-section .team-member-info .social-list a:hover": {
    color: "rgb(255 255 255 / 100)",
    "background-color": "rgb(255 73 124 / 100)",
  },
  ".personal-details .details-title": {
    "font-weight": 600,
    "font-size": "24px",
  },
  ".personal-details p": {
    "margin-bottom": "20px",
    "line-height": "1.6",
    "font-size": "18px",
  },
  ".personal-details .info-list": {
    "margin-bottom": "30px",
  },
  ".personal-details .info-list li": {
    "margin-bottom": "15px",
    "font-size": "18px",
  },
  ".personal-details .order-list": {
    "margin-bottom": "30px",
  },
  ".personal-details .order-list li": {
    "padding-left": "25px",
    "margin-bottom": "15px",
    "font-size": "18px",
  },
  ".personal-details .order-list li:before": {
    "font-family": "FontAwesome",
    top: "3px",
    color: "rgb(255 73 124 / 100)",
  },
  ".ourteam-section .more-btn": {
    color: "rgb(255 255 255 / 100)",
    "font-size": "16px",
    "font-weight": "normal",
    "border-radius": "15px",
    padding: "5px 20px",
    "background-color": "rgb(255 73 124 / 100)",
  },
  ".ourteam-section .more-btn:hover": {
    "background-color": "rgb(223 42 93 / 100)",
    color: "rgb(255 255 255 / 100)",
  },
  ".pagination-positions": {
    "justify-content": "center",
  },
  ".pagination .page-link": {
    color: "rgb(255 73 124 / 100)",
  },
  ".pagination .page-item.active .page-link": {
    "background-color": "rgb(255 73 124 / 100)",
    "border-color": "rgb(255 73 124 / 100)",
  },
};
export const ourTeamTheme1 = {
  ".team-section": {
    padding: "100px 0",
    "background-color": "rgb(255 255 255 / 100)",
    width: "",
    margin: "",
    "border-radius": "",
  },
  ".team-section.bg-image": {
    "background-image": "",
  },
  ".team-section.bg-image::before": {
    "background-color": "",
  },
  ".team-section .section-title-area": {
    "margin-bottom": "50px",
  },
  ".team-section .section-title-area .section-subtitle": {
    "font-size": "16px",
    "font-weight": "600",
    color: "rgb(255 73 124 / 100)",
    "background-color": "rgb(255 73 124 / 0.2)",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "border-radius": "15px",
    "margin-bottom": "15px",
  },
  ".team-section .section-title-area .section-title": {
    "font-size": "44px",
    "font-weight": "600",
    color: "rgb(0 0 0 / 100)",
  },
  ".team-section .member-data": {
    margin: "10px 0",
    "background-color": "rgb(255 255 255 / 100)",
    "box-shadow": "0 5px 10px 0 rgb(7 28 31 / 0.098)",
    bs1: "0",
    bs2: "5px",
    bs3: "10px",
    bs4: "0",
    shadowColor: "rgb(7 28 31 / 0.098)",
    "border-radius": "0px",
  },
  ".team-section .member-data .member-img": {
    "background-color": "rgb(7 28 31 / 0.098)",
  },
  ".team-section .member-data .member-info": {
    padding: "30px 15px",
    p1: "30px",
    p2: "15px",
  },
  ".team-section .member-data .name a": {
    color: "rgb(0 0 0 / 100)",
    "font-size": "20px",
    "font-weight": "500",
  },
  ".team-section .member-data .name a:hover": {
    color: "rgb(255 73 124 / 100)",
  },
  ".team-section .member-data .position": {
    color: "rgb(255 73 124 / 100)",
    "font-size": "18px",
    "font-weight": "600",
    margin: "10px 0",
  },
  ".team-section .member-data .socia-list a": {
    padding: "8px",
    margin: "1px",
    "text-align": "center",
    color: "rgb(102 102 102 / 100)",
    "font-size": "20px",
    border: "2px solid rgb(255 73 124 / 100)",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: "rgb(255 73 124 / 100)",
    "border-radius": "20px",
  },
  ".team-section .member-data .socia-list a:hover": {
    color: "rgb(255 255 255 / 100)",
    "background-color": "rgb(255 73 124 / 100)",
    "border-color": "rgb(255 73 124 / 100)",
  },

  ".team-detail-section": {
    padding: "100px 0",
    "background-color": "rgb(255 255 255 / 100)",
    width: "",
    margin: "",
    "border-radius": "",
  },
  ".team-detail-section.bg-image": {
    "background-image": "",
  },
  ".team-detail-section.bg-image::before": {
    "background-color": "",
  },
  ".team-detail-section .single-team-img": {
    "background-color": "rgb(244 244 245 / 100)",
    "margin-bottom": "20px",
  },
  ".team-detail-section .team-member-info .team-title": {
    "font-weight": 600,
    "font-size": "24px",
  },
  ".team-detail-section .team-member-info .designation": {
    color: "rgb(164 164 170 / 100)",
  },
  ".team-detail-section .team-member-info .social-list a": {
    padding: "9px",
    margin: "1px",
    width: "40px",
    height: "40px",
    "text-align": "center",
    color: "rgb(255 255 255 / 100)",
    "font-size": "20px",
    "border-radius": "20px",
    "background-color": "#1f2732",
  },
  ".team-detail-section .team-member-info .social-list a:hover": {
    color: "rgb(255 255 255 / 100)",
    "background-color": "rgb(255 73 124 / 100)",
  },
  ".personal-details .details-title": {
    "font-weight": 600,
    "font-size": "24px",
  },
  ".personal-details p": {
    "margin-bottom": "20px",
    "line-height": "1.6",
    "font-size": "18px",
  },
  ".personal-details .info-list": {
    "margin-bottom": "30px",
  },
  ".personal-details .info-list li": {
    "margin-bottom": "15px",
    "font-size": "18px",
  },
  ".personal-details .order-list": {
    "margin-bottom": "30px",
  },
  ".personal-details .order-list li": {
    "padding-left": "25px",
    "margin-bottom": "15px",
    "font-size": "18px",
  },
  ".personal-details .order-list li:before": {
    "font-family": "FontAwesome",
    top: "3px",
    color: "rgb(255 73 124 / 100)",
  },
  ".team-section .more-btn": {
    color: "rgb(255 255 255 / 100)",
    "font-size": "16px",
    "font-weight": "normal",
    "border-radius": "15px",
    padding: "5px 20px",
    "background-color": "rgb(255 73 124 / 100)",
  },
  ".team-section .more-btn:hover": {
    "background-color": "rgb(223 42 93 / 100)",
    color: "rgb(255 255 255 / 100)",
  },
  ".pagination-positions": {
    "justify-content": "center",
  },
  ".pagination .page-link": {
    color: "rgb(255 73 124 / 100)",
  },
  ".pagination .page-item.active .page-link": {
    "background-color": "rgb(255 73 124 / 100)",
    "border-color": "rgb(255 73 124 / 100)",
  },
};

export const themeForImages = {
  1: ImageTheme1,
  2: ImageTheme2,
  3: ImageTheme1,
};

export const isSections = {
  isOurTeam: "ourTeam",
  isOurClient: "ourClient",
  isImageBanner: "imageBanner",
  isDetailSection: "detailSection",
  isContactUs: "contactUs",
  isBlog: "blog",
  isServiceCategory: "serviceCategory",
  isSocialMedia: "socialMedia",
  isPackages: "packages",
  isCompanyLogoTaglineService: "companyLogoTaglineService",
  isGallery: "gallery",
  isSeoDetail: "seoDetails",
  isProduct: "product",
  isVideo: "videos",
  isTestimonial: "testimonial",
  isAboutUs: "aboutUs",
  isItemFeature: "itemFeature",
  isSlider: "slider",
  isPayment: "payment",
  isLatestNews: "latestNews",
  isServiceCounter: "serviceCounter",
  isVideoBanner: "videosBanner",
  isCareer: "careers",
  isCareerInquiry: "careerInquiries",
  isDownload: "download",
  isCardTheme: "cardTheme",
  isFaqs: "faqs",
  isDataCounter: "dataCounter",
  isGetLinkOnWhatsApp: "getLinkOnWhatsApp",
  isPageTitle: "pageTile",
  isInquiry: "inquiry",
  isSteps: "steps",
  isPopUpDialog: "popupDialog",
  isProductBuy: "productBuy",
  isFooter: "footer",
  isCustomForm: "customForm",
};

export const POPUP_DIALOG_MAX_LENGTH = 1; // popup dialog max length

export const ThemeNo = {
  ThemeOne: 1,
  ThemeTwo: 2,
  ThemeThree: 3,
  ThemeFour: 4,
  ThemeFive: 5,
  ThemeSix: 6,
  ThemeSeven: 7,
  ThemeEight: 8,
  ThemeNine: 9,
  ThemeTen: 10,
  ThemeEleven: 11,
  ThemeTwelve: 12,
  ThemeThirteen: 13,
  ThemeFourteen: 14,
  ThemeFifteen: 15,
};

export const singlePageSliderLimit = 6;
export const ImageBannerTheme = [
  { theme: 1, themeName: "Theme 1" },
  { theme: 2, themeName: "Theme 2" },
];

export const FILE_EXTENSION = {
  png: "PNG",
  jpg: "JPG",
  jpeg: "JPEG",
  gif: "GIF",
};

export const TestimonialThemeOne = {
  ".testimonial-section": {
    padding: "100px 0",
    "background-color": "rgb(255 255 255 / 100)",
  },
  ".testimonial-section.bg-image": {},
  ".testimonial-section.bg-image::before": {
    "background-color": "",
    // "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
  },
  ".testimonial-section .section-title-area": {
    "margin-bottom": "50px",
  },
  ".testimonial-section .section-title-area .section-subtitle": {
    "font-size": "16px",
    "font-weight": "600",
    color: "rgb(255 73 124 / 100)",
    "background-color": "rgb(255 73 124 / 0.2)",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "border-radius": "15px",
    "margin-bottom": "15px",
  },
  ".testimonial-section .section-title-area .section-title": {
    "font-size": "44px",
    "font-weight": "600",
    color: "rgb(0 0 0 / 100)",
  },
  ".testimonial-slider .slick-arrow::before": {
    color: "rgb(255 73 124 / 100)",
  },
  ".testimonial-slider .testimonial-content": {
    "box-shadow": "0px 0px 20px 0px rgb(0 0 0 / 0.051)",
    bs1: "0px",
    bs2: "0px",
    bs3: "20px",
    bs4: "0px",
    shadowColor: "rgb(0 0 0 / 0.051)",
    "background-color": "rgb(255 255 255 / 100)",
    margin: "15px",
    padding: "80px 50px 40px 50px",
    p1: "80px",
    p2: "50px",
    p3: "40px",
    p4: "50px",
    "border-radius": "10px",
    "text-align": "center",
  },
  ".testimonial-slider .testimonial-content:before": {
    "font-family": "FontAwesome",
    color: "rgb(255 73 124 / 100)",
    "font-size": "40px",
    opacity: "0.6",
    top: "10px",
  },
  ".testimonial-slider .testimonial-content .avatar-img": {
    width: "110px",
    height: "110px",
    "border-radius": "100%",
    border: "5px solid rgb(255 73 124 / 100)",
    borderPx: "5px",
    borderThik: "solid",
    borderColor: "rgb(255 73 124 / 100)",
  },
  ".testimonial-slider .testimonial-content .comments": {
    color: "rgb(129 136 153 / 100)",
    "font-size": "16px",
    "font-weight": "400",
    "line-height": "1.6",
    margin: "20px 0",
  },
  ".testimonial-slider .testimonial-content .client-name": {
    color: "rgb(31 39 50 / 100)",
    "font-size": "22px",
    "font-weight": "600",
    "margin-bottom": "10px",
  },
  ".testimonial-slider .testimonial-content .client-position": {
    color: "rgb(129 136 153 / 100)",
    "font-size": "16px",
    "font-weight": "400",
    "margin-bottom": "12px",
  },
  ".testimonial-slider .testimonial-content .star-rating": {
    "text-align": "center",
  },
  ".testimonial-slider .testimonial-content .star-rating i": {
    color: "rgb(255 73 124 / 100)",
    "font-size": "20px",
  },
  ".testimonial-section .more-btn": {
    color: "rgb(255 255 255 / 100)",
    "font-size": "16px",
    "font-weight": "normal",
    "border-radius": "15px",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "background-color": "rgb(255 73 124 / 100)",
  },
  ".testimonial-section .more-btn:hover": {
    "background-color": "rgb(255 73 124 / 100)",
    color: "rgb(255 255 255 / 100)",
  },
  ".pagination-positions": {
    "justify-content": "center",
  },
  ".pagination .page-link": {
    color: "rgb(255 73 124 / 100)",
  },
  ".pagination .page-item.active .page-link": {
    "background-color": "rgb(255 73 124 / 100)",
    "border-color": "rgb(255 73 124 / 100)",
  },
  ".testimonial-slider .slick-dots li button:before": {
    "font-size": "12px",
    color: "rgb(255 73 124 / 100)",
    opacity: "0.25",
  },
  ".testimonial-slider .slick-dots li.slick-active button:before": {
    color: "rgb(255 73 124 / 100)",
  },
};

export const TestimonialThemeTwo = {
  ".testimonial-section": {
    padding: "100px 0",
    "background-color": "rgb(255 255 255 / 100)",
  },
  ".testimonial-section.bg-image": {
    "background-image": "",
  },
  ".testimonial-section.bg-image::before": {
    "background-color": "",
    // "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
  },
  ".testimonial-section .section-title-area": {
    "margin-bottom": "50px",
  },
  ".testimonial-section .section-title-area .section-subtitle": {
    "font-size": "16px",
    "font-weight": "600",
    color: "rgb(255 73 124 / 100)",
    "background-color": "rgb(255 73 124 / 0.2)",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "border-radius": "15px",
    "margin-bottom": "15px",
  },
  ".testimonial-section .section-title-area .section-title": {
    "font-size": "44px",
    "font-weight": "600",
    color: "rgb(0 0 0 / 100)",
  },
  ".testimonial-slider .slick-arrow::before": {
    color: "rgb(255 73 124 / 100)",
  },
  ".testimonial-slider .testimonial-content": {
    "box-shadow": "0px 0px 20px 0px rgb(0 0 0 / 0.051)",
    bs1: "0px",
    bs2: "0px",
    bs3: "20px",
    bs4: "0px",
    shadowColor: "rgb(0 0 0 / 0.051)",
    "background-color": "rgb(255 255 255 / 100)",
    margin: "15px",
    padding: "40px 50px 40px 50px",
    p1: "40px",
    p2: "50px",
    p3: "40px",
    p4: "50px",
    "border-radius": "10px",
    "text-align": "center",
  },
  ".testimonial-slider .testimonial-content:before": {
    "font-family": "FontAwesome",
    color: "rgb(255 73 124 / 100)",
    "font-size": "40px",
    opacity: "0.6",
    top: "10px",
  },
  ".testimonial-slider .testimonial-content .avatar-img": {
    "min-width": "200px",
    "min-height": "200px",
    "border-radius": "10%",
    border: "2px solid rgb(255 73 124 / 100)",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: "rgb(255 73 124 / 100)",
  },
  ".testimonial-slider .testimonial-content .comments": {
    color: "rgb(129 136 153 / 100)",
    "font-size": "16px",
    "font-weight": "400",
    "line-height": "1.6",
    margin: "20px 0",
  },
  ".testimonial-slider .testimonial-content .client-name": {
    color: "rgb(31 39 50 / 100)",
    "font-size": "22px",
    "font-weight": "600",
    "margin-bottom": "10px",
  },
  ".testimonial-slider .testimonial-content .client-position": {
    color: "rgb(129 136 153 / 100)",
    "font-size": "16px",
    "font-weight": "400",
    "margin-bottom": "12px",
  },
  ".testimonial-slider .testimonial-content .star-rating": {
    "text-align": "center",
  },
  ".testimonial-slider .testimonial-content .star-rating i": {
    color: "rgb(255 73 124 / 100)",
    "font-size": "20px",
  },
  ".testimonial-section .more-btn": {
    color: "rgb(255 255 255 / 100)",
    "font-size": "16px",
    "font-weight": "normal",
    "border-radius": "15px",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "background-color": "rgb(255 73 124 / 100)",
  },
  ".testimonial-section .more-btn:hover": {
    "background-color": "rgb(255 73 124 / 100)",
    color: "rgb(255 255 255 / 100)",
  },
  ".pagination-positions": {
    "justify-content": "center",
  },
  ".pagination .page-link": {
    color: "rgb(255 73 124 / 100)",
  },
  ".pagination .page-item.active .page-link": {
    "background-color": "rgb(255 73 124 / 100)",
    "border-color": "rgb(255 73 124 / 100)",
  },
  ".testimonial-slider .slick-dots li button:before": {
    "font-size": "12px",
    color: "rgb(255 73 124 / 100)",
    opacity: "0.25",
  },
  ".testimonial-slider .slick-dots li.slick-active button:before": {
    color: "rgb(255 73 124 / 100)",
  },
  testimonialId: "",
};
export const TestimonialThemeThree = {
  ".zig-zag-section": {
    padding: "50px 0",
    "background-color": "rgb(254 254 253 / 100)",
  },
  ".zig-zag-section .testimonial-divs .testimonial": {
    padding: "30px",
    "border-radius": "5px",
    border: "1px solid rgb(238 238 238 / 100)",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: "rgb(238 238 238 / 100)",
    "box-shadow": "0 30px 90px rgb(187 187 187 / 0.33)",
    bs1: "0px",
    bs2: "30px",
    bs3: "90px",
    shadowColor: "rgb(187 187 187 / 0.33)",
    "background-color": "rgb(255 255 255 / 100)",
  },
  ".zig-zag-section .testimonial-divs .avatar-img": {
    "border-radius": "100%",
    border: "2px solid rgb(255 73 124 / 100)",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: "rgb(255 73 124 / 100)",
  },
  ".zig-zag-section .testimonial-divs .client-name": {
    color: "rgb(31 39 50 / 100)",
    "font-size": "18px",
    "font-weight": "600",
    "line-height": "1.2",
  },
  ".zig-zag-section .testimonial-divs .client-position": {
    color: "rgb(129 136 153 / 100)",
    "font-size": "16px",
    "font-weight": "400",
  },
  ".zig-zag-section .testimonial-divs .desq": {
    color: "rgb(129 136 153 / 100)",
    "font-size": "16px",
    "font-weight": "400",
    "line-height": "1.6",
    margin: "10px 0",
  },
  ".zig-zag-section .star-rating i": {
    color: "rgb(255 73 124 / 100)",
    "font-size": "20px",
  },
  ".zig-zag-section .more-btn": {
    color: "rgb(255 255 255 / 100)",
    "font-size": "16px",
    "font-weight": "normal",
    "border-radius": "15px",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "background-color": "rgb(255 73 124 / 100)",
  },
  ".zig-zag-section .more-btn:hover": {
    "background-color": "rgb(255 73 124 / 100)",
    color: "rgb(255 255 255 / 100)",
  },
  ".pagination-positions": {
    "justify-content": "center",
  },
  ".pagination .page-link": {
    color: "rgb(255 73 124 / 100)",
  },
  ".pagination .page-item.active .page-link": {
    "background-color": "rgb(255 73 124 / 100)",
    "border-color": "rgb(255 73 124 / 100)",
  },
};
export const TestimonialThemeThreeSet = {
  ".zig-zag-section": {
    padding: "50px 0",
    "background-color": { r: 254, g: 254, b: 253, a: 100 },
  },
  ".zig-zag-section.bg-image": {},
  ".zig-zag-section.bg-image::before": {
    "background-color": "",
  },
  ".zig-zag-section .testimonial-divs .testimonial": {
    padding: "30px",
    "border-radius": "5px",
    border: "1px solid rgb(238 238 238 / 100)",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: { r: 238, g: 238, b: 238, a: 100 },
    "box-shadow": "0 30px 90px rgb(187 187 187 / 0.33)",
    bs1: "0px",
    bs2: "30px",
    bs3: "90px",
    shadowColor: { r: 187, g: 187, b: 187, a: 0.33 },
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
  },
  ".zig-zag-section .testimonial-divs .avatar-img": {
    "border-radius": "100%",
    border: "2px solid rgb(255 73 124 / 100)",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: { r: 255, g: 73, b: 124, a: 100 },
  },
  ".zig-zag-section .testimonial-divs .client-name": {
    color: { r: 31, g: 39, b: 50, a: 100 },
    "font-size": "18px",
    "font-weight": "600",
    "line-height": "1.2",
  },
  ".zig-zag-section .testimonial-divs .client-position": {
    color: { r: 129, g: 136, b: 153, a: 100 },
    "font-size": "16px",
    "font-weight": "400",
  },
  ".zig-zag-section .testimonial-divs .desq": {
    color: { r: 129, g: 136, b: 153, a: 100 },
    "font-size": "16px",
    "font-weight": "400",
    "line-height": "1.6",
    margin: "10px 0",
  },
  ".zig-zag-section .star-rating i": {
    color: { r: 255, g: 73, b: 124, a: 100 },
    "font-size": "20px",
  },
  ".zig-zag-section .more-btn": {
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "16px",
    "font-weight": "normal",
    "border-radius": "15px",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  ".zig-zag-section .more-btn:hover": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 255, b: 255, a: 100 },
  },
  ".pagination-positions": {
    "justify-content": "center",
  },
  ".pagination .page-link": {
    color: { r: 255, g: 73, b: 124, a: 100 },
  },
  ".pagination .page-item.active .page-link": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    "border-color": { r: 255, g: 73, b: 124, a: 100 },
  },
};

export const TestimonialThemeResetOne = {
  ".testimonial-section": {
    padding: "100px 0",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
  },
  ".testimonial-section.bg-image": {},
  ".testimonial-section.bg-image::before": {
    "background-color": "",
    // "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
  },
  ".testimonial-section .section-title-area": {
    "margin-bottom": "50px",
  },
  ".testimonial-section .section-title-area .section-subtitle": {
    "font-size": "16px",
    "font-weight": "600",
    color: { r: 255, g: 73, b: 124, a: 100 },
    "background-color": { r: 255, g: 73, b: 124, a: 0.2 },
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "border-radius": "15px",
    "margin-bottom": "15px",
  },
  ".testimonial-section .section-title-area .section-title": {
    "font-size": "44px",
    "font-weight": "600",
    color: "rgb(0 0 0 / 100)",
  },
  ".testimonial-slider .slick-arrow::before": {
    color: { r: 255, g: 73, b: 124, a: 100 },
  },
  ".testimonial-slider .testimonial-content": {
    "box-shadow": "0px 0px 20px 0px { r: 0, g: 0, b: 0, a: 0.051 }",
    bs1: "0px",
    bs2: "0px",
    bs3: "20px",
    bs4: "0px",
    shadowColor: { r: 0, g: 0, b: 0, a: 0.051 },
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
    margin: "15px",
    padding: "80px 50px 40px 50px",
    p1: "80px",
    p2: "50px",
    p3: "40px",
    p4: "50px",
    "border-radius": "10px",
    "text-align": "center",
  },
  ".testimonial-slider .testimonial-content:before": {
    "font-family": "FontAwesome",
    color: { r: 255, g: 73, b: 124, a: 100 },
    "font-size": "40px",
    opacity: "0.6",
    top: "10px",
  },
  ".testimonial-slider .testimonial-content .avatar-img": {
    width: "110px",
    height: "110px",
    "border-radius": "100%",
    border: "5px solid { r: 255, g: 73, b: 124, a: 100 }",
    borderPx: "5px",
    borderThik: "solid",
    borderColor: { r: 255, g: 73, b: 124, a: 100 },
  },
  ".testimonial-slider .testimonial-content .comments": {
    color: { r: 129, g: 136, b: 153, a: 100 },
    "font-size": "16px",
    "font-weight": "400",
    "line-height": "1.6",
    margin: "20px 0",
  },
  ".testimonial-slider .testimonial-content .client-name": {
    color: { r: 31, g: 39, b: 50, a: 100 },
    "font-size": "22px",
    "font-weight": "600",
    "margin-bottom": "10px",
  },
  ".testimonial-slider .testimonial-content .client-position": {
    color: { r: 129, g: 136, b: 153, a: 100 },
    "font-size": "16px",
    "font-weight": "400",
    "margin-bottom": "12px",
  },
  ".testimonial-slider .testimonial-content .star-rating": {
    "text-align": "center",
  },
  ".testimonial-slider .testimonial-content .star-rating i": {
    color: { r: 255, g: 73, b: 124, a: 100 },
    "font-size": "20px",
  },
  ".testimonial-section .more-btn": {
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "16px",
    "font-weight": "normal",
    "border-radius": "15px",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  ".testimonial-section .more-btn:hover": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 255, b: 255, a: 100 },
  },
  ".pagination-positions": {
    "justify-content": "center",
  },
  ".pagination .page-link": {
    color: { r: 255, g: 73, b: 124, a: 100 },
  },
  ".pagination .page-item.active .page-link": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    "border-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  ".testimonial-slider .slick-dots li button:before": {
    "font-size": "12px",
    color: { r: 255, g: 73, b: 124, a: 100 },
    opacity: "0.25",
  },
  ".testimonial-slider .slick-dots li.slick-active button:before": {
    color: { r: 255, g: 73, b: 124, a: 100 },
  },
  custom: "",
};

export const TestimonialThemeResetTwo = {
  ".testimonial-section": {
    padding: "100px 0",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
  },
  ".testimonial-section.bg-image": {
    "background-image": "",
  },
  ".testimonial-section.bg-image::before": {
    "background-color": "",
    // "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
  },
  ".testimonial-section .section-title-area": {
    "margin-bottom": "50px",
  },
  ".testimonial-section .section-title-area .section-subtitle": {
    "font-size": "16px",
    "font-weight": "600",
    color: { r: 255, g: 73, b: 124, a: 100 },
    "background-color": { r: 255, g: 73, b: 124, a: 0.2 },
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "border-radius": "15px",
    "margin-bottom": "15px",
  },
  ".testimonial-section .section-title-area .section-title": {
    "font-size": "44px",
    "font-weight": "600",
    color: { r: 0, g: 0, b: 0, a: 100 },
  },
  ".testimonial-slider .slick-arrow::before": {
    color: { r: 255, g: 73, b: 124, a: 100 },
  },
  ".testimonial-slider .testimonial-content": {
    "box-shadow": "0px 0px 20px 0px { r: 0, g: 0, b: 0, a: 0.051 }",
    bs1: "0px",
    bs2: "0px",
    bs3: "20px",
    bs4: "0px",
    shadowColor: { r: 0, g: 0, b: 0, a: 0.051 },
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
    margin: "15px",
    padding: "40px 50px 40px 50px",
    p1: "40px",
    p2: "50px",
    p3: "40px",
    p4: "50px",
    "border-radius": "10px",
    "text-align": "center",
  },
  ".testimonial-slider .testimonial-content:before": {
    "font-family": "FontAwesome",
    color: { r: 255, g: 73, b: 124, a: 100 },
    "font-size": "40px",
    opacity: "0.6",
    top: "10px",
  },
  ".testimonial-slider .testimonial-content .avatar-img": {
    "min-width": "200px",
    "min-height": "200px",
    "border-radius": "10%",
    border: "2px solid { r: 255, g: 73, b: 124, a: 100 }",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: { r: 255, g: 73, b: 124, a: 100 },
  },
  ".testimonial-slider .testimonial-content .comments": {
    color: { r: 129, g: 136, b: 153, a: 100 },
    "font-size": "16px",
    "font-weight": "400",
    "line-height": "1.6",
    margin: "20px 0",
  },
  ".testimonial-slider .testimonial-content .client-name": {
    color: "rgb(31 39 50 / 100)",
    "font-size": "22px",
    "font-weight": "600",
    "margin-bottom": "10px",
  },
  ".testimonial-slider .testimonial-content .client-position": {
    color: { r: 129, g: 136, b: 153, a: 100 },
    "font-size": "16px",
    "font-weight": "400",
    "margin-bottom": "12px",
  },
  ".testimonial-slider .testimonial-content .star-rating": {
    "text-align": "center",
  },
  ".testimonial-slider .testimonial-content .star-rating i": {
    color: { r: 255, g: 73, b: 124, a: 100 },
    "font-size": "20px",
  },
  ".testimonial-section .more-btn": {
    color: "rgb(255 255 255 / 100)",
    "font-size": "16px",
    "font-weight": "normal",
    "border-radius": "15px",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  ".testimonial-section .more-btn:hover": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 73, b: 124, a: 100 },
  },
  ".pagination-positions": {
    "justify-content": "center",
  },
  ".pagination .page-link": {
    color: { r: 255, g: 73, b: 124, a: 100 },
  },
  ".pagination .page-item.active .page-link": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    "border-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  ".testimonial-slider .slick-dots li button:before": {
    "font-size": "12px",
    color: { r: 255, g: 73, b: 124, a: 100 },
    opacity: "0.25",
  },
  ".testimonial-slider .slick-dots li.slick-active button:before": {
    color: { r: 255, g: 73, b: 124, a: 100 },
  },
  testimonialId: "",
  custom: "",
};

export const FaqsTheme = {
  ".faqs-section": {
    padding: "100px 0",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
  },
  ".faqs-section.bg-image": {
    "background-image": "",
  },
  ".faqs-section.bg-image::before": {
    "background-color": "",
    // "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
  },
  ".faqs-section .section-title-area": {
    "margin-bottom": "50px",
  },
  ".faqs-section .section-title-area .section-subtitle": {
    "font-size": "16px",
    "font-weight": "600",
    color: { r: 255, g: 73, b: 124, a: 100 },
    "background-color": { r: 255, g: 73, b: 124, a: 0.2 },
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "border-radius": "15px",
    "margin-bottom": "15px",
  },
  ".faqs-section .section-title-area .section-title": {
    "font-size": "44px",
    "font-weight": "600",
    color: { r: 0, g: 0, b: 0, a: 100 },
  },
  ".faq-wrapper": {
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
    padding: "35px",
    "border-top": "3px solid  { r: 255, g: 73, b: 124, a: 100 }",
    borderPx: "3px",
    borderThik: "solid",
    borderColor: { r: 255, g: 73, b: 124, a: 100 },
    "box-shadow": "0px 30px 70px 0px { r: 0, g: 0, b: 0, a: 0.094 }",
    bs1: "0px",
    bs2: "30px",
    bs3: "70px",
    bs4: "0px",
    shadowColor: { r: 0, g: 0, b: 0, a: 0.094 },
    "border-radius": "20px",
  },
  ".faq-wrapper .accordion-item": {
    "padding-top": "20px",
    "padding-bottom": "20px",
    border: "0px solid { r: 255, g: 255, b: 255, a: 100 }",
    borderPx: "0px",
    borderThik: "solid",
    borderColor: { r: 255, g: 255, b: 255, a: 100 },
    "border-bottom": "1px solid  { r: 255, g: 73, b: 124, a: 100 }",
    borderBtPx: "1px",
    borderBtThik: "solid",
    borderBtColor: { r: 255, g: 73, b: 124, a: 100 },
  },
  ".faq-wrapper .accordion-item:last-child": {
    border: "0px solid { r: 255, g: 255, b: 255, a: 100 }",
    borderPx: "0px",
    borderThik: "solid",
    borderColor: { r: 255, g: 255, b: 255, a: 100 },
  },
  ".faq-wrapper .accordion-button": {
    outline: "0px solid { r: 255, g: 255, b: 255, a: 100 }",
    outPx: "0px",
    outThik: "solid",
    outColor: { r: 255, g: 255, b: 255, a: 100 },
    "box-shadow": "0px 0px 0px 0px { r: 0, g: 0, b: 0, a: 0.094 }",
    bs1: "0px",
    bs2: "0px",
    bs3: "0px",
    bs4: "0px",
    shadowColor: { r: 0, g: 0, b: 0, a: 0.094 },
    "font-weight": "500",
  },
  ".faq-wrapper .accordion-button:not(.collapsed)": {
    color: { r: 255, g: 255, b: 255, a: 100 },
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  ".faq-wrapper .accordion-body": {
    "line-height": "1.4",
  },
  ".faqs-section .more-btn": {
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "16px",
    "font-weight": "normal",
    "border-radius": "15px",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  ".faqs-section .more-btn:hover": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 255, b: 255, a: 100 },
  },
  ".pagination-positions": {
    "justify-content": "center",
  },
  ".pagination .page-link": {
    color: { r: 255, g: 73, b: 124, a: 100 },
  },
  ".pagination .page-item.active .page-link": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    "border-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  custom: "",
  faqsId: "",
};

export const PackagesTheme = {
  ".packages-section": {
    padding: "100px 0",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
  },
  ".packages-section.bg-image": {},
  ".packages-section.bg-image::before": {
    // "background-color": "#000000CC",
  },
  ".packages-section .section-title-area": {
    "margin-bottom": "50px",
  },
  ".packages-section .section-title-area .section-subtitle": {
    "font-size": "16px",
    "font-weight": "600",
    color: { r: 255, g: 73, b: 124, a: 100 },
    "background-color": { r: 255, g: 73, b: 124, a: 0.2 },
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "border-radius": "15px",
    "margin-bottom": "15px",
  },
  ".packages-section .section-title-area .section-title": {
    "font-size": "44px",
    "font-weight": "600",
    color: { r: 0, g: 0, b: 0, a: 100 },
  },
  ".planswitch": {
    "align-items": "center",
    "justify-content": "center",
    "margin-bottom": "20px",
  },
  ".planswitch .switch-text": {
    color: { r: 0, g: 0, b: 0, a: 100 },
    "font-size": "16px",
    "font-weight": "600",
  },
  ".switch .slider": {
    "border-radius": "34px",
    "background-color": { r: 204, g: 204, b: 204, a: 100 },
  },
  ".switch .slider:before": {
    bottom: "4px",
    "border-radius": "50%",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
  },
  ".switch input:checked + .slider": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  ".packagelist-1 .package": {
    border: "1px solid { r: 238, g: 238, b: 238, a: 100 }",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: { r: 238, g: 238, b: 238, a: 100 },
    "border-radius": "5px",
  },
  ".packages-section .packagelist-1 .package .title": {
    color: { r: 255, g: 255, b: 255, a: 100 },
    padding: "30px 10px 20px",
    p1: "30px",
    p2: "10px",
    p3: "20px",
    "text-align": "center",
    "text-transform": "uppercase",
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  ".packagelist-1 .package .title:before": {
    "background-color": { r: 238, g: 238, b: 238, a: 100 },
  },
  ".packagelist-1 .package .title h3": {
    "font-weight": "700",
    "font-size": "20px",
  },
  ".packagelist-1 .package .title p": {
    "font-weight": "400",
  },
  ".packagelist-1 .package .cost": {
    "background-color": { r: 238, g: 238, b: 238, a: 100 },
    width: "100%",
    "text-align": "center",
    padding: "15px 10px 25px",
    p1: "15px",
    p2: "10px",
    p3: "25px",
    "font-size": "18px",
    "font-weight": "700",
  },
  ".packagelist-1 .package .cost:before": {
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
  },
  ".packagelist-1 .package .cost .price": {
    "font-size": "35px",
  },
  ".packagelist-1 .package .desq": {
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
    padding: "10px 10px 20px",
    p1: "10px",
    p2: "10px",
    p3: "20px",
  },
  ".packagelist-1 .package .desq li": {
    "font-size": "14px",
    "font-weight": "400",
    color: { r: 102, g: 102, b: 102, a: 100 },
    margin: "15px 0",
    padding: "0 5px 0 20px",
    p1: "5px",
    p2: "20px",
  },
  ".packagelist-1 .package .desq li:before": {
    color: { r: 255, g: 73, b: 124, a: 100 },
  },
  ".packages-section .packagelist-1 .package .selectbtn": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    padding: "10px 10px 15px",
    p1: "10px",
    p2: "10px",
    p3: "15px",
    "font-size": "16px",
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-weight": "400",
    "text-transform": "uppercase",
    "text-align": "center",
  },
  ".packagelist-1 .package .selectbtn:before": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  ".packagelist-2 .package": {
    border: "1px solid { r: 238, g: 238, b: 238, a: 100 }",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: { r: 238, g: 238, b: 238, a: 100 },
    "border-radius": "5px",
  },
  ".packages-section .packagelist-2 .package .title": {
    padding: "15px",
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "20px",
    "font-weight": "500",
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    "text-align": "center",
    "text-transform": "uppercase",
  },
  ".packagelist-2 .package .cost": {
    padding: "20px 10px",
    p1: "20px",
    p2: "10px",
    "text-align": "center",
    "background-color": { r: 31, g: 39, b: 50, a: 100 },
  },
  ".packagelist-2 .package .cost span": {
    border: "2px solid { r: 255, g: 255, b: 255, a: 100 }",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: { r: 255, g: 255, b: 255, a: 100 },
    "border-radius": "50%",
    "line-height": "70px",
    "text-align": "center",
    display: "inline-block",
    color: { r: 255, g: 255, b: 255, a: 100 },
    "box-shadow": "0 2px 2px 1px { r: 0, g: 0, b: 0, a: 0.188 }",
    bs1: "2px",
    bs2: "2px",
    bs3: "1px",
    shadowColor: { r: 0, g: 0, b: 0, a: 0.188 },
  },
  ".packagelist-2 .package .subtitle": {
    padding: "15px 10px",
    p1: "15px",
    p2: "10px",
    color: { r: 255, g: 255, b: 255, a: 100 },
    "background-color": { r: 117, g: 127, b: 137, a: 100 },
    "text-align": "center",
    "font-weight": "400",
    "font-size": "14px",
  },
  ".packagelist-2 .package .desq": {
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
    padding: "13px",
  },
  ".packagelist-2 .package .desq li": {
    margin: "5px 0",
    "padding-left": "22px",
    "font-size": "12px",
    color: { r: 102, g: 102, b: 102, a: 100 },
  },
  ".packagelist-2 .package .desq li:before": {
    color: { r: 255, g: 192, b: 203, a: 100 },
  },
  ".packages-section .packagelist-2 .package .selectbtn": {
    "text-align": "center",
    padding: "10px",
    display: "inline-block",
    width: "100%",
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  ".packagelist-2 .package .selectbtn a": {
    border: "2px solid { r: 255, g: 255, b: 255, a: 100 }",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: { r: 255, g: 255, b: 255, a: 100 },
    padding: "8px 25px",
    p1: "8px",
    p2: "25px",
    "font-size": "12px",
    "text-transform": "uppercase",
    "letter-spacing": "1px",
    color: { r: 255, g: 255, b: 255, a: 100 },
    "border-radius": "3px",
  },
  ".packagelist-2 .package .selectbtn a:hover": {
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
    color: { r: 102, g: 102, b: 102, a: 100 },
  },
  ".packages-section .more-btn": {
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "16px",
    "font-weight": "normal",
    "border-radius": "15px",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  ".packages-section .more-btn:hover": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 255, b: 255, a: 100 },
  },
  ".pagination-positions": {
    "justify-content": "center",
  },
  ".pagination .page-link": {
    color: { r: 255, g: 73, b: 124, a: 100 },
  },
  ".pagination .page-item.active .page-link": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    "border-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  custom: "",
  packageId: "",
};

export const ServiceCounterTheme1 = {
  ".counter-section": {
    padding: "100px 0",
    "background-color": { r: 0, g: 0, b: 0, a: 100 },
  },
  ".counter-section.bg-image": {},
  ".counter-section.bg-image::before": {
    // "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
  },
  ".counter-section .section-title-area": {
    "margin-bottom": "50px",
  },
  ".counter-section .section-title-area .section-subtitle": {
    "font-size": "16px",
    "font-weight": "600",
    color: { r: 255, g: 73, b: 124, a: 100 },
    "background-color": { r: 255, g: 73, b: 124, a: 0.2 },
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "border-radius": "15px",
    "margin-bottom": "15px",
  },
  ".counter-section .section-title-area .section-title": {
    "font-size": "44px",
    "font-weight": "600",
    color: { r: 255, g: 255, b: 255, a: 100 },
  },
  ".counter-section .countdiv": {
    "text-align": "center",
    "margin-bottom": "30px",
  },
  ".counter-section .countdiv .counter": {
    color: { r: 255, g: 73, b: 124, a: 100 },
    "font-size": "40px",
    "font-weight": "600",
  },
  ".counter-section .countdiv .divider": {
    width: "80px",
    height: "4px",
    margin: "15px auto",
    "border-radius": "3px",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
  },
  ".counter-section .countdiv .counter-title": {
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "22px",
    "font-weight": "500",
  },
  serviceCounterId: "",
  custom: "",
};
export const ServiceCounterTheme2 = {
  ".counter1-section": {
    padding: "50px 0",
    "background-color": { r: 29, g: 33, b: 51, a: 100 },
  },
  ".counter1-section.bg-image": {},
  ".counter1-section.bg-image::before": {
    // "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
  },
  ".counter1-section .countdiv": {
    "padding-left": "70px",
    margin: "10px 0",
  },
  ".counter1-section .countdiv .counternumb": {
    "font-size": "40px",
    "font-weight": "600",
    "line-height": "1.1",
    color: { r: 255, g: 255, b: 255, a: 100 },
  },
  ".counter1-section .countdiv .counter-title": {
    "font-size": "18px",
    "font-weight": "500",
    "line-height": "1.2",
    color: { r: 255, g: 255, b: 255, a: 100 },
  },
  serviceCounterId: "",
  custom: "",
};

export const VALIDATION_MESSAGES = "Please fill all fields.";

export const THEME_UPDATE_MESSAGE = "Theme Update Successfully!";
