import React, { useContext, useState } from "react";
import { Button, Col, Container, Input, Label, Row } from "reactstrap";
import {
  Appearance,
  Border,
  Display,
  TextAlign,
  fontWeight,
} from "../../../../constant";
import GetWhatsAppLinkContext from "../../../context/GetWhatsAppLinkContext/GetWhatsAppLinkContext";
import { Switch } from "@material-ui/core";
import { SketchPicker } from "react-color";
import CommonColor from "../../../common/CommonColor";
import CustomStyle from "../../../common/CustomStyle";

const GetWhatsAppLinkThemeOne = (props) => {
  const {
    isEdit,
    settingFormData,
    setSettingFormData,
    boxSection,
    setBoxSection,
    isBackgroundColor,
    setIsBackgroundColor,
  } = useContext(GetWhatsAppLinkContext);
  const { simpleValidator, handleSubmitSetting } = props;

  /**
   * input value change
   * @param {event} e
   */

  const handleChangeGetLinkSection = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".getlink-section"]: {
          ...prev[".getlink-section"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeGetLinkSectionTitleArea = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".getlink-section .section-title-area"]: {
          ...prev[".getlink-section .section-title-area"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeGetLinkSectionTitleAreaSubtitle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".getlink-section .section-title-area .section-subtitle"]: {
          ...prev[".getlink-section .section-title-area .section-subtitle"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeGetLinkSectionTitleAreaTitle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".getlink-section .section-title-area .section-title"]: {
          ...prev[".getlink-section .section-title-area .section-title"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeGetLinkSectionNote = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".getlinksbox .section-note"]: {
          ...prev[".getlinksbox .section-note"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeGetLinkSBox = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".getlinksbox"]: {
          ...prev[".getlinksbox"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeGetLinkSBoxFormControl = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".getlinksbox .form-control"]: {
          ...prev[".getlinksbox .form-control"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeCustomStyle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  const handleChangeGetLinkSBoxBtn = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".getlink-section .getlinksbox .btn"]: {
          ...prev[".getlink-section .getlinksbox .btn"],
          [name]: value,
        },
      };
    });
  };

  const resetHandler = () => {
    setSettingFormData({
      ".getlink-section": {
        padding: "50px 0",
        "background-color": { r: 238, g: 238, b: 238, a: 100 },
      },
      ".getlink-section .section-title-area": {
        "margin-bottom": "50px",
      },
      ".getlink-section .section-title-area .section-subtitle": {
        "font-size": "16px",
        "font-weight": "600",
        color: { r: 255, g: 73, b: 124, a: 100 },
        "background-color": { r: 255, g: 73, b: 124, a: 0.2 },
        padding: "5px 20px",
        p1: "5px",
        p2: "20px",
        "border-radius": "15px",
        display: "inline-block",
        "margin-bottom": "15px",
      },
      ".getlink-section .section-title-area .section-title": {
        "font-size": "44px",
        "font-weight": "600",
        color: { r: 0, g: 0, b: 0, a: 100 },
      },
      ".getlinksbox .section-note": {
        "font-size": "18px",
        "font-weight": "500",
        color: { r: 153, g: 153, b: 153, a: 100 },
      },
      ".getlinksbox": {
        "background-color": { r: 255, g: 255, b: 255, a: 100 },
        padding: "40px 40px",
        p1: "40px",
        p2: "40px",
        "border-radius": "15px",
        border: "1px solid { r: 255, g: 255, b: 255, a: 100 }",
        borderPx: "1px",
        borderThik: "solid",
        borderColor: { r: 255, g: 255, b: 255, a: 100 },
        "border-top": "3px solid { r: 255, g: 73, b: 124, a: 100 }",
        borderTopPx: "3px",
        borderTopThik: "solid",
        borderTopColor: { r: 255, g: 73, b: 124, a: 100 },
        "box-shadow": "5px 5px 15px { r: 0, g: 0, b: 0, a: 0.09 }",
        bs1: "5px",
        bs2: "5px",
        bs3: "15px",
        shadowColor: { r: 0, g: 0, b: 0, a: 0.09 },
      },
      ".getlinksbox .form-control": {
        width: "100%",
        "margin-bottom": "10px",
        "background-color": { r: 242, g: 247, b: 255, a: 100 },
        border: "2px solid { r: 238, g: 238, b: 238, a: 100 }",
        borderPx: "2px",
        borderThik: "solid",
        borderColor: { r: 238, g: 238, b: 238, a: 100 },
        "border-radius": "4px",
        "font-size": "16px",
        appearance: "auto",
        outline: "0px solid { r: 255, g: 255, b: 255, a: 100 }",
        outPx: "0px",
        outThik: "solid",
        outcolor: { r: 255, g: 255, b: 255, a: 100 },
      },
      ".getlink-section .getlinksbox .btn": {
        color: { r: 255, g: 255, b: 255, a: 100 },
        "background-color": { r: 255, g: 73, b: 124, a: 100 },
      },
      custom: "",
    });
    setBoxSection(false);
    setIsBackgroundColor(true);
  };

  return (
    <>
      <Row className="mt-4">
        <h4 className="mb-3">Make change in Main section</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Padding (default : 50)"}</Label>
          <Input
            className="form-control"
            name="padding"
            type="number"
            placeholder="padding"
            value={settingFormData?.[".getlink-section"]?.["padding"]
              ?.split(" ")[0]
              ?.replace(/px/g, "")}
            onChange={(e) => handleChangeGetLinkSection(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>

        <Col md="4 mb-3">
          <div className="d-flex align-items-center">
            <Label htmlFor="validationCustom03">
              {isBackgroundColor
                ? "Add Background Color"
                : "Remove Background Color"}
            </Label>
            <Switch
              checked={isBackgroundColor}
              inputProps={{ "aria-label": "controlled" }}
              onChange={(e) => {
                setIsBackgroundColor(e.target.checked);
                setSettingFormData((prev) => {
                  if (e.target.checked) {
                    if (boxSection) {
                      return {
                        ...prev,
                        [".getlink-section"]: {
                          "background-color": "#eeeeee",
                          padding:
                            settingFormData?.[".getlink-section"]?.["padding"],
                          width: settingFormData?.[".getlink-section"]?.[
                            "width"
                          ]?.replace("rem)", ""),
                          margin: settingFormData?.[".getlink-section"]?.[
                            "margin"
                          ]
                            ?.split(" ")[0]
                            ?.replace(/px/g, ""),
                          "border-radius": settingFormData?.[
                            ".getlink-section"
                          ]?.["border-radius"]?.replace(/rem/g, ""),
                        },
                      };
                    } else {
                      return {
                        ...prev,
                        [".getlink-section"]: {
                          "background-color": "#eeeeee",
                          padding:
                            settingFormData?.[".getlink-section"]?.["padding"],
                        },
                      };
                    }
                  } else {
                    if (boxSection) {
                      return {
                        ...prev,
                        [".getlink-section"]: {
                          padding:
                            settingFormData?.[".getlink-section"]?.["padding"],
                          width: settingFormData?.[".getlink-section"]?.[
                            "width"
                          ]?.replace("rem)", ""),
                          margin: settingFormData?.[".getlink-section"]?.[
                            "margin"
                          ]
                            ?.split(" ")[0]
                            ?.replace(/px/g, ""),
                          "border-radius": settingFormData?.[
                            ".getlink-section"
                          ]?.["border-radius"]?.replace(/rem/g, ""),
                        },
                      };
                    } else {
                      return {
                        ...prev,
                        [".getlink-section"]: {
                          padding:
                            settingFormData?.[".getlink-section"]?.["padding"],
                        },
                      };
                    }
                  }
                });
              }}
            />
          </div>
          {isBackgroundColor && (
            <CommonColor
              color={
                settingFormData?.[".getlink-section"]?.["background-color"]
              }
              setSettingFormData={setSettingFormData}
              className={".getlink-section"}
              classProperty={"background-color"}
              label={"Select Background Color"}
            />
          )}
        </Col>
        <p>{"Box Section : convert your section to Box section"}</p>
        <div>
          <Switch
            checked={boxSection}
            inputProps={{ "aria-label": "controlled" }}
            onChange={(e) => {
              setBoxSection(e.target.checked);
              setSettingFormData((prev) => {
                return {
                  ...prev,
                  ".getlink-section": {
                    padding: settingFormData?.[".getlink-section"]?.["padding"]
                      ?.split(" ")[0]
                      ?.replace(/px/g, ""),
                    "background-color":
                      settingFormData?.[".getlink-section"]?.[
                        "background-color"
                      ],
                    width: "10",
                    margin: "0",
                    "border-radius": "3",
                  },
                };
              });
            }}
          />
        </div>
        {boxSection && (
          <>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Width (Default : 10)"}
              </Label>
              <Input
                className="form-control"
                name="width"
                type="number"
                placeholder="width"
                value={settingFormData?.[".getlink-section"]?.[
                  "width"
                ]?.replace("rem)", "")}
                onChange={(e) => handleChangeGetLinkSection(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Margin (Default : 0)"}
              </Label>
              <Input
                className="form-control"
                name="margin"
                type="number"
                placeholder="margin"
                value={settingFormData?.[".getlink-section"]?.["margin"]
                  ?.split(" ")[0]
                  ?.replace(/px/g, "")}
                onChange={(e) => handleChangeGetLinkSection(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Border Radius (Default : 3)"}
              </Label>
              <Input
                className="form-control"
                name="border-radius"
                type="number"
                placeholder="Border Radius"
                value={settingFormData?.[".getlink-section"]?.[
                  "border-radius"
                ]?.replace(/rem/g, "")}
                onChange={(e) => handleChangeGetLinkSection(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
          </>
        )}
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make space in Title bottom</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin bottom (Default : 50)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="Margin bottom"
            value={settingFormData?.[".getlink-section .section-title-area"]?.[
              "margin-bottom"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeGetLinkSectionTitleArea(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in section subtitle</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 16)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[
              ".getlink-section .section-title-area .section-subtitle"
            ]?.["font-size"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeGetLinkSectionTitleAreaSubtitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 600)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-control"
            value={
              settingFormData?.[
                ".getlink-section .section-title-area .section-subtitle"
              ]?.["font-weight"]
            }
            onChange={(e) => handleChangeGetLinkSectionTitleAreaSubtitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".getlink-section .section-title-area .section-subtitle"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={".getlink-section .section-title-area .section-subtitle"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".getlink-section .section-title-area .section-subtitle"
              ]?.["background-color"]
            }
            setSettingFormData={setSettingFormData}
            className={".getlink-section .section-title-area .section-subtitle"}
            classProperty={"background-color"}
            label={"Select Background Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding (Default : 5 - 20)"}
          </Label>
          <div className="d-flex justify-content-between">
            <Input
              className="form-control"
              name="p1"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".getlink-section .section-title-area .section-subtitle"
              ]?.["p1"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeGetLinkSectionTitleAreaSubtitle(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="p2"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".getlink-section .section-title-area .section-subtitle"
              ]?.["p2"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeGetLinkSectionTitleAreaSubtitle(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </div>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border Radius (Default : 15)"}
          </Label>
          <Input
            className="form-control"
            name="border-radius"
            type="number"
            placeholder="Border Radius"
            value={settingFormData?.[
              ".getlink-section .section-title-area .section-subtitle"
            ]?.["border-radius"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeGetLinkSectionTitleAreaSubtitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Display (Default : inline-block)"}
          </Label>
          <select
            name="display"
            id="display"
            className="form-control"
            value={
              settingFormData?.[
                ".getlink-section .section-title-area .section-subtitle"
              ]?.["display"]
            }
            onChange={(e) => handleChangeGetLinkSectionTitleAreaSubtitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {Display.map((elem) => {
              return <option value={elem["display"]}>{elem["display"]}</option>;
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin bottom (Default : 15)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="Margin bottom"
            value={settingFormData?.[
              ".getlink-section .section-title-area .section-subtitle"
            ]?.["margin-bottom"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeGetLinkSectionTitleAreaSubtitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in section Title</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 44)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[
              ".getlink-section .section-title-area .section-title"
            ]?.["font-size"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeGetLinkSectionTitleAreaTitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 600)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-control"
            value={
              settingFormData?.[
                ".getlink-section .section-title-area .section-title"
              ]?.["font-weight"]
            }
            onChange={(e) => handleChangeGetLinkSectionTitleAreaTitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".getlink-section .section-title-area .section-title"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={".getlink-section .section-title-area .section-title"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in getlinksbox | section-note</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 18)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[".getlinksbox .section-note"]?.[
              "font-size"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeGetLinkSectionNote(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 500)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-control"
            value={
              settingFormData?.[".getlinksbox .section-note"]?.["font-weight"]
            }
            onChange={(e) => handleChangeGetLinkSectionNote(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".getlink-section .section-title-area .section-title"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={".getlink-section .section-title-area .section-title"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in getlinksbox</h4>
        <Col md="4 mb-3">
          <CommonColor
            color={settingFormData?.[".getlinksbox"]?.["background-color"]}
            setSettingFormData={setSettingFormData}
            className={".getlinksbox"}
            classProperty={"background-color"}
            label={"Select Background Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding (Default : 40 - 40)"}
          </Label>
          <div className="d-flex justify-content-between">
            <Input
              className="form-control"
              name="p1"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[".getlinksbox"]?.["p1"]?.replace(
                /px/g,
                ""
              )}
              onChange={(e) => handleChangeGetLinkSBox(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="p2"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[".getlinksbox"]?.["p2"]?.replace(
                /px/g,
                ""
              )}
              onChange={(e) => handleChangeGetLinkSBox(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </div>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border Radius (Default : 15)"}
          </Label>
          <Input
            className="form-control"
            name="border-radius"
            type="number"
            placeholder="Border Radius"
            value={settingFormData?.[".getlinksbox"]?.[
              "border-radius"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeGetLinkSBox(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border (Default : 1 solid white)"}
          </Label>
          <div className="d-flex">
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="borderPx"
                type="number"
                placeholder="px"
                value={settingFormData?.[".getlinksbox"]?.["borderPx"]?.replace(
                  /px/g,
                  ""
                )}
                onChange={(e) => handleChangeGetLinkSBox(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div style={{ width: "100px" }}>
              <select
                name="borderThik"
                id="borderThik"
                className="form-select"
                value={settingFormData?.[".getlinksbox"]?.["borderThik"]}
                onChange={(e) => handleChangeGetLinkSBox(e)}
                style={{ borderRadius: "0", color: "black" }}
              >
                <option value="">--select--</option>
                {Border.map((elem) => {
                  return <option value={elem.border}>{elem.border}</option>;
                })}
              </select>
            </div>
            <div>
              <CommonColor
                color={settingFormData?.[".getlinksbox"]?.["borderColor"]}
                setSettingFormData={setSettingFormData}
                className={".getlinksbox"}
                classProperty={"borderColor"}
                label={"Select Color"}
              />
            </div>
          </div>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border Top (Default : 3 solid pink)"}
          </Label>
          <div className="d-flex">
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="borderTopPx"
                type="number"
                placeholder="px"
                value={settingFormData?.[".getlinksbox"]?.[
                  "borderTopPx"
                ]?.replace(/px/g, "")}
                onChange={(e) => handleChangeGetLinkSBox(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div style={{ width: "100px" }}>
              <select
                name="borderTopThik"
                id="borderTopThik"
                className="form-select"
                value={settingFormData?.[".getlinksbox"]?.["borderTopThik"]}
                onChange={(e) => handleChangeGetLinkSBox(e)}
                style={{ borderRadius: "0", color: "black" }}
              >
                <option value="">--select--</option>
                {Border.map((elem) => {
                  return <option value={elem.border}>{elem.border}</option>;
                })}
              </select>
            </div>
            <div>
              <CommonColor
                color={settingFormData?.[".getlinksbox"]?.["borderTopColor"]}
                setSettingFormData={setSettingFormData}
                className={".getlinksbox"}
                classProperty={"borderTopColor"}
                label={"Select Color"}
              />
            </div>
          </div>
        </Col>
        <Col md="6 mb-3">
          <Label htmlFor="validationCustom03">
            {"Box Shadow (Default : 5 5 15 black)"}
          </Label>
          <div className="d-flex">
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="bs1"
                type="number"
                placeholder="px"
                value={settingFormData?.[".getlinksbox"]?.["bs1"]?.replace(
                  /px/g,
                  ""
                )}
                onChange={(e) => handleChangeGetLinkSBox(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="bs2"
                type="number"
                placeholder="px"
                value={settingFormData?.[".getlinksbox"]?.["bs2"]?.replace(
                  /px/g,
                  ""
                )}
                onChange={(e) => handleChangeGetLinkSBox(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="bs3"
                type="number"
                placeholder="px"
                value={settingFormData?.[".getlinksbox"]?.["bs3"]?.replace(
                  /px/g,
                  ""
                )}
                onChange={(e) => handleChangeGetLinkSBox(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div>
              <CommonColor
                color={settingFormData?.[".getlinksbox"]?.["shadowColor"]}
                setSettingFormData={setSettingFormData}
                className={".getlinksbox"}
                classProperty={"shadowColor"}
                label={"Select Color"}
              />
            </div>
          </div>
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in getlinksbox | form</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Width (Default : 100)"}</Label>
          <Input
            className="form-control"
            name="width"
            type="number"
            placeholder="width"
            value={settingFormData?.[".getlinksbox .form-control"]?.[
              "width"
            ]?.replace(/%/g, "")}
            onChange={(e) => handleChangeGetLinkSBoxFormControl(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin bottom (Default : 10)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="Margin bottom"
            value={settingFormData?.[".getlinksbox .form-control"]?.[
              "margin-bottom"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeGetLinkSBoxFormControl(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[".getlinksbox .form-control"]?.[
                "background-color"
              ]
            }
            setSettingFormData={setSettingFormData}
            className={".getlinksbox .form-control"}
            classProperty={"background-color"}
            label={"Select Background Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border (Default : 2 solid black)"}
          </Label>
          <div className="d-flex ">
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="borderPx"
                type="number"
                placeholder="px"
                value={settingFormData?.[".getlinksbox .form-control"]?.[
                  "borderPx"
                ]?.replace(/px/g, "")}
                onChange={(e) => handleChangeGetLinkSBoxFormControl(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div style={{ width: "100px" }}>
              <select
                name="borderThik"
                id="borderThik"
                className="form-select"
                value={
                  settingFormData?.[".getlinksbox .form-control"]?.[
                    "borderThik"
                  ]
                }
                onChange={(e) => handleChangeGetLinkSBoxFormControl(e)}
                style={{ borderRadius: "0", color: "black" }}
              >
                <option value="">--select--</option>
                {Border.map((elem) => {
                  return <option value={elem.border}>{elem.border}</option>;
                })}
              </select>
            </div>
            <div>
              <CommonColor
                color={
                  settingFormData?.[".getlinksbox .form-control"]?.[
                    "borderColor"
                  ]
                }
                setSettingFormData={setSettingFormData}
                className={".getlinksbox .form-control"}
                classProperty={"borderColor"}
                label={"Select Color"}
              />
            </div>
          </div>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border Radius (Default : 4)"}
          </Label>
          <Input
            className="form-control"
            name="border-radius"
            type="number"
            placeholder="Border Radius"
            value={settingFormData?.[".getlinksbox .form-control"]?.[
              "border-radius"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeGetLinkSBoxFormControl(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 16)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[".getlinksbox .form-control"]?.[
              "font-size"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeGetLinkSBoxFormControl(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Appearance (Default : auto)"}
          </Label>
          <select
            name="appearance"
            id="appearance"
            className="form-control"
            value={
              settingFormData?.[".getlinksbox .form-control"]?.["appearance"]
            }
            onChange={(e) => handleChangeGetLinkSBoxFormControl(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {Appearance.map((elem) => {
              return (
                <option value={elem["appearance"]}>{elem["appearance"]}</option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Outline (Default : 0 solid white)"}
          </Label>
          <div className="d-flex">
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="outPx"
                type="number"
                placeholder="px"
                value={settingFormData?.[".getlinksbox .form-control"]?.[
                  "outPx"
                ]?.replace(/px/g, "")}
                onChange={(e) => handleChangeGetLinkSBoxFormControl(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div style={{ width: "100px" }}>
              <select
                name="outThik"
                id="outThik"
                className="form-select"
                value={
                  settingFormData?.[".getlinksbox .form-control"]?.["outThik"]
                }
                onChange={(e) => handleChangeGetLinkSBoxFormControl(e)}
                style={{ borderRadius: "0", color: "black" }}
              >
                <option value="">--select--</option>
                {Border.map((elem) => {
                  return <option value={elem.border}>{elem.border}</option>;
                })}
              </select>
            </div>
            <div>
              <CommonColor
                color={
                  settingFormData?.[".getlinksbox .form-control"]?.["outcolor"]
                }
                setSettingFormData={setSettingFormData}
                className={".getlinksbox .form-control"}
                classProperty={"outcolor"}
                label={"Select Color"}
              />
            </div>
          </div>
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in getlinksbox | button</h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[".getlink-section .getlinksbox .btn"]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={".getlink-section .getlinksbox .btn"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[".getlink-section .getlinksbox .btn"]?.[
                "background-color"
              ]
            }
            setSettingFormData={setSettingFormData}
            className={".getlink-section .getlinksbox .btn"}
            classProperty={"background-color"}
            label={"Select Background Color"}
          />
        </Col>
      </Row>
      <CustomStyle
        handleChange={handleChangeCustomStyle}
        value={settingFormData?.custom}
      />
    </>
  );
};

export default GetWhatsAppLinkThemeOne;
