import React, { useContext } from "react";
import { Button, Col, Container, Input, Label, Row } from "reactstrap";
import {
  Border,
  Display,
  THEME_UPDATE_MESSAGE,
  TextAlign,
  TextTransform,
  fontWeight,
} from "../../../../constant";
import PackagesContext from "../../../context/PackagesContext/PackagesContext";
import { Zoom, toast } from "react-toastify";
import { Switch } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { UploadCloud } from "react-feather";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import {
  AboutUsTheme,
  Cases,
  DELETE_CONFIRM,
  ProductTheme,
} from "../../../constant";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { delete_Multi_Image_Permanent } from "../apis/SliderApi/SliderApi";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import { IconButton } from "@material-ui/core";
import Dividers from "../../../common/divider";
import ProductImageUploadPopup from "../../../Modals/imageUploade/productImageUploadPopup";
import PaginationButtonStyle from "../../../common/paginationButtonStyle";
import CommonColor from "../../../common/CommonColor";
import CustomStyle from "../../../common/CustomStyle";
import { putUpdatePackages } from "../apis/PackagesApi/PackagesApi";
import packageTheme1 from "../ScreenShorts/Packages/PackageTheme1.png";
import packageTheme2 from "../ScreenShorts/Packages/PackageTheme2.png";
import ThemeImageShow from "../../../common/ThemeImageShow";

const SettingPackagesParentPopup = (props) => {
  const {
    isEdit,
    settingFormData,
    setSettingFormData,
    backImageToggle,
    setBackImageToggle,
    boxSection,
    setBoxSection,
    isBackgroundColor,
    setIsBackgroundColor,
    id,
    theme,
    setTheme,
    imgShow,
    setImgShow,
  } = useContext(PackagesContext);
  const {
    allPopupState,
    setAllPopupState,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
  } = useContext(ByteCardBuilderContext);
  const { imageUpload, handleSubmitSetting, handleChangeSettings } = props;
  const { setLoaderShow } = useContext(LoaderContext);

  /**
   * input value change
   * @param {event} e
   */

  const handleChangePackagesSection = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".packages-section"]: {
          ...prev[".packages-section"],
          [name]: value,
        },
      };
    });
  };

  const handleChangePackagesTitleArea = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".packages-section .section-title-area"]: {
          ...prev[".packages-section .section-title-area"],
          [name]: value,
        },
      };
    });
  };

  const handleChangePackagesTitleAreaSubtitle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".packages-section .section-title-area .section-subtitle"]: {
          ...prev[".packages-section .section-title-area .section-subtitle"],
          [name]: value,
        },
      };
    });
  };

  const handleChangePackagesTitleAreaSectionTitle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".packages-section .section-title-area .section-title"]: {
          ...prev[".packages-section .section-title-area .section-title"],
          [name]: value,
        },
      };
    });
  };

  const handleChangePackagesPlanSwitch = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".planswitch"]: {
          ...prev[".planswitch"],
          [name]: value,
        },
      };
    });
  };

  const handleChangePackagesPlanSwitchText = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".planswitch .switch-text"]: {
          ...prev[".planswitch .switch-text"],
          [name]: value,
        },
      };
    });
  };

  const handleChangePackagesPlanSwitchSlider = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".switch .slider"]: {
          ...prev[".switch .slider"],
          [name]: value,
        },
      };
    });
  };

  const handleChangePackagesPlanSwitchSliderBefore = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".switch .slider:before"]: {
          ...prev[".switch .slider:before"],
          [name]: value,
        },
      };
    });
  };

  const handleChangePackagesPackageList1 = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".packagelist-1 .package"]: {
          ...prev[".packagelist-1 .package"],
          [name]: value,
        },
      };
    });
  };

  const handleChangePackagesPackageList1Title = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".packages-section .packagelist-1 .package .title"]: {
          ...prev[".packages-section .packagelist-1 .package .title"],
          [name]: value,
        },
      };
    });
  };

  const handleChangePackagesPackageList1TitleH3 = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".packagelist-1 .package .title h3"]: {
          ...prev[".packagelist-1 .package .title h3"],
          [name]: value,
        },
      };
    });
  };

  const handleChangePackagesPackageList1TitleP = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".packagelist-1 .package .title p"]: {
          ...prev[".packagelist-1 .package .title p"],
          [name]: value,
        },
      };
    });
  };

  const handleChangePackagesPackageList1Cost = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".packagelist-1 .package .cost"]: {
          ...prev[".packagelist-1 .package .cost"],
          [name]: value,
        },
      };
    });
  };

  const handleChangePackagesPackageCostPrice = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".packagelist-1 .package .cost .price"]: {
          ...prev[".packagelist-1 .package .cost .price"],
          [name]: value,
        },
      };
    });
  };

  const handleChangePackagesPackageDesq = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".packagelist-1 .package .desq"]: {
          ...prev[".packagelist-1 .package .desq"],
          [name]: value,
        },
      };
    });
  };

  const handleChangePackagesPackageDesqLi = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".packagelist-1 .package .desq li"]: {
          ...prev[".packagelist-1 .package .desq li"],
          [name]: value,
        },
      };
    });
  };

  const handleChangePackagesPackageSelectBtn = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".packages-section .packagelist-1 .package .selectbtn"]: {
          ...prev[".packages-section .packagelist-1 .package .selectbtn"],
          [name]: value,
        },
      };
    });
  };

  const handleChangePackagesPackage2 = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".packagelist-2 .package"]: {
          ...prev[".packagelist-2 .package"],
          [name]: value,
        },
      };
    });
  };

  const handleChangePackages2Title = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".packages-section .packagelist-2 .package .title"]: {
          ...prev[".packages-section .packagelist-2 .package .title"],
          [name]: value,
        },
      };
    });
  };

  const handleChangePackages2Cost = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".packagelist-2 .package .cost"]: {
          ...prev[".packagelist-2 .package .cost"],
          [name]: value,
        },
      };
    });
  };

  const handleChangePackages2CostSpan = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".packagelist-2 .package .cost span"]: {
          ...prev[".packagelist-2 .package .cost span"],
          [name]: value,
        },
      };
    });
  };

  const handleChangePackages2Subtitle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".packagelist-2 .package .subtitle"]: {
          ...prev[".packagelist-2 .package .subtitle"],
          [name]: value,
        },
      };
    });
  };

  const handleChangePackages2Desq = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".packagelist-2 .package .desq"]: {
          ...prev[".packagelist-2 .package .desq"],
          [name]: value,
        },
      };
    });
  };

  const handleChangePackagesList2SDesqLi = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".packagelist-2 .package .desq li"]: {
          ...prev[".packagelist-2 .package .desq li"],
          [name]: value,
        },
      };
    });
  };

  const handleChangePackages2SelectBtn = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".packages-section .packagelist-2 .package .selectbtn"]: {
          ...prev[".packages-section .packagelist-2 .package .selectbtn"],
          [name]: value,
        },
      };
    });
  };

  const handleChangePackages2SelectBtnA = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".packagelist-2 .package .selectbtn a"]: {
          ...prev[".packagelist-2 .package .selectbtn a"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeCustomStyle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const resetHandler = () => {
    setSettingFormData({
      ".packages-section": {
        padding: "100px 0",
        "background-color": { r: 255, g: 255, b: 255, a: 100 },
      },
      ".packages-section.bg-image": {},
      ".packages-section.bg-image::before": {
        // "background-color": "#000000CC",
      },
      ".packages-section .section-title-area": {
        "margin-bottom": "50px",
      },
      ".packages-section .section-title-area .section-subtitle": {
        "font-size": "16px",
        "font-weight": "600",
        color: { r: 255, g: 73, b: 124, a: 100 },
        "background-color": { r: 255, g: 73, b: 124, a: 0.2 },
        padding: "5px 20px",
        p1: "5px",
        p2: "20px",
        "border-radius": "15px",
        "margin-bottom": "15px",
      },
      ".packages-section .section-title-area .section-title": {
        "font-size": "44px",
        "font-weight": "600",
        color: { r: 0, g: 0, b: 0, a: 100 },
      },
      ".planswitch": {
        "align-items": "center",
        "justify-content": "center",
        "margin-bottom": "20px",
      },
      ".planswitch .switch-text": {
        color: { r: 0, g: 0, b: 0, a: 100 },
        "font-size": "16px",
        "font-weight": "600",
      },
      ".switch .slider": {
        "border-radius": "34px",
        "background-color": { r: 204, g: 204, b: 204, a: 100 },
      },
      ".switch .slider:before": {
        bottom: "4px",
        "border-radius": "50%",
        "background-color": { r: 255, g: 255, b: 255, a: 100 },
      },
      ".switch input:checked + .slider": {
        "background-color": { r: 255, g: 73, b: 124, a: 100 },
      },
      ".packagelist-1 .package": {
        border: "1px solid { r: 238, g: 238, b: 238, a: 100 }",
        borderPx: "1px",
        borderThik: "solid",
        borderColor: { r: 238, g: 238, b: 238, a: 100 },
        "border-radius": "5px",
      },
      ".packages-section .packagelist-1 .package .title": {
        color: { r: 255, g: 255, b: 255, a: 100 },
        padding: "30px 10px 20px",
        p1: "30px",
        p2: "10px",
        p3: "20px",
        "text-align": "center",
        "text-transform": "uppercase",
        "background-color": { r: 255, g: 73, b: 124, a: 100 },
      },
      ".packagelist-1 .package .title:before": {
        "background-color": { r: 238, g: 238, b: 238, a: 100 },
      },
      ".packagelist-1 .package .title h3": {
        "font-weight": "700",
        "font-size": "20px",
      },
      ".packagelist-1 .package .title p": {
        "font-weight": "400",
      },
      ".packagelist-1 .package .cost": {
        "background-color": { r: 238, g: 238, b: 238, a: 100 },
        width: "100%",
        "text-align": "center",
        padding: "15px 10px 25px",
        p1: "15px",
        p2: "10px",
        p3: "25px",
        "font-size": "18px",
        "font-weight": "700",
      },
      ".packagelist-1 .package .cost:before": {
        "background-color": { r: 255, g: 255, b: 255, a: 100 },
      },
      ".packagelist-1 .package .cost .price": {
        "font-size": "35px",
      },
      ".packagelist-1 .package .desq": {
        "background-color": { r: 255, g: 255, b: 255, a: 100 },
        padding: "10px 10px 20px",
        p1: "10px",
        p2: "10px",
        p3: "20px",
      },
      ".packagelist-1 .package .desq li": {
        "font-size": "14px",
        "font-weight": "400",
        color: { r: 102, g: 102, b: 102, a: 100 },
        margin: "15px 0",
        padding: "0 5px 0 20px",
        p1: "5px",
        p2: "20px",
      },
      ".packagelist-1 .package .desq li:before": {
        color: { r: 255, g: 73, b: 124, a: 100 },
      },
      ".packages-section .packagelist-1 .package .selectbtn": {
        "background-color": { r: 255, g: 73, b: 124, a: 100 },
        padding: "10px 10px 15px",
        p1: "10px",
        p2: "10px",
        p3: "15px",
        "font-size": "16px",
        color: { r: 255, g: 255, b: 255, a: 100 },
        "font-weight": "400",
        "text-transform": "uppercase",
        "text-align": "center",
      },
      ".packagelist-1 .package .selectbtn:before": {
        "background-color": { r: 255, g: 73, b: 124, a: 100 },
      },
      ".packagelist-2 .package": {
        border: "1px solid { r: 238, g: 238, b: 238, a: 100 }",
        borderPx: "1px",
        borderThik: "solid",
        borderColor: { r: 238, g: 238, b: 238, a: 100 },
        "border-radius": "5px",
      },
      ".packages-section .packagelist-2 .package .title": {
        padding: "15px",
        color: { r: 255, g: 255, b: 255, a: 100 },
        "font-size": "20px",
        "font-weight": "500",
        "background-color": { r: 255, g: 73, b: 124, a: 100 },
        "text-align": "center",
        "text-transform": "uppercase",
      },
      ".packagelist-2 .package .cost": {
        padding: "20px 10px",
        p1: "20px",
        p2: "10px",
        "text-align": "center",
        "background-color": { r: 31, g: 39, b: 50, a: 100 },
      },
      ".packagelist-2 .package .cost span": {
        border: "2px solid { r: 255, g: 255, b: 255, a: 100 }",
        borderPx: "2px",
        borderThik: "solid",
        borderColor: { r: 255, g: 255, b: 255, a: 100 },
        "border-radius": "50%",
        "line-height": "70px",
        "text-align": "center",
        display: "inline-block",
        color: { r: 255, g: 255, b: 255, a: 100 },
        "box-shadow": "0 2px 2px 1px { r: 0, g: 0, b: 0, a: 0.188 }",
        bs1: "2px",
        bs2: "2px",
        bs3: "1px",
        shadowColor: { r: 0, g: 0, b: 0, a: 0.188 },
      },
      ".packagelist-2 .package .subtitle": {
        padding: "15px 10px",
        p1: "15px",
        p2: "10px",
        color: { r: 255, g: 255, b: 255, a: 100 },
        "background-color": { r: 117, g: 127, b: 137, a: 100 },
        "text-align": "center",
        "font-weight": "400",
        "font-size": "14px",
      },
      ".packagelist-2 .package .desq": {
        "background-color": { r: 255, g: 255, b: 255, a: 100 },
        padding: "13px",
      },
      ".packagelist-2 .package .desq li": {
        margin: "5px 0",
        "padding-left": "22px",
        "font-size": "12px",
        color: { r: 102, g: 102, b: 102, a: 100 },
      },
      ".packagelist-2 .package .desq li:before": {
        color: { r: 255, g: 192, b: 203, a: 100 },
      },
      ".packages-section .packagelist-2 .package .selectbtn": {
        "text-align": "center",
        padding: "10px",
        display: "inline-block",
        width: "100%",
        "background-color": { r: 255, g: 73, b: 124, a: 100 },
      },
      ".packagelist-2 .package .selectbtn a": {
        border: "2px solid { r: 255, g: 255, b: 255, a: 100 }",
        borderPx: "2px",
        borderThik: "solid",
        borderColor: { r: 255, g: 255, b: 255, a: 100 },
        padding: "8px 25px",
        p1: "8px",
        p2: "25px",
        "font-size": "12px",
        "text-transform": "uppercase",
        "letter-spacing": "1px",
        color: { r: 255, g: 255, b: 255, a: 100 },
        "border-radius": "3px",
      },
      ".packagelist-2 .package .selectbtn a:hover": {
        "background-color": { r: 255, g: 255, b: 255, a: 100 },
        color: { r: 102, g: 102, b: 102, a: 100 },
      },
      ".packages-section .more-btn": {
        color: { r: 255, g: 255, b: 255, a: 100 },
        "font-size": "16px",
        "font-weight": "normal",
        "border-radius": "15px",
        padding: "5px 20px",
        p1: "5px",
        p2: "20px",
        "background-color": { r: 255, g: 73, b: 124, a: 100 },
      },
      ".packages-section .more-btn:hover": {
        "background-color": { r: 255, g: 73, b: 124, a: 100 },
        color: { r: 255, g: 255, b: 255, a: 100 },
      },
      ".pagination-positions": {
        "justify-content": "center",
      },
      ".pagination .page-link": {
        color: { r: 255, g: 73, b: 124, a: 100 },
      },
      ".pagination .page-item.active .page-link": {
        "background-color": { r: 255, g: 73, b: 124, a: 100 },
        "border-color": { r: 255, g: 73, b: 124, a: 100 },
      },
      custom: "",
      packageId: "",
    });
    setBackImageToggle(false);
    setBoxSection(false);
    setIsBackgroundColor(true);
  };

  /**
   * delete image permanently
   */
  const deleteImagePermanent = async () => {
    try {
      let confirmation = window.confirm(DELETE_CONFIRM);
      if (confirmation) {
        setLoaderShow(true);
        let formData = {
          fileName: settingFormData?.[".packages-section.bg-image"]?.[
            "background-image"
          ]
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
            .pop(),
        };
        const { data } = await delete_Multi_Image_Permanent(formData);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
            position: "top-center",
          });
          setSettingFormData({
            ...settingFormData,
            ".packages-section.bg-image": { "background-image": "" },
          });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setLoaderShow(false);
    }
  };

  /**
   * image reupload
   */
  const reupload = () => {
    setSettingFormData((prev) => {
      return {
        ...prev,
        image: "",
      };
    });
  };

  const handleTheme = async (e) => {
    setLoaderShow(true);
    const { value } = e.target;
    setTheme(value);
    try {
      let formData = {
        theme: value,
        packageId: id,
      };
      const { data } = await putUpdatePackages(formData);
      if (data.status === 200) {
        await handleChangeSettings(id, true);
        TostMessage({
          message: { message: THEME_UPDATE_MESSAGE },
          type: "success",
        });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    } finally {
      setLoaderShow(false);
    }
  };
  const handleClose = () => {
    setImgShow(false);
  };
  const themeImg = {
    1: packageTheme1,
    2: packageTheme2,
  };
  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col sm="12" xl="12">
            <div className="mt-4 d-flex flex-column justify-content-center position-relative">
              <div
                className="d-flex justify-content-between position-sticky"
                style={{ top: "80px", background: "white", zIndex: 999 }}
              >
                <h6>{isEdit ? "Edit" : "Add New"}</h6>
                <div className="d-flex gap-2">
                  <Button onClick={resetHandler}>Reset</Button>
                  <Button onClick={() => handleSubmitSetting(true)}>
                    Restore
                  </Button>
                  <Button
                    onClick={() => handleSubmitSetting(null, false)}
                    color="success"
                  >
                    Save & View
                  </Button>
                </div>
              </div>
              <Row>
                <Col md="12 mb-3">
                  <label htmlFor="">Theme</label>
                  <select
                    name="theme"
                    id="font-weight"
                    className="form-select"
                    value={theme}
                    onChange={(e) => handleTheme(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    {ProductTheme.map((ele) => {
                      return (
                        <option value={ele.theme} key={ele.theme}>
                          {ele.themeName}
                        </option>
                      );
                    })}
                  </select>
                </Col>
              </Row>
              {!imgShow && (
                <div>
                  <Button
                    onClick={() => setImgShow(true)}
                    color="primary"
                    className="mt-2"
                  >
                    Show Theme Image
                  </Button>
                </div>
              )}
              {imgShow && (
                <ThemeImageShow
                  themeImg={themeImg[theme]}
                  handleClose={handleClose}
                />
              )}
              <Row className="mt-4">
                <h4 className="mb-3">Make change in Main section</h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Padding (default : 100)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="padding"
                    type="number"
                    placeholder="Padding"
                    value={settingFormData?.[".packages-section"]?.["padding"]
                      ?.split(" ")[0]
                      ?.replace(/px/g, "")}
                    onChange={(e) => handleChangePackagesSection(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <div className="d-flex align-items-center">
                    <Label htmlFor="validationCustom03">
                      {isBackgroundColor
                        ? "Add Background Color"
                        : "Remove Background Color"}
                    </Label>
                    <Switch
                      checked={isBackgroundColor}
                      inputProps={{ "aria-label": "controlled" }}
                      onChange={(e) => {
                        setIsBackgroundColor(e.target.checked);
                        setSettingFormData((prev) => {
                          if (e.target.checked) {
                            if (boxSection) {
                              return {
                                ...prev,
                                [".packages-section"]: {
                                  "background-color": "#f9f9f9",
                                  padding:
                                    settingFormData?.[".packages-section"]?.[
                                      "padding"
                                    ],
                                  width: settingFormData?.[
                                    ".packages-section"
                                  ]?.["width"]?.replace("rem)", ""),
                                  margin: settingFormData?.[
                                    ".packages-section"
                                  ]?.["margin"]
                                    ?.split(" ")[0]
                                    ?.replace(/px/g, ""),
                                  "border-radius": settingFormData?.[
                                    ".packages-section"
                                  ]?.["border-radius"]?.replace(/rem/g, ""),
                                },
                              };
                            } else {
                              return {
                                ...prev,
                                [".packages-section"]: {
                                  "background-color": "#f9f9f9",
                                  padding:
                                    settingFormData?.[".packages-section"]?.[
                                      "padding"
                                    ],
                                },
                              };
                            }
                          } else {
                            if (boxSection) {
                              return {
                                ...prev,
                                [".packages-section"]: {
                                  padding:
                                    settingFormData?.[".packages-section"]?.[
                                      "padding"
                                    ],
                                  width: settingFormData?.[
                                    ".packages-section"
                                  ]?.["width"]?.replace("rem)", ""),
                                  margin: settingFormData?.[
                                    ".packages-section"
                                  ]?.["margin"]
                                    ?.split(" ")[0]
                                    ?.replace(/px/g, ""),
                                  "border-radius": settingFormData?.[
                                    ".packages-section"
                                  ]?.["border-radius"]?.replace(/rem/g, ""),
                                },
                              };
                            } else {
                              return {
                                ...prev,
                                [".packages-section"]: {
                                  padding:
                                    settingFormData?.[".packages-section"]?.[
                                      "padding"
                                    ],
                                },
                              };
                            }
                          }
                        });
                      }}
                    />
                  </div>
                  {isBackgroundColor && (
                    <CommonColor
                      color={
                        settingFormData?.[".packages-section"]?.[
                          "background-color"
                        ]
                      }
                      setSettingFormData={setSettingFormData}
                      className={".packages-section"}
                      classProperty={"background-color"}
                      label={"Select Background Color"}
                    />
                  )}
                </Col>
                <p>{"Box Section : convert your section to Box section"}</p>
                <div>
                  <Switch
                    checked={boxSection}
                    inputProps={{ "aria-label": "controlled" }}
                    onChange={(e) => {
                      setBoxSection(e.target.checked);
                      setSettingFormData((prev) => {
                        return {
                          ...prev,
                          ".packages-section": {
                            padding: settingFormData?.[".packages-section"]?.[
                              "padding"
                            ]
                              ?.split(" ")[0]
                              ?.replace(/px/g, ""),
                            "background-color":
                              settingFormData?.[".packages-section"]?.[
                                "background-color"
                              ],
                            width: "10",
                            margin: "0",
                            "border-radius": "3",
                          },
                        };
                      });
                    }}
                  />
                </div>
                {boxSection && (
                  <>
                    <Col md="4 mb-3">
                      <Label htmlFor="validationCustom03">
                        {"Width (Default : 10)"}
                      </Label>
                      <Input
                        className="form-control"
                        name="width"
                        type="number"
                        placeholder="width"
                        value={settingFormData?.[".packages-section"]?.[
                          "width"
                        ]?.replace("rem)", "")}
                        onChange={(e) => handleChangePackagesSection(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </Col>
                    <Col md="4 mb-3">
                      <Label htmlFor="validationCustom03">
                        {"Margin (Default : 0)"}
                      </Label>
                      <Input
                        className="form-control"
                        name="margin"
                        type="number"
                        placeholder="margin"
                        value={settingFormData?.[".packages-section"]?.[
                          "margin"
                        ]
                          ?.split(" ")[0]
                          ?.replace(/px/g, "")}
                        onChange={(e) => handleChangePackagesSection(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </Col>
                    <Col md="4 mb-3">
                      <Label htmlFor="validationCustom03">
                        {"Border Radius (Default : 3)"}
                      </Label>
                      <Input
                        className="form-control"
                        name="border-radius"
                        type="number"
                        placeholder="Border Radius"
                        value={settingFormData?.[".packages-section"]?.[
                          "border-radius"
                        ]?.replace(/rem/g, "")}
                        onChange={(e) => handleChangePackagesSection(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </Col>
                  </>
                )}
              </Row>
              <hr />
              <Row className="mt-4">
                <Col md="12 mb-3">
                  <h4 className="mb-3">Add Background Image in section</h4>

                  <p>do you want to upload Background image</p>
                  <Switch
                    checked={backImageToggle}
                    inputProps={{ "aria-label": "controlled" }}
                    onChange={(e) => {
                      setBackImageToggle(e.target.checked);
                      setSettingFormData((prev) => {
                        if (e.target.checked) {
                          return {
                            ...prev,
                            image: "",
                            ".packages-section.bg-image": {
                              "background-image": "",
                            },
                            ".packages-section.bg-image::before": {
                              "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
                            },
                          };
                        } else {
                          return {
                            ...prev,
                            image: "",
                            ".packages-section.bg-image": {},
                            ".packages-section.bg-image::before": {},
                          };
                        }
                      });
                    }}
                  />
                  {backImageToggle && (
                    <>
                      {" "}
                      <Col md="6 mb-3">
                        <Dividers divide="IMAGE" />
                        <p className="mb-4">
                          File type required JPG, PNG (512px X 512px)
                        </p>
                      </Col>
                      <Row>
                        <Col md="4 mb-3">
                          <div
                            className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center"
                            style={{ cursor: "pointer" }}
                          >
                            {!settingFormData?.[".packages-section.bg-image"]?.[
                              "background-image"
                            ] && (
                              <span
                                onClick={() =>
                                  OpenModalCommonFunc(
                                    Cases.PRODUCT_UPLOAD,
                                    setAllPopupState
                                  )
                                }
                              >
                                <UploadCloud />
                              </span>
                            )}

                            {settingFormData?.[".packages-section.bg-image"]?.[
                              "background-image"
                            ] && (
                              <img
                                src={
                                  settingFormData?.[
                                    ".packages-section.bg-image"
                                  ]?.["background-image"] || ""
                                }
                                alt=""
                                style={{
                                  width: "85%",
                                  height: "90px",
                                  objectFit: "contain",
                                  position: "absolute",
                                }}
                                onClick={() => deleteImagePermanent()}
                              />
                            )}
                            {settingFormData?.[".packages-section.bg-image"]?.[
                              "background-image"
                            ] && (
                              <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="close"
                                style={{
                                  color: "red",
                                  position: "absolute",
                                  top: "-22px",
                                  left: "1px",
                                }}
                                onClick={() => deleteImagePermanent()}
                              >
                                <Close
                                  style={{
                                    fontSize: "25px",
                                    fontWeight: "bold",
                                  }}
                                />
                              </IconButton>
                            )}
                          </div>
                        </Col>
                        <Col md="6 mb-3">
                          <h4 className="mb-3">
                            Add Background color in section
                          </h4>
                          <Col md="6 mb-3">
                            <CommonColor
                              color={
                                settingFormData?.[
                                  ".packages-section.bg-image::before"
                                ]?.["background-color"]
                              }
                              setSettingFormData={setSettingFormData}
                              className={".packages-section.bg-image::before"}
                              classProperty={"background-color"}
                              label={"Select Background Color"}
                            />
                          </Col>
                        </Col>
                      </Row>
                    </>
                  )}
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <h4 className="mb-3">Make space in Title bottom</h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Margin Bottom (Default : 50)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="margin-bottom"
                    type="number"
                    placeholder="Margin Bottom"
                    value={settingFormData?.[
                      ".packages-section .section-title-area"
                    ]["margin-bottom"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangePackagesTitleArea(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <h4 className="mb-3">Make change in section subtitle</h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Size (Default : 16)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="font-size"
                    type="number"
                    placeholder="Font Size"
                    value={settingFormData?.[
                      ".packages-section .section-title-area .section-subtitle"
                    ]?.["font-size"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangePackagesTitleAreaSubtitle(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Weight (Default : 600)"}
                  </Label>
                  <select
                    name="font-weight"
                    id="font-weight"
                    className="form-select"
                    value={
                      settingFormData?.[
                        ".packages-section .section-title-area .section-subtitle"
                      ]?.["font-weight"]
                    }
                    onChange={(e) => handleChangePackagesTitleAreaSubtitle(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value="">--select--</option>
                    {fontWeight.map((elem) => {
                      return (
                        <option value={elem["font-weight"]}>
                          {elem["font-weight"]}
                        </option>
                      );
                    })}
                  </select>
                </Col>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".packages-section .section-title-area .section-subtitle"
                      ]?.["color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={
                      ".packages-section .section-title-area .section-subtitle"
                    }
                    classProperty={"color"}
                    label={"Select Color"}
                  />
                </Col>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".packages-section .section-title-area .section-subtitle"
                      ]?.["background-color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={
                      ".packages-section .section-title-area .section-subtitle"
                    }
                    classProperty={"background-color"}
                    label={"Select Background Color"}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Padding (Default : 5 - 20)"}
                  </Label>
                  <div className="d-flex justify-content-between">
                    <Input
                      className="form-control"
                      name="p1"
                      type="number"
                      placeholder="Padding"
                      value={settingFormData?.[
                        ".packages-section .section-title-area .section-subtitle"
                      ]?.["p1"]?.replace(/px/g, "")}
                      onChange={(e) => handleChangePackagesTitleAreaSubtitle(e)}
                      style={{ borderRadius: "0", color: "black" }}
                    />
                    <Input
                      className="form-control"
                      name="p2"
                      type="number"
                      placeholder="Padding"
                      value={settingFormData?.[
                        ".packages-section .section-title-area .section-subtitle"
                      ]?.["p2"]?.replace(/px/g, "")}
                      onChange={(e) => handleChangePackagesTitleAreaSubtitle(e)}
                      style={{ borderRadius: "0", color: "black" }}
                    />
                  </div>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Border Radius (Default : 15)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="border-radius"
                    type="number"
                    placeholder="Border Radius"
                    value={settingFormData?.[
                      ".packages-section .section-title-area .section-subtitle"
                    ]?.["border-radius"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangePackagesTitleAreaSubtitle(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Margin Bottom (Default : 15)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="margin-bottom"
                    type="number"
                    placeholder="Margin Bottom"
                    value={settingFormData?.[
                      ".packages-section .section-title-area .section-subtitle"
                    ]?.["margin-bottom"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangePackagesTitleAreaSubtitle(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <h4 className="mb-3">Make change in section Title</h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Size (Default : 44)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="font-size"
                    type="number"
                    placeholder="Font Size"
                    value={settingFormData?.[
                      ".packages-section .section-title-area .section-title"
                    ]?.["font-size"]?.replace(/px/g, "")}
                    onChange={(e) =>
                      handleChangePackagesTitleAreaSectionTitle(e)
                    }
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Weight (Default : 600)"}
                  </Label>
                  <select
                    name="font-weight"
                    id="font-weight"
                    className="form-select"
                    value={
                      settingFormData?.[
                        ".packages-section .section-title-area .section-title"
                      ]?.["font-weight"]
                    }
                    onChange={(e) =>
                      handleChangePackagesTitleAreaSectionTitle(e)
                    }
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value="">--select--</option>
                    {fontWeight.map((elem) => {
                      return (
                        <option value={elem["font-weight"]}>
                          {elem["font-weight"]}
                        </option>
                      );
                    })}
                  </select>
                </Col>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".packages-section .section-title-area .section-title"
                      ]?.["color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={
                      ".packages-section .section-title-area .section-title"
                    }
                    classProperty={"color"}
                    label={"Select Color"}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <h4 className="mb-3">Make change in plan switch</h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Align Items (Default : center)"}
                  </Label>
                  <select
                    name="align-items"
                    id="align-items"
                    className="form-control"
                    value={settingFormData?.[".planswitch"]?.["align-items"]}
                    onChange={(e) => handleChangePackagesPlanSwitch(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value="">--select--</option>
                    {TextAlign.map((elem) => {
                      return (
                        <option value={elem["text-align"]}>
                          {elem["text-align"]}
                        </option>
                      );
                    })}
                  </select>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Justify Content (Default : center)"}
                  </Label>
                  <select
                    name="justify-content"
                    id="justify-content"
                    className="form-control"
                    value={
                      settingFormData?.[".planswitch"]?.["justify-content"]
                    }
                    onChange={(e) => handleChangePackagesPlanSwitch(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value="">--select--</option>
                    {TextAlign.map((elem) => {
                      return (
                        <option value={elem["text-align"]}>
                          {elem["text-align"]}
                        </option>
                      );
                    })}
                  </select>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Margin Bottom (Default : 20)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="margin-bottom"
                    type="number"
                    placeholder="Margin Bottom"
                    value={settingFormData?.[".planswitch"]?.[
                      "margin-bottom"
                    ]?.replace(/px/g, "")}
                    onChange={(e) => handleChangePackagesPlanSwitch(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
              <Row className="mt-2">
                <h4 className="mb-3">Make change in plan switch text</h4>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[".planswitch .switch-text"]?.["color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".planswitch .switch-text"}
                    classProperty={"color"}
                    label={"Select Color"}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Size (Default : 16)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="font-size"
                    type="number"
                    placeholder="Font Size"
                    value={settingFormData?.[".planswitch .switch-text"]?.[
                      "font-size"
                    ]?.replace(/px/g, "")}
                    onChange={(e) => handleChangePackagesPlanSwitchText(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Weight (Default : 600)"}
                  </Label>
                  <select
                    name="font-weight"
                    id="font-weight"
                    className="form-select"
                    value={
                      settingFormData?.[".planswitch .switch-text"]?.[
                        "font-weight"
                      ]
                    }
                    onChange={(e) => handleChangePackagesPlanSwitchText(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value="">--select--</option>
                    {fontWeight.map((elem) => {
                      return (
                        <option value={elem["font-weight"]}>
                          {elem["font-weight"]}
                        </option>
                      );
                    })}
                  </select>
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <h4 className="mb-3">Make change in switch | slider</h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Border Radius (Default : 34)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="border-radius"
                    type="number"
                    placeholder="Border Radius"
                    value={settingFormData?.[".switch .slider"]?.[
                      "border-radius"
                    ]?.replace(/px/g, "")}
                    onChange={(e) => handleChangePackagesPlanSwitchSlider(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[".switch .slider"]?.["background-color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".switch .slider"}
                    classProperty={"background-color"}
                    label={"Select Background Color"}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <h4 className="mb-3">Make change in switch | slider:before</h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Bottom (Default : 4)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="bottom"
                    type="number"
                    placeholder="Bottom"
                    value={settingFormData?.[".switch .slider:before"]?.[
                      "bottom"
                    ]?.replace(/px/g, "")}
                    onChange={(e) =>
                      handleChangePackagesPlanSwitchSliderBefore(e)
                    }
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Border Radius (Default : 50)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="border-radius"
                    type="number"
                    placeholder="Border Radius"
                    value={settingFormData?.[".switch .slider:before"]?.[
                      "border-radius"
                    ]?.replace(/%/g, "")}
                    onChange={(e) =>
                      handleChangePackagesPlanSwitchSliderBefore(e)
                    }
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[".switch .slider:before"]?.[
                        "background-color"
                      ]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".switch .slider:before"}
                    classProperty={"background-color"}
                    label={"Select Background Color"}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <h4 className="mb-3">Change switch color at checked</h4>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[".switch input:checked + .slider"]?.[
                        "background-color"
                      ]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".switch input:checked + .slider"}
                    classProperty={"background-color"}
                    label={"Select Background Color"}
                  />
                </Col>
              </Row>
              <hr /> <h4 className="mb-3">This is for Theme 1</h4>
              <Row className="mt-2">
                <h4 className="mb-3">Make change in packagelist-1 | package</h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Border (Default : 1 solid black)"}
                  </Label>
                  <div className="d-flex ">
                    <div style={{ width: "100px" }}>
                      <Input
                        className="form-control"
                        name="borderPx"
                        type="number"
                        placeholder="px"
                        value={settingFormData?.[".packagelist-1 .package"]?.[
                          "borderPx"
                        ]?.replace(/px/g, "")}
                        onChange={(e) => handleChangePackagesPackageList1(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </div>
                    <div style={{ width: "100px" }}>
                      <select
                        name="borderThik"
                        id="borderThik"
                        className="form-control"
                        value={
                          settingFormData?.[".packagelist-1 .package"]?.[
                            "borderThik"
                          ]
                        }
                        onChange={(e) => handleChangePackagesPackageList1(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      >
                        <option value="">--select--</option>
                        {Border.map((elem) => {
                          return (
                            <option value={elem.border}>{elem.border}</option>
                          );
                        })}
                      </select>
                    </div>
                    <div>
                      <CommonColor
                        color={
                          settingFormData?.[".packagelist-1 .package"]?.[
                            "borderColor"
                          ]
                        }
                        setSettingFormData={setSettingFormData}
                        className={".packagelist-1 .package"}
                        classProperty={"borderColor"}
                        label={"Select Color"}
                      />
                    </div>
                  </div>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Border Radius (Default : 5)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="border-radius"
                    type="number"
                    placeholder="Border Radius"
                    value={settingFormData?.[".packagelist-1 .package"]?.[
                      "border-radius"
                    ]?.replace(/px/g, "")}
                    onChange={(e) => handleChangePackagesPackageList1(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <h4 className="mb-3">
                  Make change in packagelist-1 | package | title
                </h4>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".packages-section .packagelist-1 .package .title"
                      ]?.["color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={
                      ".packages-section .packagelist-1 .package .title"
                    }
                    classProperty={"color"}
                    label={"Select Color"}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Padding (Default : 30 10 20)"}
                  </Label>
                  <div className="d-flex justify-content-between">
                    <Input
                      className="form-control"
                      name="p1"
                      type="number"
                      placeholder="Padding"
                      value={settingFormData?.[
                        ".packages-section .packagelist-1 .package .title"
                      ]?.["p1"]?.replace(/px/g, "")}
                      onChange={(e) => handleChangePackagesPackageList1Title(e)}
                      style={{ borderRadius: "0", color: "black" }}
                    />
                    <Input
                      className="form-control"
                      name="p2"
                      type="number"
                      placeholder="Padding"
                      value={settingFormData?.[
                        ".packages-section .packagelist-1 .package .title"
                      ]?.["p2"]?.replace(/px/g, "")}
                      onChange={(e) => handleChangePackagesPackageList1Title(e)}
                      style={{ borderRadius: "0", color: "black" }}
                    />
                    <Input
                      className="form-control"
                      name="p3"
                      type="number"
                      placeholder="Padding"
                      value={settingFormData?.[
                        ".packages-section .packagelist-1 .package .title"
                      ]?.["p3"]?.replace(/px/g, "")}
                      onChange={(e) => handleChangePackagesPackageList1Title(e)}
                      style={{ borderRadius: "0", color: "black" }}
                    />
                  </div>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Text Align (Default : center)"}
                  </Label>
                  <select
                    name="text-align"
                    id="text-align"
                    className="form-control"
                    value={
                      settingFormData?.[
                        ".packages-section .packagelist-1 .package .title"
                      ]?.["text-align"]
                    }
                    onChange={(e) => handleChangePackagesPackageList1Title(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value="">--select--</option>
                    {TextAlign.map((elem) => {
                      return (
                        <option value={elem["text-align"]}>
                          {elem["text-align"]}
                        </option>
                      );
                    })}
                  </select>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Text Transform (Default : uppercase)"}
                  </Label>
                  <select
                    name="text-transform"
                    id="text-transform"
                    className="form-control"
                    value={
                      settingFormData?.[
                        ".packages-section .packagelist-1 .package .title"
                      ]?.["text-transform"]
                    }
                    onChange={(e) => handleChangePackagesPackageList1Title(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value="">--select--</option>
                    {TextTransform.map((elem) => {
                      return (
                        <option
                          value={elem["text-transform"]}
                          key={elem["text-transform"]}
                        >
                          {elem["text-transform"]}
                        </option>
                      );
                    })}
                  </select>
                </Col>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".packages-section .packagelist-1 .package .title"
                      ]?.["background-color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={
                      ".packages-section .packagelist-1 .package .title"
                    }
                    classProperty={"background-color"}
                    label={"Select Background Color"}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <h4 className="mb-3">
                  Make change in packagelist-1 | package | title:before
                </h4>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".packagelist-1 .package .title:before"
                      ]?.["background-color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".packagelist-1 .package .title:before"}
                    classProperty={"background-color"}
                    label={"Select Background Color"}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <h4 className="mb-3">
                  Make change in packagelist-1 | package | title
                </h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Weight (Default : 700)"}
                  </Label>
                  <select
                    name="font-weight"
                    id="font-weight"
                    className="form-select"
                    value={
                      settingFormData?.[".packagelist-1 .package .title h3"]?.[
                        "font-weight"
                      ]
                    }
                    onChange={(e) => handleChangePackagesPackageList1TitleH3(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value="">--select--</option>
                    {fontWeight.map((elem) => {
                      return (
                        <option value={elem["font-weight"]}>
                          {elem["font-weight"]}
                        </option>
                      );
                    })}
                  </select>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Size (Default : 20)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="font-size"
                    type="number"
                    placeholder="Font Size"
                    value={settingFormData?.[
                      ".packagelist-1 .package .title h3"
                    ]?.["font-size"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangePackagesPackageList1TitleH3(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <h4 className="mb-3">
                  Make change in packagelist-1 | package | title p
                </h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Weight (Default : 400)"}
                  </Label>
                  <select
                    name="font-weight"
                    id="font-weight"
                    className="form-select"
                    value={
                      settingFormData?.[".packagelist-1 .package .title p"]?.[
                        "font-weight"
                      ]
                    }
                    onChange={(e) => handleChangePackagesPackageList1TitleP(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value="">--select--</option>
                    {fontWeight.map((elem) => {
                      return (
                        <option value={elem["font-weight"]}>
                          {elem["font-weight"]}
                        </option>
                      );
                    })}
                  </select>
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <h4 className="mb-3">
                  Make change in packagelist-1 | package | cost
                </h4>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[".packagelist-1 .package .cost"]?.[
                        "background-color"
                      ]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".packagelist-1 .package .cost"}
                    classProperty={"background-color"}
                    label={"Select Background Color"}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Width (Default : 100)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="width"
                    type="number"
                    placeholder="width"
                    value={settingFormData?.[".packagelist-1 .package .cost"]?.[
                      "width"
                    ]?.replace(/%/g, "")}
                    onChange={(e) => handleChangePackagesPackageList1Cost(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Text Align (Default : center)"}
                  </Label>
                  <select
                    name="text-align"
                    id="text-align"
                    className="form-control"
                    value={
                      settingFormData?.[".packagelist-1 .package .cost"]?.[
                        "text-align"
                      ]
                    }
                    onChange={(e) => handleChangePackagesPackageList1Cost(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value="">--select--</option>
                    {TextAlign.map((elem) => {
                      return (
                        <option value={elem["text-align"]}>
                          {elem["text-align"]}
                        </option>
                      );
                    })}
                  </select>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Padding (Default : 15 10 25)"}
                  </Label>
                  <div className="d-flex justify-content-between">
                    <Input
                      className="form-control"
                      name="p1"
                      type="number"
                      placeholder="Padding"
                      value={settingFormData?.[
                        ".packagelist-1 .package .cost"
                      ]?.["p1"]?.replace(/px/g, "")}
                      onChange={(e) => handleChangePackagesPackageList1Cost(e)}
                      style={{ borderRadius: "0", color: "black" }}
                    />
                    <Input
                      className="form-control"
                      name="p2"
                      type="number"
                      placeholder="Padding"
                      value={settingFormData?.[
                        ".packagelist-1 .package .cost"
                      ]?.["p2"]?.replace(/px/g, "")}
                      onChange={(e) => handleChangePackagesPackageList1Cost(e)}
                      style={{ borderRadius: "0", color: "black" }}
                    />
                    <Input
                      className="form-control"
                      name="p3"
                      type="number"
                      placeholder="Padding"
                      value={settingFormData?.[
                        ".packagelist-1 .package .cost"
                      ]?.["p3"]?.replace(/px/g, "")}
                      onChange={(e) => handleChangePackagesPackageList1Cost(e)}
                      style={{ borderRadius: "0", color: "black" }}
                    />
                  </div>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Size (Default : 18)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="font-size"
                    type="number"
                    placeholder="Font Size"
                    value={settingFormData?.[".packagelist-1 .package .cost"]?.[
                      "font-size"
                    ]?.replace(/px/g, "")}
                    onChange={(e) => handleChangePackagesPackageList1Cost(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Weight (Default : 700)"}
                  </Label>
                  <select
                    name="font-weight"
                    id="font-weight"
                    className="form-select"
                    value={
                      settingFormData?.[".packagelist-1 .package .cost"]?.[
                        "font-weight"
                      ]
                    }
                    onChange={(e) => handleChangePackagesPackageList1Cost(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value="">--select--</option>
                    {fontWeight.map((elem) => {
                      return (
                        <option value={elem["font-weight"]}>
                          {elem["font-weight"]}
                        </option>
                      );
                    })}
                  </select>
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <h4 className="mb-3">
                  Make change in packagelist-1 | package | cost:before
                </h4>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".packagelist-1 .package .cost:before"
                      ]?.["background-color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".packagelist-1 .package .cost:before"}
                    classProperty={"background-color"}
                    label={"Select Background Color"}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <h4 className="mb-3">
                  Make change in packagelist-1 | package | cost | price
                </h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Size (Default : 35)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="font-size"
                    type="number"
                    placeholder="Font Size"
                    value={settingFormData?.[
                      ".packagelist-1 .package .cost .price"
                    ]?.["font-size"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangePackagesPackageCostPrice(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <h4 className="mb-3">
                  Make change in packagelist-1 | package | description
                </h4>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[".packagelist-1 .package .desq"]?.[
                        "background-color"
                      ]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".packagelist-1 .package .desq"}
                    classProperty={"background-color"}
                    label={"Select Background Color"}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Padding (Default : 10 10 20)"}
                  </Label>
                  <div className="d-flex justify-content-between">
                    <Input
                      className="form-control"
                      name="p1"
                      type="number"
                      placeholder="Padding"
                      value={settingFormData?.[
                        ".packagelist-1 .package .desq"
                      ]?.["p1"]?.replace(/px/g, "")}
                      onChange={(e) => handleChangePackagesPackageDesq(e)}
                      style={{ borderRadius: "0", color: "black" }}
                    />
                    <Input
                      className="form-control"
                      name="p2"
                      type="number"
                      placeholder="Padding"
                      value={settingFormData?.[
                        ".packagelist-1 .package .desq"
                      ]?.["p2"]?.replace(/px/g, "")}
                      onChange={(e) => handleChangePackagesPackageDesq(e)}
                      style={{ borderRadius: "0", color: "black" }}
                    />
                    <Input
                      className="form-control"
                      name="p3"
                      type="number"
                      placeholder="Padding"
                      value={settingFormData?.[
                        ".packagelist-1 .package .desq"
                      ]?.["p3"]?.replace(/px/g, "")}
                      onChange={(e) => handleChangePackagesPackageDesq(e)}
                      style={{ borderRadius: "0", color: "black" }}
                    />
                  </div>
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <h4 className="mb-3">
                  Make change in packagelist-1 | package | description list
                </h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Size (Default : 14)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="font-size"
                    type="number"
                    placeholder="Font Size"
                    value={settingFormData?.[
                      ".packagelist-1 .package .desq li"
                    ]?.["font-size"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangePackagesPackageDesqLi(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Weight (Default : 400)"}
                  </Label>
                  <select
                    name="font-weight"
                    id="font-weight"
                    className="form-select"
                    value={
                      settingFormData?.[".packagelist-1 .package .desq li"]?.[
                        "font-weight"
                      ]
                    }
                    onChange={(e) => handleChangePackagesPackageDesqLi(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value="">--select--</option>
                    {fontWeight.map((elem) => {
                      return (
                        <option value={elem["font-weight"]}>
                          {elem["font-weight"]}
                        </option>
                      );
                    })}
                  </select>
                </Col>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[".packagelist-1 .package .desq li"]?.[
                        "color"
                      ]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".packagelist-1 .package .desq li"}
                    classProperty={"color"}
                    label={"Select Color"}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Margin (Default : 15)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="margin"
                    type="number"
                    placeholder="Margin"
                    value={settingFormData?.[
                      ".packagelist-1 .package .desq li"
                    ]?.["margin"]
                      ?.split(" ")[0]
                      ?.replace(/px/g, "")}
                    onChange={(e) => handleChangePackagesPackageDesqLi(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Padding (Default : 5 - 20)"}
                  </Label>
                  <div className="d-flex justify-content-between">
                    <Input
                      className="form-control"
                      name="p1"
                      type="number"
                      placeholder="Padding"
                      value={settingFormData?.[
                        ".packagelist-1 .package .desq li"
                      ]?.["p1"]?.replace(/px/g, "")}
                      onChange={(e) => handleChangePackagesPackageDesqLi(e)}
                      style={{ borderRadius: "0", color: "black" }}
                    />
                    <Input
                      className="form-control"
                      name="p2"
                      type="number"
                      placeholder="Padding"
                      value={settingFormData?.[
                        ".packagelist-1 .package .desq li"
                      ]?.["p2"]?.replace(/px/g, "")}
                      onChange={(e) => handleChangePackagesPackageDesqLi(e)}
                      style={{ borderRadius: "0", color: "black" }}
                    />
                  </div>
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <h4 className="mb-3">
                  Make change in packagelist-1 | package | description
                  list:before
                </h4>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".packagelist-1 .package .desq li:before"
                      ]?.["color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".packagelist-1 .package .desq li:before"}
                    classProperty={"color"}
                    label={"Select Color"}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <h4 className="mb-3">
                  Make change in packagelist-1 | package | select button
                </h4>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".packages-section .packagelist-1 .package .selectbtn"
                      ]?.["background-color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={
                      ".packages-section .packagelist-1 .package .selectbtn"
                    }
                    classProperty={"background-color"}
                    label={"Select Background Color"}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Padding (Default : 10 10 15)"}
                  </Label>
                  <div className="d-flex justify-content-between">
                    <Input
                      className="form-control"
                      name="p1"
                      type="number"
                      placeholder="Padding"
                      value={settingFormData?.[
                        ".packages-section .packagelist-1 .package .selectbtn"
                      ]?.["p1"]?.replace(/px/g, "")}
                      onChange={(e) => handleChangePackagesPackageSelectBtn(e)}
                      style={{ borderRadius: "0", color: "black" }}
                    />
                    <Input
                      className="form-control"
                      name="p2"
                      type="number"
                      placeholder="Padding"
                      value={settingFormData?.[
                        ".packages-section .packagelist-1 .package .selectbtn"
                      ]?.["p2"]?.replace(/px/g, "")}
                      onChange={(e) => handleChangePackagesPackageSelectBtn(e)}
                      style={{ borderRadius: "0", color: "black" }}
                    />
                    <Input
                      className="form-control"
                      name="p3"
                      type="number"
                      placeholder="Padding"
                      value={settingFormData?.[
                        ".packages-section .packagelist-1 .package .selectbtn"
                      ]?.["p3"]?.replace(/px/g, "")}
                      onChange={(e) => handleChangePackagesPackageSelectBtn(e)}
                      style={{ borderRadius: "0", color: "black" }}
                    />
                  </div>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Size (Default : 16)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="font-size"
                    type="number"
                    placeholder="Font Size"
                    value={settingFormData?.[
                      ".packages-section .packagelist-1 .package .selectbtn"
                    ]?.["font-size"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangePackagesPackageSelectBtn(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".packages-section .packagelist-1 .package .selectbtn"
                      ]?.["color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={
                      ".packages-section .packagelist-1 .package .selectbtn"
                    }
                    classProperty={"color"}
                    label={"Select Color"}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Weight (Default : 400)"}
                  </Label>
                  <select
                    name="font-weight"
                    id="font-weight"
                    className="form-select"
                    value={
                      settingFormData?.[
                        ".packages-section .packagelist-1 .package .selectbtn"
                      ]?.["font-weight"]
                    }
                    onChange={(e) => handleChangePackagesPackageSelectBtn(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value="">--select--</option>
                    {fontWeight.map((elem) => {
                      return (
                        <option value={elem["font-weight"]}>
                          {elem["font-weight"]}
                        </option>
                      );
                    })}
                  </select>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Text Transform (Default : uppercase)"}
                  </Label>
                  <select
                    name="text-transform"
                    id="text-transform"
                    className="form-control"
                    value={
                      settingFormData?.[
                        ".packages-section .packagelist-1 .package .selectbtn"
                      ]?.["text-transform"]
                    }
                    onChange={(e) => handleChangePackagesPackageSelectBtn(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value="">--select--</option>
                    {TextTransform.map((elem) => {
                      return (
                        <option
                          value={elem["text-transform"]}
                          key={elem["text-transform"]}
                        >
                          {elem["text-transform"]}
                        </option>
                      );
                    })}
                  </select>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Text Align (Default : center)"}
                  </Label>
                  <select
                    name="text-align"
                    id="text-align"
                    className="form-select"
                    value={
                      settingFormData?.[
                        ".packages-section .packagelist-1 .package .selectbtn"
                      ]?.["text-align"]
                    }
                    onChange={(e) => handleChangePackagesPackageSelectBtn(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value="">--select--</option>
                    {TextAlign.map((elem) => {
                      return (
                        <option value={elem["text-align"]}>
                          {elem["text-align"]}
                        </option>
                      );
                    })}
                  </select>
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <h4 className="mb-3">
                  make change in packagelist-1 | package | select button:before
                </h4>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".packagelist-1 .package .selectbtn:before"
                      ]?.["background-color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".packagelist-1 .package .selectbtn:before"}
                    classProperty={"background-color"}
                    label={"Select Background Color"}
                  />
                </Col>
              </Row>
              <hr />
              <h4 className="mb-3">This is for Theme 2</h4>
              <Row className="mt-2">
                <h4 className="mb-3">Make change in packagelist-2 | package</h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Border (Default : 1 solid black)"}
                  </Label>
                  <div className="d-flex ">
                    <div style={{ width: "100px" }}>
                      <Input
                        className="form-control"
                        name="borderPx"
                        type="number"
                        placeholder="px"
                        value={settingFormData?.[".packagelist-2 .package"]?.[
                          "borderPx"
                        ]?.replace(/px/g, "")}
                        onChange={(e) => handleChangePackagesPackage2(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </div>
                    <div style={{ width: "100px" }}>
                      <select
                        name="borderThik"
                        id="borderThik"
                        className="form-control"
                        value={
                          settingFormData?.[".packagelist-2 .package"]?.[
                            "borderThik"
                          ]
                        }
                        onChange={(e) => handleChangePackagesPackage2(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      >
                        <option value="">--select--</option>
                        {Border.map((elem) => {
                          return (
                            <option value={elem.border}>{elem.border}</option>
                          );
                        })}
                      </select>
                    </div>
                    <div>
                      <CommonColor
                        color={
                          settingFormData?.[".packagelist-2 .package"]?.[
                            "borderColor"
                          ]
                        }
                        setSettingFormData={setSettingFormData}
                        className={".packagelist-2 .package"}
                        classProperty={"borderColor"}
                        label={"Select Color"}
                      />
                    </div>
                  </div>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Border Radius (Default : 5)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="border-radius"
                    type="number"
                    placeholder="Border Radius"
                    value={settingFormData?.[".packagelist-2 .package"]?.[
                      "border-radius"
                    ]?.replace(/px/g, "")}
                    onChange={(e) => handleChangePackagesPackage2(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <h4 className="mb-3">
                  Make change in packagelist-2 | package | title
                </h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Padding (Default : 15)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="padding"
                    type="number"
                    placeholder="Padding"
                    value={settingFormData?.[
                      ".packages-section .packagelist-2 .package .title"
                    ]?.["padding"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangePackages2Title(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".packages-section .packagelist-2 .package .title"
                      ]?.["color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={
                      ".packages-section .packagelist-2 .package .title"
                    }
                    classProperty={"color"}
                    label={"Select Color"}
                  />
                </Col>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".packages-section .packagelist-2 .package .title"
                      ]?.["background-color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={
                      ".packages-section .packagelist-2 .package .title"
                    }
                    classProperty={"background-color"}
                    label={"Select Background Color"}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Size (Default : 20)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="font-size"
                    type="number"
                    placeholder="Font Size"
                    value={settingFormData?.[
                      ".packages-section .packagelist-2 .package .title"
                    ]?.["font-size"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangePackages2Title(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Weight (Default : 500)"}
                  </Label>
                  <select
                    name="font-weight"
                    id="font-weight"
                    className="form-control"
                    value={
                      settingFormData?.[
                        ".packages-section .packagelist-2 .package .title"
                      ]?.["font-weight"]
                    }
                    onChange={(e) => handleChangePackages2Title(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value="">--select--</option>
                    {fontWeight.map((elem) => {
                      return (
                        <option value={elem["font-weight"]}>
                          {elem["font-weight"]}
                        </option>
                      );
                    })}
                  </select>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Text Align (Default : center)"}
                  </Label>
                  <select
                    name="text-align"
                    id="text-align"
                    className="form-control"
                    value={
                      settingFormData?.[
                        ".packages-section .packagelist-2 .package .title"
                      ]?.["text-align"]
                    }
                    onChange={(e) => handleChangePackages2Title(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value="">--select--</option>
                    {TextAlign.map((elem) => {
                      return (
                        <option value={elem["text-align"]}>
                          {elem["text-align"]}
                        </option>
                      );
                    })}
                  </select>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Text Transform (Default : uppercase)"}
                  </Label>
                  <select
                    name="text-transform"
                    id="text-transform"
                    className="form-control"
                    value={
                      settingFormData?.[
                        ".packages-section .packagelist-2 .package .title"
                      ]?.["text-transform"]
                    }
                    onChange={(e) => handleChangePackages2Title(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value="">--select--</option>
                    {TextTransform.map((elem) => {
                      return (
                        <option
                          value={elem["text-transform"]}
                          key={elem["text-transform"]}
                        >
                          {elem["text-transform"]}
                        </option>
                      );
                    })}
                  </select>
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <h4 className="mb-3">
                  Make change in packagelist-2 | package | cost
                </h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Padding (Default : 20 - 10)"}
                  </Label>
                  <div className="d-flex justify-content-between">
                    <Input
                      className="form-control"
                      name="p1"
                      type="number"
                      placeholder="Padding"
                      value={settingFormData?.[
                        ".packagelist-2 .package .cost"
                      ]?.["p1"]?.replace(/px/g, "")}
                      onChange={(e) => handleChangePackages2Cost(e)}
                      style={{ borderRadius: "0", color: "black" }}
                    />
                    <Input
                      className="form-control"
                      name="p2"
                      type="number"
                      placeholder="Padding"
                      value={settingFormData?.[
                        ".packagelist-2 .package .cost"
                      ]?.["p2"]?.replace(/px/g, "")}
                      onChange={(e) => handleChangePackages2Cost(e)}
                      style={{ borderRadius: "0", color: "black" }}
                    />
                  </div>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Text Align (Default : center)"}
                  </Label>
                  <select
                    name="text-align"
                    id="text-align"
                    className="form-control"
                    value={
                      settingFormData?.[".packagelist-2 .package .cost"]?.[
                        "text-align"
                      ]
                    }
                    onChange={(e) => handleChangePackages2Cost(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value="">--select--</option>
                    {TextAlign.map((elem) => {
                      return (
                        <option value={elem["text-align"]}>
                          {elem["text-align"]}
                        </option>
                      );
                    })}
                  </select>
                </Col>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[".packagelist-2 .package .cost"]?.[
                        "background-color"
                      ]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".packagelist-2 .package .cost"}
                    classProperty={"background-color"}
                    label={"Select Background Color"}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <h4 className="mb-3">
                  Make change in packagelist-2 | package | cost text
                </h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Border (Default : 2 solid black)"}
                  </Label>
                  <div className="d-flex ">
                    <div style={{ width: "100px" }}>
                      <Input
                        className="form-control"
                        name="borderPx"
                        type="number"
                        placeholder="px"
                        value={settingFormData?.[
                          ".packagelist-2 .package .cost span"
                        ]?.["borderPx"]?.replace(/px/g, "")}
                        onChange={(e) => handleChangePackages2CostSpan(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </div>
                    <div style={{ width: "100px" }}>
                      <select
                        name="borderThik"
                        id="borderThik"
                        className="form-control"
                        value={
                          settingFormData?.[
                            ".packagelist-2 .package .cost span"
                          ]?.["borderThik"]
                        }
                        onChange={(e) => handleChangePackages2CostSpan(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      >
                        <option value="">--select--</option>
                        {Border.map((elem) => {
                          return (
                            <option value={elem.border}>{elem.border}</option>
                          );
                        })}
                      </select>
                    </div>
                    <div>
                      <CommonColor
                        color={
                          settingFormData?.[
                            ".packagelist-2 .package .cost span"
                          ]?.["borderColor"]
                        }
                        setSettingFormData={setSettingFormData}
                        className={".packagelist-2 .package .cost span"}
                        classProperty={"borderColor"}
                        label={"Select Color"}
                      />
                    </div>
                  </div>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Border Radius (Default : 50)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="border-radius"
                    type="number"
                    placeholder="Border Radius"
                    value={settingFormData?.[
                      ".packagelist-2 .package .cost span"
                    ]?.["border-radius"]?.replace(/%/g, "")}
                    onChange={(e) => handleChangePackages2CostSpan(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Text Align (Default : center)"}
                  </Label>
                  <select
                    name="text-align"
                    id="text-align"
                    className="form-control"
                    value={settingFormData?.[
                      ".packagelist-2 .package .cost span"
                    ]?.["text-align"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangePackages2CostSpan(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value="">--select--</option>
                    {TextAlign.map((elem) => {
                      return (
                        <option value={elem["text-align"]}>
                          {elem["text-align"]}
                        </option>
                      );
                    })}
                  </select>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Line Height (Default : 70)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="line-height"
                    type="number"
                    placeholder="Line Height"
                    value={settingFormData?.[
                      ".packagelist-2 .package .cost span"
                    ]?.["line-height"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangePackages2CostSpan(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Display (Default : inline-block)"}
                  </Label>
                  <select
                    name="display"
                    id="display"
                    className="form-control"
                    value={
                      settingFormData?.[".packagelist-2 .package .cost span"]?.[
                        "display"
                      ]
                    }
                    onChange={(e) => handleChangePackages2CostSpan(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value="">--select--</option>
                    {Display.map((elem) => {
                      return (
                        <option value={elem["display"]} key={elem["display"]}>
                          {elem["display"]}
                        </option>
                      );
                    })}
                  </select>
                </Col>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[".packagelist-2 .package .cost span"]?.[
                        "color"
                      ]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".packagelist-2 .package .cost span"}
                    classProperty={"color"}
                    label={"Select Color"}
                  />
                </Col>
                <Col md="6 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Box Shadow (Default : 2 2 1 black)"}
                  </Label>
                  <div className="d-flex">
                    <div style={{ width: "100px" }}>
                      <Input
                        className="form-control"
                        name="bs1"
                        type="number"
                        placeholder="px"
                        value={settingFormData?.[
                          ".packagelist-2 .package .cost span"
                        ]?.["bs1"]?.replace(/px/g, "")}
                        onChange={(e) => handleChangePackages2CostSpan(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </div>
                    <div style={{ width: "100px" }}>
                      <Input
                        className="form-control"
                        name="bs2"
                        type="number"
                        placeholder="px"
                        value={settingFormData?.[
                          ".packagelist-2 .package .cost span"
                        ]?.["bs2"]?.replace(/px/g, "")}
                        onChange={(e) => handleChangePackages2CostSpan(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </div>
                    <div style={{ width: "100px" }}>
                      <Input
                        className="form-control"
                        name="bs3"
                        type="number"
                        placeholder="px"
                        value={settingFormData?.[
                          ".packagelist-2 .package .cost span"
                        ]?.["bs3"]?.replace(/px/g, "")}
                        onChange={(e) => handleChangePackages2CostSpan(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </div>
                    <div>
                      <CommonColor
                        color={
                          settingFormData?.[
                            ".packagelist-2 .package .cost span"
                          ]?.["shadowColor"]
                        }
                        setSettingFormData={setSettingFormData}
                        className={".packagelist-2 .package .cost span"}
                        classProperty={"shadowColor"}
                        label={"Select Color"}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <h4 className="mb-3">
                  Make change in packagelist-2 | package | subtitle
                </h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Padding (Default : 15 - 10)"}
                  </Label>
                  <div className="d-flex justify-content-between">
                    <Input
                      className="form-control"
                      name="p1"
                      type="number"
                      placeholder="Padding"
                      value={settingFormData?.[
                        ".packagelist-2 .package .subtitle"
                      ]?.["p1"]?.replace(/px/g, "")}
                      onChange={(e) => handleChangePackages2Subtitle(e)}
                      style={{ borderRadius: "0", color: "black" }}
                    />
                    <Input
                      className="form-control"
                      name="p2"
                      type="number"
                      placeholder="Padding"
                      value={settingFormData?.[
                        ".packagelist-2 .package .subtitle"
                      ]?.["p2"]?.replace(/px/g, "")}
                      onChange={(e) => handleChangePackages2Subtitle(e)}
                      style={{ borderRadius: "0", color: "black" }}
                    />
                  </div>
                </Col>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[".packagelist-2 .package .subtitle"]?.[
                        "color"
                      ]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".packagelist-2 .package .subtitle"}
                    classProperty={"color"}
                    label={"Select Color"}
                  />
                </Col>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[".packagelist-2 .package .subtitle"]?.[
                        "background-color"
                      ]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".packagelist-2 .package .subtitle"}
                    classProperty={"background-color"}
                    label={"Select Background Color"}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Text Align (Default : center)"}
                  </Label>
                  <select
                    name="text-align"
                    id="text-align"
                    className="form-control"
                    value={
                      settingFormData?.[".packagelist-2 .package .subtitle"]?.[
                        "text-align"
                      ]
                    }
                    onChange={(e) => handleChangePackages2Subtitle(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value="">--select--</option>
                    {TextAlign.map((elem) => {
                      return (
                        <option value={elem["text-align"]}>
                          {elem["text-align"]}
                        </option>
                      );
                    })}
                  </select>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Weight (Default : 400)"}
                  </Label>
                  <select
                    name="font-weight"
                    id="font-weight"
                    className="form-control"
                    value={
                      settingFormData?.[".packagelist-2 .package .subtitle"]?.[
                        "font-weight"
                      ]
                    }
                    onChange={(e) => handleChangePackages2Subtitle(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value="">--select--</option>
                    {fontWeight.map((elem) => {
                      return (
                        <option value={elem["font-weight"]}>
                          {elem["font-weight"]}
                        </option>
                      );
                    })}
                  </select>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Size (Default : 14)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="font-size"
                    type="number"
                    placeholder="Font Size"
                    value={settingFormData?.[
                      ".packagelist-2 .package .subtitle"
                    ]?.["font-size"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangePackages2Subtitle(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <h4 className="mb-3">
                  Make change in packagelist-2 | package | description
                </h4>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[".packagelist-2 .package .desq"]?.[
                        "background-color"
                      ]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".packagelist-2 .package .desq"}
                    classProperty={"background-color"}
                    label={"Select Background Color"}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Padding (Default : 13)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="padding"
                    type="number"
                    placeholder="padding"
                    value={settingFormData?.[".packagelist-2 .package .desq"]?.[
                      "padding"
                    ]?.replace(/px/g, "")}
                    onChange={(e) => handleChangePackages2Desq(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <h4 className="mb-3">
                  Make change in packagelist-2 | package | description list
                </h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Margin (Default : 5)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="margin"
                    type="number"
                    placeholder="margin"
                    value={settingFormData?.[
                      ".packagelist-2 .package .desq li"
                    ]?.["margin"]
                      ?.split(" ")[0]
                      ?.replace(/px/g, "")}
                    onChange={(e) => handleChangePackagesList2SDesqLi(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Padding Left (Default : 22)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="padding-left"
                    type="number"
                    placeholder="Padding Left"
                    value={settingFormData?.[
                      ".packagelist-2 .package .desq li"
                    ]?.["padding-left"]
                      ?.split(" ")[0]
                      ?.replace(/px/g, "")}
                    onChange={(e) => handleChangePackagesList2SDesqLi(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Size (Default : 12)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="font-size"
                    type="number"
                    placeholder="Font Size"
                    value={settingFormData?.[
                      ".packagelist-2 .package .desq li"
                    ]?.["font-size"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangePackagesList2SDesqLi(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[".packagelist-2 .package .desq li"]?.[
                        "color"
                      ]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".packagelist-2 .package .desq li"}
                    classProperty={"color"}
                    label={"Select Color"}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <h4 className="mb-3">
                  Make change in packagelist-2 | package | description
                  list:before
                </h4>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".packagelist-2 .package .desq li:before"
                      ]?.["color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".packagelist-2 .package .desq li:before"}
                    classProperty={"color"}
                    label={"Select Color"}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <h4 className="mb-3">
                  Make change in packagelist-2 | package | select button
                </h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Text Align (Default : center)"}
                  </Label>
                  <select
                    name="text-align"
                    id="text-align"
                    className="form-control"
                    value={
                      settingFormData?.[
                        ".packages-section .packagelist-2 .package .selectbtn"
                      ]?.["text-align"]
                    }
                    onChange={(e) => handleChangePackages2SelectBtn(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value="">--select--</option>
                    {TextAlign.map((elem) => {
                      return (
                        <option value={elem["text-align"]}>
                          {elem["text-align"]}
                        </option>
                      );
                    })}
                  </select>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Padding (Default : 10)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="padding"
                    type="number"
                    placeholder="padding"
                    value={settingFormData?.[
                      ".packages-section .packagelist-2 .package .selectbtn"
                    ]?.["padding"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangePackages2SelectBtn(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Display (Default : inline-block)"}
                  </Label>
                  <select
                    name="display"
                    id="display"
                    className="form-control"
                    value={
                      settingFormData?.[
                        ".packages-section .packagelist-2 .package .selectbtn"
                      ]?.["display"]
                    }
                    onChange={(e) => handleChangePackages2SelectBtn(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value="">--select--</option>
                    {Display.map((elem) => {
                      return (
                        <option value={elem["display"]} key={elem["display"]}>
                          {elem["display"]}
                        </option>
                      );
                    })}
                  </select>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Width (Default : 100)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="width"
                    type="number"
                    placeholder="width"
                    value={settingFormData?.[
                      ".packages-section .packagelist-2 .package .selectbtn"
                    ]?.["width"]?.replace(/%/g, "")}
                    onChange={(e) => handleChangePackages2SelectBtn(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".packages-section .packagelist-2 .package .selectbtn"
                      ]?.["background-color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={
                      ".packages-section .packagelist-2 .package .selectbtn"
                    }
                    classProperty={"background-color"}
                    label={"Select Background Color"}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <h4 className="mb-3">
                  Make change in packagelist-2 | package | selectbtn text
                </h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Border (Default : 2 solid black)"}
                  </Label>
                  <div className="d-flex ">
                    <div style={{ width: "100px" }}>
                      <Input
                        className="form-control"
                        name="borderPx"
                        type="number"
                        placeholder="px"
                        value={settingFormData?.[
                          ".packagelist-2 .package .selectbtn a"
                        ]?.["borderPx"]?.replace(/px/g, "")}
                        onChange={(e) => handleChangePackages2SelectBtnA(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </div>
                    <div style={{ width: "100px" }}>
                      <select
                        name="borderThik"
                        id="borderThik"
                        className="form-control"
                        value={
                          settingFormData?.[
                            ".packagelist-2 .package .selectbtn a"
                          ]?.["borderThik"]
                        }
                        onChange={(e) => handleChangePackages2SelectBtnA(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      >
                        <option value="">--select--</option>
                        {Border.map((elem) => {
                          return (
                            <option value={elem.border}>{elem.border}</option>
                          );
                        })}
                      </select>
                    </div>
                    <div>
                      <CommonColor
                        color={
                          settingFormData?.[
                            ".packagelist-2 .package .selectbtn a"
                          ]?.["borderColor"]
                        }
                        setSettingFormData={setSettingFormData}
                        className={".packagelist-2 .package .selectbtn a"}
                        classProperty={"borderColor"}
                        label={"Select Color"}
                      />
                    </div>
                  </div>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Padding (Default : 8 - 25)"}
                  </Label>
                  <div className="d-flex justify-content-between">
                    <Input
                      className="form-control"
                      name="p1"
                      type="number"
                      placeholder="Padding"
                      value={settingFormData?.[
                        ".packagelist-2 .package .selectbtn a"
                      ]?.["p1"]?.replace(/px/g, "")}
                      onChange={(e) => handleChangePackages2SelectBtnA(e)}
                      style={{ borderRadius: "0", color: "black" }}
                    />
                    <Input
                      className="form-control"
                      name="p2"
                      type="number"
                      placeholder="Padding"
                      value={settingFormData?.[
                        ".packagelist-2 .package .selectbtn a"
                      ]?.["p2"]?.replace(/px/g, "")}
                      onChange={(e) => handleChangePackages2SelectBtnA(e)}
                      style={{ borderRadius: "0", color: "black" }}
                    />
                  </div>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Size (Default : 12)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="font-size"
                    type="number"
                    placeholder="Font Size"
                    value={settingFormData?.[
                      ".packagelist-2 .package .selectbtn a"
                    ]?.["font-size"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangePackages2SelectBtnA(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Text Transform (Default : uppercase)"}
                  </Label>
                  <select
                    name="text-transform"
                    id="text-transform"
                    className="form-select"
                    value={
                      settingFormData?.[
                        ".packagelist-2 .package .selectbtn a"
                      ]?.["text-transform"]
                    }
                    onChange={(e) => handleChangePackages2SelectBtnA(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value="">--select--</option>
                    {TextTransform.map((elem) => {
                      return (
                        <option
                          value={elem["text-transform"]}
                          key={elem["text-transform"]}
                        >
                          {elem["text-transform"]}
                        </option>
                      );
                    })}
                  </select>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Letter Spacing (Default : 1)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="letter-spacing"
                    type="number"
                    placeholder="Letter Spacing"
                    value={settingFormData?.[
                      ".packagelist-2 .package .selectbtn a"
                    ]?.["letter-spacing"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangePackages2SelectBtnA(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".packagelist-2 .package .selectbtn a"
                      ]?.["color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".packagelist-2 .package .selectbtn a"}
                    classProperty={"color"}
                    label={"Select Color"}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Border Radius (Default : 3)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="border-radius"
                    type="number"
                    placeholder="Border Radius"
                    value={settingFormData?.[
                      ".packagelist-2 .package .selectbtn a"
                    ]?.["border-radius"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangePackages2SelectBtnA(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <h4 className="mb-3">
                  Make change in packagelist-2 | package | selectbtn text at
                  hover
                </h4>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".packagelist-2 .package .selectbtn a:hover"
                      ]?.["background-color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".packagelist-2 .package .selectbtn a:hover"}
                    classProperty={"background-color"}
                    label={"Select Background Color"}
                  />
                </Col>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".packagelist-2 .package .selectbtn a:hover"
                      ]?.["color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".packagelist-2 .package .selectbtn a:hover"}
                    classProperty={"color"}
                    label={"Select Color"}
                  />
                </Col>
              </Row>
              <PaginationButtonStyle
                mainClass={".packages-section"}
                settingFormData={settingFormData}
                setSettingFormData={setSettingFormData}
              />
              <CustomStyle
                handleChange={handleChangeCustomStyle}
                value={settingFormData?.custom}
              />
            </div>
          </Col>
        </Row>
        <ProductImageUploadPopup
          allPopupState={allPopupState}
          setAllPopupState={setAllPopupState}
          crop={crop}
          setCrop={setCrop}
          completedCrop={completedCrop}
          setCompletedCrop={setCompletedCrop}
          scale={scale}
          rotate={rotate}
          image={settingFormData?.image}
          setImage={setSettingFormData}
          imageUpload={imageUpload}
          isEdit={isEdit}
          reupload={reupload}
          // aspect={2.54 / 1}
        />
      </Container>
    </>
  );
};

export default SettingPackagesParentPopup;
