import React, { useContext } from "react";
import { Button, Col, Container, Input, Label, Row } from "reactstrap";
import {
  Border,
  Float,
  TextAlign,
  fontWeight,
  textDecoration,
} from "../../../../constant";
import { Switch } from "@material-ui/core";
import ProductMultipageContext from "../../../context/ProductMultipageContext/ProductMultipageContext";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import Dividers from "../../../common/divider";
import { OpenModalCommonFunc } from "../../../utils";
import { Cases, DELETE_CONFIRM } from "../../../constant";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { UploadCloud } from "react-feather";
import { delete_Multi_Image_Permanent } from "../apis/SliderApi/SliderApi";
import { Zoom, toast } from "react-toastify";
import ProductImageUploadPopup from "../../../Modals/imageUploade/productImageUploadPopup";
import PaginationButtonStyle from "../../../common/paginationButtonStyle";
import CommonColor from "../../../common/CommonColor";
import CustomStyle from "../../../common/CustomStyle";

const ProductThemeOne = (props) => {
  const {
    isEdit,
    settingFormData,
    setSettingFormData,
    boxSection,
    setBoxSection,
    backImageToggle,
    setBackImageToggle,
    isBackgroundColor,
    setIsBackgroundColor,
  } = useContext(ProductMultipageContext);
  const {
    allPopupState,
    setAllPopupState,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
  } = useContext(ByteCardBuilderContext);
  const { imageUpload, handleSubmitSetting } = props;
  const { setLoaderShow } = useContext(LoaderContext);

  /**
   * input value change
   * @param {event} e
   */

  const handleChangeProductsSection = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".products-section"]: {
          ...prev[".products-section"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeProductsSectionBgImage = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".products-section.bg-image::before"]: {
          ...prev[".products-section.bg-image::before"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeProductSectionTitleArea = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".products-section .section-title-area"]: {
          ...prev[".products-section .section-title-area"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeProductsSectionSubtitle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".products-section .section-title-area .section-subtitle"]: {
          ...prev[".products-section .section-title-area .section-subtitle"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeProductsSectionSubtitleArea = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".products-section .section-title-area .section-title"]: {
          ...prev[".products-section .section-title-area .section-title"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeProductsSectionCatList = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".products-section .product-catlist"]: {
          ...prev[".products-section .product-catlist"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeProductsSectionCatListBtn = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".products-section .product-catlist .btn"]: {
          ...prev[".products-section .product-catlist .btn"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeProductsSectionActive = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".products-section .product-catlist .active"]: {
          ...prev[".products-section .product-catlist .active"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeProductsSectionCatDataBox = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".products-section .product-catdata .box"]: {
          ...prev[".products-section .product-catdata .box"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeProductsSectionCatDataImgTalLabel = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".products-section .product-catdata .product-img .taglabel"]: {
          ...prev[".products-section .product-catdata .product-img .taglabel"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeProductsSectionCatDataImgPostDate = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".products-section .product-catdata .product-img .post-date"]: {
          ...prev[".products-section .product-catdata .product-img .post-date"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeProductsSectionCatDataContent = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".products-section .product-catdata .product-content"]: {
          ...prev[".products-section .product-catdata .product-content"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeProductsSectionCatDataImgPostDateContentShopA = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".products-section .product-catdata .product-content .shop a"]: {
          ...prev[
            ".products-section .product-catdata .product-content .shop a"
          ],
          [name]: value,
        },
      };
    });
  };
  const handleChangeProductsSectionCatDataImgPostDateContentShopAHover = (
    e
  ) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".products-section .product-catdata .product-content .shop a:hover"]: {
          ...prev[
            ".products-section .product-catdata .product-content .shop a:hover"
          ],
          [name]: value,
        },
      };
    });
  };
  const handleChangeProductsSectionCatDataImgPostDateContentPrice = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".products-section .product-catdata .product-content .price"]: {
          ...prev[".products-section .product-catdata .product-content .price"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeProductsSectionCatDataImgPostDateContentPriceDel = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".products-section .product-catdata .product-content .price del"]: {
          ...prev[
            ".products-section .product-catdata .product-content .price del"
          ],
          [name]: value,
        },
      };
    });
  };
  const handleChangeProductsSectionCatDataImgPostDateContentPriceIns = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".products-section .product-catdata .product-content .price ins"]: {
          ...prev[
            ".products-section .product-catdata .product-content .price ins"
          ],
          [name]: value,
        },
      };
    });
  };
  const handleChangeProductsSectionCatDataImgPostDateContentPriceAddCart = (
    e
  ) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".products-section .product-catdata .product-content .add-cart"]: {
          ...prev[
            ".products-section .product-catdata .product-content .add-cart"
          ],
          [name]: value,
        },
      };
    });
  };
  const handleChangeProductsSectionPriceAddCartHover = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".products-section .product-catdata .product-content .add-cart:hover"]:
          {
            ...prev[
              ".products-section .product-catdata .product-content .add-cart:hover"
            ],
            [name]: value,
          },
      };
    });
  };

  const handleChangeCustomStyle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  /**
   * delete image permanently
   */
  const deleteImagePermanent = async () => {
    try {
      let confirmation = window.confirm(DELETE_CONFIRM);
      if (confirmation) {
        setLoaderShow(true);
        let formData = {
          fileName: settingFormData?.[".products-section.bg-image"]?.[
            "background-image"
          ]
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
            .pop(),
        };
        const { data } = await delete_Multi_Image_Permanent(formData);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
            position: "top-center",
          });
          setSettingFormData({
            ...settingFormData,
            ".products-section.bg-image": { "background-image": "" },
          });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setLoaderShow(false);
    }
  };

  /**
   * image reupload
   */
  const reupload = () => {
    setSettingFormData((prev) => {
      return {
        ...prev,
        image: "",
      };
    });
  };
  return (
    <>
      <Row className="mt-4">
        <h4 className="mb-3">Make change in Main section</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding (Default : 100)"}
          </Label>
          <Input
            className="form-control"
            name="padding"
            type="number"
            placeholder="Padding"
            value={settingFormData?.[".products-section"]?.["padding"]
              ?.split(" ")[0]
              ?.replace(/px/g, "")}
            onChange={(e) => handleChangeProductsSection(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <div className="d-flex align-items-center">
            <Label htmlFor="validationCustom03">
              {isBackgroundColor
                ? "Add Background Color"
                : "Remove Background Color"}
            </Label>
            <Switch
              checked={isBackgroundColor}
              inputProps={{ "aria-label": "controlled" }}
              onChange={(e) => {
                setIsBackgroundColor(e.target.checked);
                setSettingFormData((prev) => {
                  if (e.target.checked) {
                    if (boxSection) {
                      return {
                        ...prev,
                        [".products-section"]: {
                          "background-color": "#faf9f6",
                          padding:
                            settingFormData?.[".products-section"]?.["padding"],
                          width: settingFormData?.[".products-section"]?.[
                            "width"
                          ]?.replace("rem)", ""),
                          margin: settingFormData?.[".products-section"]?.[
                            "margin"
                          ]
                            ?.split(" ")[0]
                            ?.replace(/px/g, ""),
                          "border-radius": settingFormData?.[
                            ".products-section"
                          ]?.["border-radius"]?.replace(/rem/g, ""),
                        },
                      };
                    } else {
                      return {
                        ...prev,
                        [".products-section"]: {
                          "background-color": "#faf9f6",
                          padding:
                            settingFormData?.[".products-section"]?.["padding"],
                        },
                      };
                    }
                  } else {
                    if (boxSection) {
                      return {
                        ...prev,
                        [".products-section"]: {
                          padding:
                            settingFormData?.[".products-section"]?.["padding"],
                          width: settingFormData?.[".products-section"]?.[
                            "width"
                          ]?.replace("rem)", ""),
                          margin: settingFormData?.[".products-section"]?.[
                            "margin"
                          ]
                            ?.split(" ")[0]
                            ?.replace(/px/g, ""),
                          "border-radius": settingFormData?.[
                            ".products-section"
                          ]?.["border-radius"]?.replace(/rem/g, ""),
                        },
                      };
                    } else {
                      return {
                        ...prev,
                        [".products-section"]: {
                          padding:
                            settingFormData?.[".products-section"]?.["padding"],
                        },
                      };
                    }
                  }
                });
              }}
            />
          </div>
          {isBackgroundColor && (
            <CommonColor
              color={
                settingFormData?.[".products-section"]?.["background-color"]
              }
              setSettingFormData={setSettingFormData}
              className={".products-section"}
              classProperty={"background-color"}
              label={"Select Background Color"}
            />
          )}
        </Col>
        <p>{"Box Section : convert your section to Box section"}</p>
        <div>
          <Switch
            checked={boxSection}
            inputProps={{ "aria-label": "controlled" }}
            onChange={(e) => {
              setBoxSection(e.target.checked);
              setSettingFormData((prev) => {
                return {
                  ...prev,
                  ".products-section": {
                    padding: settingFormData?.[".products-section"]?.["padding"]
                      ?.split(" ")[0]
                      ?.replace(/px/g, ""),
                    "background-color":
                      settingFormData?.[".products-section"]?.[
                        "background-color"
                      ],
                    width: "10",
                    margin: "0",
                    "border-radius": "3",
                  },
                };
              });
            }}
          />
        </div>
        {boxSection && (
          <>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Width (Default : 10)"}
              </Label>
              <Input
                className="form-control"
                name="width"
                type="number"
                placeholder="width"
                value={settingFormData?.[".products-section"]?.[
                  "width"
                ]?.replace("rem)", "")}
                onChange={(e) => handleChangeProductsSection(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Margin (Default : 0)"}
              </Label>
              <Input
                className="form-control"
                name="margin"
                type="number"
                placeholder="margin"
                value={settingFormData?.[".products-section"]?.["margin"]
                  ?.split(" ")[0]
                  ?.replace(/px/g, "")}
                onChange={(e) => handleChangeProductsSection(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Border Radius (Default : 3)"}
              </Label>
              <Input
                className="form-control"
                name="border-radius"
                type="number"
                placeholder="Border Radius"
                value={settingFormData?.[".products-section"]?.[
                  "border-radius"
                ]?.replace(/rem/g, "")}
                onChange={(e) => handleChangeProductsSection(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
          </>
        )}
      </Row>
      <hr />
      <Row className="mt-4">
        <Col md="12 mb-3">
          <h4 className="mb-3">Add Background Image in section</h4>

          <p>do you want to upload Background image</p>
          <Switch
            checked={backImageToggle}
            inputProps={{ "aria-label": "controlled" }}
            onChange={(e) => {
              setBackImageToggle(e.target.checked);
              setSettingFormData((prev) => {
                if (e.target.checked) {
                  return {
                    ...prev,
                    image: "",
                    ".products-section.bg-image": {},
                    ".products-section.bg-image::before": {
                      "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
                    },
                  };
                } else {
                  return {
                    ...prev,
                    image: "",
                    ".products-section.bg-image": {},
                    ".products-section.bg-image::before": {},
                  };
                }
              });
            }}
          />
          {backImageToggle && (
            <>
              {" "}
              <Col md="6 mb-3">
                <Dividers divide="IMAGE" />
                <p className="mb-4">
                  File type required JPG, PNG (1905px X 1319px)
                </p>
              </Col>
              <Row>
                <Col md="4 mb-3">
                  <div
                    className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center"
                    style={{ cursor: "pointer" }}
                  >
                    {!settingFormData?.[".products-section.bg-image"]?.[
                      "background-image"
                    ] && (
                      <span
                        onClick={() =>
                          OpenModalCommonFunc(
                            Cases.PRODUCT_UPLOAD,
                            setAllPopupState
                          )
                        }
                      >
                        <UploadCloud />
                      </span>
                    )}

                    {settingFormData?.[".products-section.bg-image"]?.[
                      "background-image"
                    ] && (
                      <img
                        src={
                          settingFormData?.[".products-section.bg-image"]?.[
                            "background-image"
                          ] || ""
                        }
                        alt=""
                        style={{
                          width: "85%",
                          height: "90px",
                          objectFit: "contain",
                          position: "absolute",
                        }}
                        onClick={() => deleteImagePermanent()}
                      />
                    )}
                    {settingFormData?.[".products-section.bg-image"]?.[
                      "background-image"
                    ] && (
                      <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="close"
                        style={{
                          color: "red",
                          position: "absolute",
                          top: "-22px",
                          left: "1px",
                        }}
                        onClick={() => deleteImagePermanent()}
                      >
                        <Close
                          style={{
                            fontSize: "25px",
                            fontWeight: "bold",
                          }}
                        />
                      </IconButton>
                    )}
                  </div>
                </Col>
                <Col md="6 mb-3">
                  <h4 className="mb-3">Add Background color in section</h4>
                  <Col md="6 mb-3">
                    <CommonColor
                      color={
                        settingFormData?.[
                          ".products-section.bg-image::before"
                        ]?.["background-color"]
                      }
                      setSettingFormData={setSettingFormData}
                      className={".products-section.bg-image::before"}
                      classProperty={"background-color"}
                      label={"Select Background Color"}
                    />
                  </Col>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make space in Title bottom</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin bottom (Default : 50)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="Margin bottom"
            value={settingFormData?.[".products-section .section-title-area"]?.[
              "margin-bottom"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeProductSectionTitleArea(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in section subtitle</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 16)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[
              ".products-section .section-title-area .section-subtitle"
            ]?.["font-size"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeProductsSectionSubtitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 600)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-select"
            value={
              settingFormData?.[
                ".products-section .section-title-area .section-subtitle"
              ]?.["font-weight"]
            }
            onChange={(e) => handleChangeProductsSectionSubtitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".products-section .section-title-area .section-subtitle"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={
              ".products-section .section-title-area .section-subtitle"
            }
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".products-section .section-title-area .section-subtitle"
              ]?.["background-color"]
            }
            setSettingFormData={setSettingFormData}
            className={
              ".products-section .section-title-area .section-subtitle"
            }
            classProperty={"background-color"}
            label={"Select background Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding (Default : 5 - 20)"}
          </Label>
          <div className="d-flex justify-content-between">
            <Input
              className="form-control"
              name="p1"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".products-section .section-title-area .section-subtitle"
              ]?.["p1"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeProductsSectionSubtitle(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="p2"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".products-section .section-title-area .section-subtitle"
              ]?.["p2"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeProductsSectionSubtitle(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </div>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border Radius (Default : 15)"}
          </Label>
          <Input
            className="form-control"
            name="border-radius"
            type="number"
            placeholder="Border Radius"
            value={settingFormData?.[
              ".products-section .section-title-area .section-subtitle"
            ]?.["border-radius"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeProductsSectionSubtitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin bottom (Default : 15)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="Margin bottom"
            value={settingFormData?.[
              ".products-section .section-title-area .section-subtitle"
            ]?.["margin-bottom"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeProductsSectionSubtitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in section Title</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 44)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[
              ".products-section .section-title-area .section-title"
            ]?.["font-size"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeProductsSectionSubtitleArea(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 600)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-select"
            value={
              settingFormData?.[
                ".products-section .section-title-area .section-title"
              ]?.["font-weight"]
            }
            onChange={(e) => handleChangeProductsSectionSubtitleArea(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".products-section .section-title-area .section-title"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={".products-section .section-title-area .section-title"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in products section category list</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Text Align (Default : center)"}
          </Label>
          <select
            name="text-align"
            id="text-align"
            className="form-select"
            value={
              settingFormData?.[".products-section .product-catlist"]?.[
                "text-align"
              ]
            }
            onChange={(e) => handleChangeProductsSectionCatList(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {TextAlign.map((elem) => {
              return (
                <option value={elem["text-align"]}>{elem["text-align"]}</option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin bottom (Default : 30)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="Margin bottom"
            value={settingFormData?.[".products-section .product-catlist"]?.[
              "margin-bottom"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeProductsSectionCatList(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">
          Make change in products section category list button
        </h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Margin (Default : 5)"}</Label>
          <Input
            className="form-control"
            name="margin"
            type="number"
            placeholder="Margin"
            value={settingFormData?.[
              ".products-section .product-catlist .btn"
            ]?.["margin"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeProductsSectionCatListBtn(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border (Default : 2 solid pink)"}
          </Label>
          <div className="d-flex">
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="borderPx"
                type="number"
                placeholder="px"
                value={settingFormData?.[
                  ".products-section .product-catlist .btn"
                ]?.["borderPx"]?.replace(/px/g, "")}
                onChange={(e) => handleChangeProductsSectionCatListBtn(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div style={{ width: "100px" }}>
              <select
                name="borderThik"
                id="borderThik"
                className="form-control"
                value={
                  settingFormData?.[
                    ".products-section .product-catlist .btn"
                  ]?.["borderThik"]
                }
                onChange={(e) => handleChangeProductsSectionCatListBtn(e)}
                style={{ borderRadius: "0", color: "black" }}
              >
                <option value="">--select--</option>
                {Border.map((elem) => {
                  return <option value={elem.border}>{elem.border}</option>;
                })}
              </select>
            </div>
            <div>
              <CommonColor
                color={
                  settingFormData?.[
                    ".products-section .product-catlist .btn"
                  ]?.["borderColor"]
                }
                setSettingFormData={setSettingFormData}
                className={".products-section .product-catlist .btn"}
                classProperty={"borderColor"}
                label={"Select Color"}
              />
            </div>
          </div>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[".products-section .product-catlist .btn"]?.[
                "color"
              ]
            }
            setSettingFormData={setSettingFormData}
            className={".products-section .product-catlist .btn"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding (Default : 6 - 20)"}
          </Label>
          <div className="d-flex justify-content-between">
            <Input
              className="form-control"
              name="p1"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".products-section .product-catlist .btn"
              ]?.["p1"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeProductsSectionSubtitleArea(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="p2"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".products-section .product-catlist .btn"
              ]?.["p2"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeProductsSectionSubtitleArea(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </div>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Margin (Default : 5)"}</Label>
          <Input
            className="form-control"
            name="margin"
            type="number"
            placeholder="margin"
            value={settingFormData?.[
              ".products-section .product-catlist .btn"
            ]?.["margin"]
              ?.split(" ")[0]
              ?.replace(/px/g, "")}
            onChange={(e) => handleChangeProductsSectionSubtitleArea(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">
          Make change in products section category list : active
        </h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[".products-section .product-catlist .active"]?.[
                "background-color"
              ]
            }
            setSettingFormData={setSettingFormData}
            className={".products-section .product-catlist .active"}
            classProperty={"background-color"}
            label={"Select Background Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[".products-section .product-catlist .active"]?.[
                "color"
              ]
            }
            setSettingFormData={setSettingFormData}
            className={".products-section .product-catlist .active"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">
          Make change in products section category data box
        </h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin bottom (Default : 15)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="Margin bottom"
            value={settingFormData?.[
              ".products-section .product-catdata .box"
            ]?.["margin-bottom"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeProductsSectionCatDataBox(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[".products-section .product-catdata .box"]?.[
                "background-color"
              ]
            }
            setSettingFormData={setSettingFormData}
            className={".products-section .product-catdata .box"}
            classProperty={"background-color"}
            label={"Select Background Color"}
          />
        </Col>
        <Col md="6 mb-3">
          <Label htmlFor="validationCustom03">
            {"Box Shadow 0 5 10 0 black"}
          </Label>
          <div className="d-flex">
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="bs1"
                type="number"
                placeholder="px"
                value={settingFormData?.[
                  ".products-section .product-catdata .box"
                ]?.["bs1"]?.replace(/px/g, "")}
                onChange={(e) => handleChangeProductsSectionCatDataBox(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="bs2"
                type="number"
                placeholder="px"
                value={settingFormData?.[
                  ".products-section .product-catdata .box"
                ]?.["bs2"]?.replace(/px/g, "")}
                onChange={(e) => handleChangeProductsSectionCatDataBox(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="bs3"
                type="number"
                placeholder="px"
                value={settingFormData?.[
                  ".products-section .product-catdata .box"
                ]?.["bs3"]?.replace(/px/g, "")}
                onChange={(e) => handleChangeProductsSectionCatDataBox(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="bs4"
                type="number"
                placeholder="px"
                value={settingFormData?.[
                  ".products-section .product-catdata .box"
                ]?.["bs4"]?.replace(/px/g, "")}
                onChange={(e) => handleChangeProductsSectionCatDataBox(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div>
              <CommonColor
                color={
                  settingFormData?.[
                    ".products-section .product-catdata .box"
                  ]?.["shedowColor"]
                }
                setSettingFormData={setSettingFormData}
                className={".products-section .product-catdata .box"}
                classProperty={"shedowColor"}
                label={"Select Color"}
              />
            </div>
          </div>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border radius (Default : 0)"}
          </Label>
          <Input
            className="form-control"
            name="border-radius"
            type="number"
            placeholder="border-radius"
            value={settingFormData?.[
              ".products-section .product-catdata .box"
            ]?.["border-radius"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeProductsSectionCatDataBox(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in product box image tag label</h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".products-section .product-catdata .product-img .taglabel"
              ]?.["background-color"]
            }
            setSettingFormData={setSettingFormData}
            className={
              ".products-section .product-catdata .product-img .taglabel"
            }
            classProperty={"background-color"}
            label={"Select Background Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".products-section .product-catdata .product-img .taglabel"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={
              ".products-section .product-catdata .product-img .taglabel"
            }
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 14)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[
              ".products-section .product-catdata .product-img .taglabel"
            ]?.["font-size"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeProductsSectionCatDataImgTalLabel(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 400)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-select"
            value={
              settingFormData?.[
                ".products-section .product-catdata .product-img .taglabel"
              ]?.["font-weight"]
            }
            onChange={(e) => handleChangeProductsSectionCatDataImgTalLabel(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding (Default : 8 - 15)"}
          </Label>
          <div className="d-flex justify-content-between">
            <Input
              className="form-control"
              name="p1"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".products-section .product-catdata .product-img .taglabel"
              ]?.["p1"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeProductsSectionCatDataImgTalLabel(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="p2"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".products-section .product-catdata .product-img .taglabel"
              ]?.["p2"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeProductsSectionCatDataImgTalLabel(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </div>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border Radius (Default : 5)"}
          </Label>
          <Input
            className="form-control"
            name="border-radius"
            type="number"
            placeholder="Border Radius"
            value={settingFormData?.[
              ".products-section .product-catdata .product-img .taglabel"
            ]?.["border-radius"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeProductsSectionCatDataImgTalLabel(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Top (Default : 10)"}</Label>
          <Input
            className="form-control"
            name="top"
            type="number"
            placeholder="top"
            value={settingFormData?.[
              ".products-section .product-catdata .product-img .taglabel"
            ]?.["top"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeProductsSectionCatDataImgTalLabel(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Left (Default : 10)"}</Label>
          <Input
            className="form-control"
            name="left"
            type="number"
            placeholder="left"
            value={settingFormData?.[
              ".products-section .product-catdata .product-img .taglabel"
            ]?.["left"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeProductsSectionCatDataImgTalLabel(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in product box post date</h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".products-section .product-catdata .product-img .post-date"
              ]?.["background-color"]
            }
            setSettingFormData={setSettingFormData}
            className={
              ".products-section .product-catdata .product-img .post-date"
            }
            classProperty={"background-color"}
            label={"Select background Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border Radius (Default : 5)"}
          </Label>
          <Input
            className="form-control"
            name="border-radius"
            type="number"
            placeholder="Border Radius"
            value={settingFormData?.[
              ".products-section .product-catdata .product-img .post-date"
            ]?.["border-radius"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeProductsSectionCatDataImgPostDate(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".products-section .product-catdata .product-img .post-date"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={
              ".products-section .product-catdata .product-img .post-date"
            }
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 14)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[
              ".products-section .product-catdata .product-img .post-date"
            ]?.["font-size"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeProductsSectionCatDataImgPostDate(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 400)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-select"
            value={
              settingFormData?.[
                ".products-section .product-catdata .product-img .post-date"
              ]?.["font-weight"]
            }
            onChange={(e) => handleChangeProductsSectionCatDataImgPostDate(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding (Default : 8 - 15)"}
          </Label>
          <div className="d-flex justify-content-between">
            <Input
              className="form-control"
              name="p1"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".products-section .product-catdata .product-img .post-date"
              ]?.["p1"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeProductsSectionCatDataImgPostDate(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="p2"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".products-section .product-catdata .product-img .post-date"
              ]?.["p2"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeProductsSectionCatDataImgPostDate(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </div>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"text-align (Default : center)"}
          </Label>
          <select
            name="text-align"
            id="text-align"
            className="form-select"
            value={
              settingFormData?.[
                ".products-section .product-catdata .product-img .post-date"
              ]?.["text-align"]
            }
            onChange={(e) => handleChangeProductsSectionCatDataImgPostDate(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {TextAlign.map((elem) => {
              return (
                <option value={elem["text-align"]}>{elem["text-align"]}</option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Top (Default : 10)"}</Label>
          <Input
            className="form-control"
            name="top"
            type="number"
            placeholder="top"
            value={settingFormData?.[
              ".products-section .product-catdata .product-img .post-date"
            ]?.["top"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeProductsSectionCatDataImgPostDate(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Right (Default : 10)"}</Label>
          <Input
            className="form-control"
            name="right"
            type="number"
            placeholder="right"
            value={settingFormData?.[
              ".products-section .product-catdata .product-img .post-date"
            ]?.["right"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeProductsSectionCatDataImgPostDate(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in product box content</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Padding (Default : 20)"}</Label>
          <Input
            className="form-control"
            name="padding"
            type="number"
            placeholder="padding"
            value={settingFormData?.[
              ".products-section .product-catdata .product-content"
            ]?.["padding"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeProductsSectionCatDataContent(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"width (Default : 100)"}</Label>
          <Input
            className="form-control"
            name="width"
            type="number"
            placeholder="width"
            value={settingFormData?.[
              ".products-section .product-catdata .product-content"
            ]?.["width"]?.replace(/%/g, "")}
            onChange={(e) => handleChangeProductsSectionCatDataContent(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Float (Default : left)"}</Label>
          <select
            name="float"
            id="float"
            className="form-select"
            value={
              settingFormData?.[
                ".products-section .product-catdata .product-content"
              ]?.["float"]
            }
            onChange={(e) => handleChangeProductsSectionCatDataContent(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {Float.map((elem) => {
              return <option value={elem["float"]}>{elem["float"]}</option>;
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"text-align (Default : center)"}
          </Label>
          <select
            name="text-align"
            id="text-align"
            className="form-select"
            value={
              settingFormData?.[
                ".products-section .product-catdata .product-content"
              ]?.["text-align"]
            }
            onChange={(e) => handleChangeProductsSectionCatDataContent(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {TextAlign.map((elem) => {
              return (
                <option value={elem["text-align"]}>{elem["text-align"]}</option>
              );
            })}
          </select>
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in product box content shop link</h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".products-section .product-catdata .product-content .shop a"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={
              ".products-section .product-catdata .product-content .shop a"
            }
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 20)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[
              ".products-section .product-catdata .product-content .shop a"
            ]?.["font-size"]?.replace(/px/g, "")}
            onChange={(e) =>
              handleChangeProductsSectionCatDataImgPostDateContentShopA(e)
            }
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">
          Make change in product box content shop link : hover
        </h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".products-section .product-catdata .product-content .shop a:hover"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={
              ".products-section .product-catdata .product-content .shop a:hover"
            }
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in product box content price</h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".products-section .product-catdata .product-content .price"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={
              ".products-section .product-catdata .product-content .price"
            }
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin (Default : 15 5 10 5)"}
          </Label>
          <div className="d-flex justify-content-between">
            <Input
              className="form-control"
              name="m1"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".products-section .product-catdata .product-content .price"
              ]?.["m1"]?.replace(/px/g, "")}
              onChange={(e) =>
                handleChangeProductsSectionCatDataImgPostDateContentPrice(e)
              }
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="m2"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".products-section .product-catdata .product-content .price"
              ]?.["m2"]?.replace(/px/g, "")}
              onChange={(e) =>
                handleChangeProductsSectionCatDataImgPostDateContentPrice(e)
              }
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="m3"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".products-section .product-catdata .product-content .price"
              ]?.["m3"]?.replace(/px/g, "")}
              onChange={(e) =>
                handleChangeProductsSectionCatDataImgPostDateContentPrice(e)
              }
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="m4"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".products-section .product-catdata .product-content .price"
              ]?.["m4"]?.replace(/px/g, "")}
              onChange={(e) =>
                handleChangeProductsSectionCatDataImgPostDateContentPrice(e)
              }
              style={{ borderRadius: "0", color: "black" }}
            />
          </div>
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">
          Make change in product box content shop link price del
        </h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".products-section .product-catdata .product-content .price del"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={
              ".products-section .product-catdata .product-content .price del"
            }
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin Right (Default : 15)"}
          </Label>
          <Input
            className="form-control"
            name="margin-right"
            type="number"
            placeholder="Margin Right"
            value={settingFormData?.[
              ".products-section .product-catdata .product-content .price del"
            ]?.["margin-right"]?.replace(/px/g, "")}
            onChange={(e) =>
              handleChangeProductsSectionCatDataImgPostDateContentPriceDel(e)
            }
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in product box price text</h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".products-section .product-catdata .product-content .price ins"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={
              ".products-section .product-catdata .product-content .price ins"
            }
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Text Decoration (Default : none)"}
          </Label>
          <select
            name="text-decoration"
            id="text-decoration"
            className="form-select"
            value={
              settingFormData?.[
                ".products-section .product-catdata .product-content .price ins"
              ]?.["text-decoration"]
            }
            onChange={(e) =>
              handleChangeProductsSectionCatDataImgPostDateContentPriceIns(e)
            }
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {textDecoration.map((elem) => {
              return (
                <option value={elem["text-decoration"]}>
                  {elem["text-decoration"]}
                </option>
              );
            })}
          </select>
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in product box add cart</h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".products-section .product-catdata .product-content .add-cart"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={
              ".products-section .product-catdata .product-content .add-cart"
            }
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>

        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border (Default : 2 solid pink)"}
          </Label>
          <div className="d-flex ">
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="borderPx"
                type="number"
                placeholder="px"
                value={settingFormData?.[
                  ".products-section .product-catdata .product-content .add-cart"
                ]?.["borderPx"]?.replace(/px/g, "")}
                onChange={(e) =>
                  handleChangeProductsSectionCatDataImgPostDateContentPriceAddCart(
                    e
                  )
                }
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div style={{ width: "100px" }}>
              <select
                name="borderThik"
                id="borderThik"
                className="form-control"
                value={
                  settingFormData?.[
                    ".products-section .product-catdata .product-content .add-cart"
                  ]?.["borderThik"]
                }
                onChange={(e) =>
                  handleChangeProductsSectionCatDataImgPostDateContentPriceAddCart(
                    e
                  )
                }
                style={{ borderRadius: "0", color: "black" }}
              >
                <option value="">--select--</option>
                {Border.map((elem) => {
                  return <option value={elem.border}>{elem.border}</option>;
                })}
              </select>
            </div>
            <div>
              <CommonColor
                color={
                  settingFormData?.[
                    ".products-section .product-catdata .product-content .add-cart"
                  ]?.["borderColor"]
                }
                setSettingFormData={setSettingFormData}
                className={
                  ".products-section .product-catdata .product-content .add-cart"
                }
                classProperty={"borderColor"}
                label={"Select Color"}
              />
            </div>
          </div>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".products-section .product-catdata .product-content .add-cart"
              ]?.["background-color"]
            }
            setSettingFormData={setSettingFormData}
            className={
              ".products-section .product-catdata .product-content .add-cart"
            }
            classProperty={"background-color"}
            label={"Select Background Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".products-section .product-catdata .product-content .add-cart"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={
              ".products-section .product-catdata .product-content .add-cart"
            }
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 14)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[
              ".products-section .product-catdata .product-content .add-cart"
            ]?.["font-size"]?.replace(/px/g, "")}
            onChange={(e) =>
              handleChangeProductsSectionCatDataImgPostDateContentPriceAddCart(
                e
              )
            }
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 500)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-select"
            value={
              settingFormData?.[
                ".products-section .product-catdata .product-content .add-cart"
              ]?.["font-weight"]
            }
            onChange={(e) =>
              handleChangeProductsSectionCatDataImgPostDateContentPriceAddCart(
                e
              )
            }
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding (Default : 4 - 15)"}
          </Label>
          <div className="d-flex justify-content-between">
            <Input
              className="form-control"
              name="p1"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".products-section .product-catdata .product-content .add-cart"
              ]?.["p1"]?.replace(/px/g, "")}
              onChange={(e) =>
                handleChangeProductsSectionCatDataImgPostDateContentPriceAddCart(
                  e
                )
              }
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="p2"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".products-section .product-catdata .product-content .add-cart"
              ]?.["p2"]?.replace(/px/g, "")}
              onChange={(e) =>
                handleChangeProductsSectionCatDataImgPostDateContentPriceAddCart(
                  e
                )
              }
              style={{ borderRadius: "0", color: "black" }}
            />
          </div>
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in product box add cart : hover</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Opacity (Default : 0.8)"}
          </Label>
          <Input
            className="form-control"
            name="opacity"
            type="number"
            placeholder="Opacity"
            value={settingFormData?.[
              ".products-section .product-catdata .product-content .add-cart:hover"
            ]?.["opacity"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeProductsSectionPriceAddCartHover(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <PaginationButtonStyle
        mainClass={".products-section"}
        settingFormData={settingFormData}
        setSettingFormData={setSettingFormData}
      />
      <CustomStyle
        handleChange={handleChangeCustomStyle}
        value={settingFormData?.custom}
      />

      <ProductImageUploadPopup
        allPopupState={allPopupState}
        setAllPopupState={setAllPopupState}
        crop={crop}
        setCrop={setCrop}
        completedCrop={completedCrop}
        setCompletedCrop={setCompletedCrop}
        scale={scale}
        rotate={rotate}
        image={settingFormData?.image}
        setImage={setSettingFormData}
        imageUpload={imageUpload}
        isEdit={isEdit}
        reupload={reupload}
        // aspect={1.44 / 1}
      />
    </>
  );
};

export default ProductThemeOne;
