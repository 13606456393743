import React, { useContext } from "react";
import { Col, Input, Label, Row } from "reactstrap";
import { fontWeight } from "../../../../constant";
import ServiceAndCategoryContext from "../../../context/ServiceAndCatagoryContext/ServiceAndCategoryContext";
import { Cases, DELETE_CONFIRM } from "../../../constant";
import { delete_Multi_Image_Permanent } from "../apis/SliderApi/SliderApi";
import { Zoom, toast } from "react-toastify";
import Switch from "@material-ui/core/Switch";
import { Close } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { UploadCloud } from "react-feather";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import ProductImageUploadPopup from "../../../Modals/imageUploade/productImageUploadPopup";
import Dividers from "../../../common/divider";
import { OpenModalCommonFunc } from "../../../utils";
import ServiceCategoryDetailStyle from "./ServiceCategoryDetailStyle";
import PaginationButtonStyle from "../../../common/paginationButtonStyle";
import CommonColor from "../../../common/CommonColor";
import CustomStyle from "../../../common/CustomStyle";

const ServiceThemeSeven = (props) => {
  const {
    isEdit,
    settingFormData,
    setSettingFormData,
    backImageToggle,
    setBackImageToggle,
    boxSection,
    setBoxSection,
    setIsImageUpload,
    isBackgroundColor,
    setIsBackgroundColor,
  } = useContext(ServiceAndCategoryContext);
  const { setLoaderShow } = useContext(LoaderContext);
  const {
    allPopupState,
    setAllPopupState,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
  } = useContext(ByteCardBuilderContext);

  const { imageUpload } = props;

  const handleChangeVisionSection = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".boxes1-section": {
          ...prev[".boxes1-section"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeVisionSectionTitleArea = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".boxes1-section .section-title-area": {
          ...prev[".boxes1-section .section-title-area"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeBoxes1SectionTitleAreaSubtitle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".boxes1-section .section-title-area .section-subtitle": {
          ...prev[".boxes1-section .section-title-area .section-subtitle"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeBoxes1SectionTitleAreaSectionTitle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".boxes1-section .section-title-area .section-title": {
          ...prev[".boxes1-section .section-title-area .section-title"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeVisionSectionVisionDiv = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".boxes1-section .cus-boxes": {
          ...prev[".boxes1-section .cus-boxes"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeBoxBefore = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".boxes1-section .cus-boxes:before": {
          ...prev[".boxes1-section .cus-boxes:before"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeVisionSectionVisionSub = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".boxes1-section .flaticon": {
          ...prev[".boxes1-section .flaticon"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeBoxSubTitle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".boxes1-section .subtitle": {
          ...prev[".boxes1-section .subtitle"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeCustomStyle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  /**
   * delete image permanently
   */
  const deleteImagePermanent = async () => {
    try {
      let confirmation = window.confirm(DELETE_CONFIRM);
      if (confirmation) {
        setLoaderShow(true);
        let formData = {
          fileName: settingFormData?.[".boxes1-section.bg-image"]?.[
            "background-image"
          ]
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
            .pop(),
        };
        const { data } = await delete_Multi_Image_Permanent(formData);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
            position: "top-center",
          });
          setSettingFormData({
            ...settingFormData,
            ".boxes1-section.bg-image": { "background-image": "" },
          });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setLoaderShow(false);
    }
  };
  /**
   * image reupload
   */
  const reupload = () => {
    setSettingFormData((prev) => {
      return {
        ...prev,
        image: "",
      };
    });
  };

  return (
    <>
      <Row className="mt-4">
        <h4 className="mb-3">Make change in main Service section</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding (default : 100)"}
          </Label>
          <Input
            className="form-control"
            name="padding"
            type="number"
            placeholder="Padding"
            value={settingFormData?.[".boxes1-section"]?.["padding"]
              ?.split(" ")[0]
              ?.replace(/px/g, "")}
            onChange={(e) => handleChangeVisionSection(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <div className="d-flex align-items-center">
            <Label htmlFor="validationCustom03">
              {isBackgroundColor
                ? "Add Background Color"
                : "Remove Background Color"}
            </Label>
            <Switch
              checked={isBackgroundColor}
              inputProps={{ "aria-label": "controlled" }}
              onChange={(e) => {
                setIsBackgroundColor(e.target.checked);
                setSettingFormData((prev) => {
                  if (e.target.checked) {
                    if (boxSection) {
                      return {
                        ...prev,
                        ".boxes1-section": {
                          "background-color": {
                            r: 255,
                            g: 255,
                            b: 255,
                            a: 100,
                          },
                          padding:
                            settingFormData?.[".boxes1-section"]?.["padding"],
                          width: settingFormData?.[".boxes1-section"]?.[
                            "width"
                          ]?.replace("rem)", ""),
                          margin: settingFormData?.[".boxes1-section"]?.[
                            "margin"
                          ]
                            ?.split(" ")[0]
                            ?.replace(/px/g, ""),
                          "border-radius": settingFormData?.[
                            ".boxes1-section"
                          ]?.["border-radius"]?.replace(/rem/g, ""),
                        },
                      };
                    } else {
                      return {
                        ...prev,
                        ".boxes1-section": {
                          "background-color": {
                            r: 255,
                            g: 255,
                            b: 255,
                            a: 100,
                          },
                          padding:
                            settingFormData?.[".boxes1-section"]?.["padding"],
                        },
                      };
                    }
                  } else {
                    if (boxSection) {
                      return {
                        ...prev,
                        ".boxes1-section": {
                          padding:
                            settingFormData?.[".boxes1-section"]?.["padding"],
                          width: settingFormData?.[".boxes1-section"]?.[
                            "width"
                          ]?.replace("rem)", ""),
                          margin: settingFormData?.[".boxes1-section"]?.[
                            "margin"
                          ]
                            ?.split(" ")[0]
                            ?.replace(/px/g, ""),
                          "border-radius": settingFormData?.[
                            ".boxes1-section"
                          ]?.["border-radius"]?.replace(/rem/g, ""),
                        },
                      };
                    } else {
                      return {
                        ...prev,
                        ".boxes1-section": {
                          padding:
                            settingFormData?.[".boxes1-section"]?.["padding"],
                        },
                      };
                    }
                  }
                });
              }}
            />
          </div>
          {isBackgroundColor && (
            <CommonColor
              color={settingFormData?.[".boxes1-section"]?.["background-color"]}
              setSettingFormData={setSettingFormData}
              className={".boxes1-section"}
              classProperty={"background-color"}
              label={"Select Background Color"}
            />
          )}
        </Col>
        <p>{"Box Section : convert your section to Box section"}</p>
        <div>
          <Switch
            checked={boxSection}
            inputProps={{ "aria-label": "controlled" }}
            onChange={(e) => {
              setBoxSection(e.target.checked);
              setSettingFormData((prev) => {
                return {
                  ...prev,
                  ".boxes1-section": {
                    padding: settingFormData?.[".boxes1-section"]?.["padding"]
                      ?.split(" ")[0]
                      ?.replace(/px/g, ""),
                    "background-color":
                      settingFormData?.[".boxes1-section"]?.[
                        "background-color"
                      ],
                    width: "10",
                    margin: "0",
                    "border-radius": "3",
                  },
                };
              });
            }}
          />
        </div>
        {boxSection && (
          <>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Width (Default : 10)"}
              </Label>
              <Input
                className="form-control"
                name="width"
                type="number"
                placeholder="width"
                value={settingFormData?.[".boxes1-section"]?.["width"]?.replace(
                  "rem)",
                  ""
                )}
                onChange={(e) => handleChangeVisionSection(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Margin (Default : 0)"}
              </Label>
              <Input
                className="form-control"
                name="margin"
                type="number"
                placeholder="margin"
                value={settingFormData?.[".boxes1-section"]?.["margin"]
                  ?.split(" ")[0]
                  ?.replace(/px/g, "")}
                onChange={(e) => handleChangeVisionSection(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Border Radius (Default : 3)"}
              </Label>
              <Input
                className="form-control"
                name="border-radius"
                type="number"
                placeholder="Border Radius"
                value={settingFormData?.[".boxes1-section"]?.[
                  "border-radius"
                ]?.replace(/rem/g, "")}
                onChange={(e) => handleChangeVisionSection(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
          </>
        )}
      </Row>
      <hr />
      <Row className="mt-4">
        <Col md="12 mb-3">
          <h4 className="mb-3">Add Background Image in section</h4>

          <p>do you want to upload Background image</p>
          <Switch
            checked={backImageToggle}
            inputProps={{ "aria-label": "controlled" }}
            onChange={(e) => {
              setBackImageToggle(e.target.checked);
              setSettingFormData((prev) => {
                if (e.target.checked) {
                  return {
                    ...prev,
                    image: "",
                    ".boxes1-section.bg-image": {},
                    ".boxes1-section.bg-image::before": {
                      "background-color": "#000000CC",
                    },
                  };
                } else {
                  return {
                    ...prev,
                    image: "",
                    ".boxes1-section.bg-image": {},
                    ".boxes1-section.bg-image::before": {},
                  };
                }
              });
            }}
          />
          {backImageToggle && (
            <>
              {" "}
              <Col md="6 mb-3">
                <Dividers divide="IMAGE" />
                <p className="mb-4">
                  File type required JPG, PNG (1905px X 986px)
                </p>
              </Col>
              <Row>
                <Col md="4 mb-3">
                  <div
                    className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center"
                    style={{ cursor: "pointer" }}
                  >
                    {!settingFormData?.[".boxes1-section.bg-image"]?.[
                      "background-image"
                    ] && (
                      <span
                        onClick={() => {
                          OpenModalCommonFunc(
                            Cases.PRODUCT_UPLOAD,
                            setAllPopupState
                          );
                          setSettingFormData((prev) => {
                            return {
                              ...prev,
                              image:
                                settingFormData?.[".boxes1-section.bg-image"]?.[
                                  "background-image"
                                ],
                            };
                          });
                          setIsImageUpload((prev) => {
                            return {
                              isList: true,
                              isDetails: false,
                            };
                          });
                        }}
                      >
                        <UploadCloud />
                      </span>
                    )}

                    {settingFormData?.[".boxes1-section.bg-image"]?.[
                      "background-image"
                    ] && (
                      <img
                        src={
                          settingFormData?.[".boxes1-section.bg-image"]?.[
                            "background-image"
                          ] || ""
                        }
                        alt=""
                        style={{
                          width: "85%",
                          height: "90px",
                          objectFit: "contain",
                          position: "absolute",
                        }}
                        onClick={() => {
                          deleteImagePermanent();
                        }}
                      />
                    )}
                    {settingFormData?.[".boxes1-section.bg-image"]?.[
                      "background-image"
                    ] && (
                      <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="close"
                        style={{
                          color: "red",
                          position: "absolute",
                          top: "-22px",
                          left: "1px",
                        }}
                        onClick={() => deleteImagePermanent()}
                      >
                        <Close
                          style={{
                            fontSize: "25px",
                            fontWeight: "bold",
                          }}
                        />
                      </IconButton>
                    )}
                  </div>
                </Col>
                <Col md="6 mb-3">
                  <h4 className="mb-3">Add Background color in section</h4>
                  <Col md="6 mb-3">
                    <CommonColor
                      color={
                        settingFormData?.[".boxes1-section.bg-image::before"]?.[
                          "background-color"
                        ]
                      }
                      setSettingFormData={setSettingFormData}
                      className={".boxes1-section.bg-image::before"}
                      classProperty={"background-color"}
                      label={"Select Background Color"}
                    />
                  </Col>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make space in Title bottom</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin Bottom (Default : 50)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="Margin Bottom"
            value={settingFormData?.[".boxes1-section .section-title-area"]?.[
              "margin-bottom"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeVisionSectionTitleArea(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in section subtitle</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 16)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[
              ".boxes1-section .section-title-area .section-subtitle"
            ]?.["font-size"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeBoxes1SectionTitleAreaSubtitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 600)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-select"
            value={
              settingFormData?.[
                ".boxes1-section .section-title-area .section-subtitle"
              ]?.["font-weight"]
            }
            onChange={(e) => handleChangeBoxes1SectionTitleAreaSubtitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]} key={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".boxes1-section .section-title-area .section-subtitle"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={".boxes1-section .section-title-area .section-subtitle"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".boxes1-section .section-title-area .section-subtitle"
              ]?.["background-color"]
            }
            setSettingFormData={setSettingFormData}
            className={".boxes1-section .section-title-area .section-subtitle"}
            classProperty={"background-color"}
            label={"Select Background Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding (Default : 5 - 20)"}
          </Label>
          <div className="d-flex justify-content-between">
            <Input
              className="form-control"
              name="p1"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".boxes1-section .section-title-area .section-subtitle"
              ]?.["p1"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeBoxes1SectionTitleAreaSubtitle(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="p2"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".boxes1-section .section-title-area .section-subtitle"
              ]?.["p2"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeBoxes1SectionTitleAreaSubtitle(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </div>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border Radius (Default : 15)"}
          </Label>
          <Input
            className="form-control"
            name="border-radius"
            type="number"
            placeholder="Border Radius"
            value={settingFormData?.[
              ".boxes1-section .section-title-area .section-subtitle"
            ]?.["border-radius"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeBoxes1SectionTitleAreaSubtitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin Bottom (Default : 15)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="Margin Bottom"
            value={settingFormData?.[
              ".boxes1-section .section-title-area .section-subtitle"
            ]?.["margin-bottom"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeBoxes1SectionTitleAreaSubtitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in section Title</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 44)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[
              ".boxes1-section .section-title-area .section-title"
            ]?.["font-size"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeBoxes1SectionTitleAreaSectionTitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 600)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-select"
            value={
              settingFormData?.[
                ".boxes1-section .section-title-area .section-title"
              ]?.["font-weight"]
            }
            onChange={(e) => handleChangeBoxes1SectionTitleAreaSectionTitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]} key={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".boxes1-section .section-title-area .section-title"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={".boxes1-section .section-title-area .section-title"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in Box</h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[".boxes1-section .cus-boxes"]?.["background"]
            }
            setSettingFormData={setSettingFormData}
            className={".boxes1-section .cus-boxes"}
            classProperty={"background"}
            label={"Select Background Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding (Default : 42 - 15 - 45)"}
          </Label>
          <div className="d-flex justify-content-between">
            <Input
              className="form-control"
              name="p1"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[".boxes1-section .cus-boxes"]?.[
                "p1"
              ]?.replace(/px/g, "")}
              onChange={(e) => handleChangeVisionSectionVisionDiv(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="p2"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[".boxes1-section .cus-boxes"]?.[
                "p2"
              ]?.replace(/px/g, "")}
              onChange={(e) => handleChangeVisionSectionVisionDiv(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="p3"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[".boxes1-section .cus-boxes"]?.[
                "p3"
              ]?.replace(/px/g, "")}
              onChange={(e) => handleChangeVisionSectionVisionDiv(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </div>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin (Default : 10 - 0)"}
          </Label>
          <div className="d-flex justify-content-between">
            <Input
              className="form-control"
              name="m1"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[".boxes1-section .cus-boxes"]?.[
                "m1"
              ]?.replace(/px/g, "")}
              onChange={(e) => handleChangeVisionSectionVisionDiv(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="m2"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[".boxes1-section .cus-boxes"]?.[
                "m2"
              ]?.replace(/px/g, "")}
              onChange={(e) => handleChangeVisionSectionVisionDiv(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </div>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border Radius (Default : 4)"}
          </Label>
          <Input
            className="form-control"
            name="border-radius"
            type="number"
            placeholder="Border Radius"
            value={settingFormData?.[".boxes1-section .cus-boxes"]?.[
              "border-radius"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeVisionSectionVisionDiv(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in Box : Before</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Height (Default : 22)"}</Label>
          <Input
            className="form-control"
            name="height"
            type="number"
            placeholder="Height"
            value={settingFormData?.[".boxes1-section .cus-boxes:before"]?.[
              "height"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeBoxBefore(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[".boxes1-section .cus-boxes:before"]?.[
                "background-color"
              ]
            }
            setSettingFormData={setSettingFormData}
            className={".boxes1-section .cus-boxes:before"}
            classProperty={"background-color"}
            label={"Select Background Color"}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make Change in Box Flat Icon</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Height (Default : 90)"}</Label>
          <Input
            className="form-control"
            name="height"
            type="number"
            placeholder="Height"
            value={settingFormData?.[".boxes1-section .flaticon"]?.[
              "height"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeVisionSectionVisionSub(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Width (Default : 90)"}</Label>
          <Input
            className="form-control"
            name="width"
            type="number"
            placeholder="Width"
            value={settingFormData?.[".boxes1-section .flaticon"]?.[
              "width"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeVisionSectionVisionSub(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border Radius (Default : 50)"}
          </Label>
          <Input
            className="form-control"
            name="border-radius"
            type="number"
            placeholder="Border Radius"
            value={settingFormData?.[".boxes1-section .flaticon"]?.[
              "border-radius"
            ]?.replace(/%/g, "")}
            onChange={(e) => handleChangeVisionSectionVisionSub(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Margin (default : 0)"}</Label>
          <Input
            className="form-control"
            name="margin"
            type="number"
            placeholder="Margin"
            value={settingFormData?.[".boxes1-section .flaticon"]?.["margin"]
              ?.split(" ")[0]
              ?.replace(/px/g, "")}
            onChange={(e) => handleChangeVisionSectionVisionSub(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[".boxes1-section .flaticon"]?.["background"]
            }
            setSettingFormData={setSettingFormData}
            className={".boxes1-section .flaticon"}
            classProperty={"background"}
            label={"Select Background"}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make Change in Box Flat Icon : hover</h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[".boxes1-section .cus-boxes:hover .flaticon"]?.[
                "background"
              ]
            }
            setSettingFormData={setSettingFormData}
            className={".boxes1-section .cus-boxes:hover .flaticon"}
            classProperty={"background"}
            label={"Select Background"}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in section Sub Title</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 600)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-select"
            value={
              settingFormData?.[".boxes1-section .subtitle"]?.["font-weight"]
            }
            onChange={(e) => handleChangeBoxSubTitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]} key={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 18)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[".boxes1-section .subtitle"]?.[
              "font-size"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeBoxSubTitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Line Height (Default : 1.5)"}
          </Label>
          <Input
            className="form-control"
            name="line-height"
            type="number"
            placeholder="Line Height"
            value={settingFormData?.[".boxes1-section .subtitle"]?.[
              "line-height"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeBoxSubTitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>

        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"margin (Default : 20 0 0)"}
          </Label>
          <div className="d-flex justify-content-between">
            <Input
              className="form-control"
              name="m1"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[".boxes1-section .subtitle"]?.[
                "m1"
              ]?.replace(/px/g, "")}
              onChange={(e) => handleChangeBoxSubTitle(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="m2"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[".boxes1-section .subtitle"]?.[
                "m2"
              ]?.replace(/px/g, "")}
              onChange={(e) => handleChangeBoxSubTitle(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="m3"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[".boxes1-section .subtitle"]?.[
                "m3"
              ]?.replace(/px/g, "")}
              onChange={(e) => handleChangeBoxSubTitle(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </div>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={settingFormData?.[".boxes1-section .subtitle"]?.["color"]}
            setSettingFormData={setSettingFormData}
            className={".boxes1-section .subtitle"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in section Sub Title : hover</h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[".boxes1-section .cus-boxes:hover .subtitle"]?.[
                "color"
              ]
            }
            setSettingFormData={setSettingFormData}
            className={".boxes1-section .cus-boxes:hover .subtitle"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
      </Row>
      <hr />
      <ServiceCategoryDetailStyle />
      <PaginationButtonStyle
        mainClass={".boxes1-section"}
        settingFormData={settingFormData}
        setSettingFormData={setSettingFormData}
      />
      <CustomStyle
        handleChange={handleChangeCustomStyle}
        value={settingFormData?.custom}
      />
      <ProductImageUploadPopup
        allPopupState={allPopupState}
        setAllPopupState={setAllPopupState}
        crop={crop}
        setCrop={setCrop}
        completedCrop={completedCrop}
        setCompletedCrop={setCompletedCrop}
        scale={scale}
        rotate={rotate}
        image={settingFormData?.image}
        setImage={setSettingFormData}
        imageUpload={imageUpload}
        isEdit={isEdit}
        reupload={reupload}
        // aspect={1.93 / 1}
      />
    </>
  );
};

export default ServiceThemeSeven;
