import React, { useContext } from "react";
import { Button, Col, Container, Input, Label, Row } from "reactstrap";
import {
  Border,
  FaqsTheme,
  THEME_UPDATE_MESSAGE,
  fontWeight,
} from "../../../../constant";
import FaqsContext from "../../../context/FaqsContext/FaqsContext";
import { Cases, DELETE_CONFIRM } from "../../../constant";
import { delete_Multi_Image_Permanent } from "../apis/SliderApi/SliderApi";
import { Zoom, toast } from "react-toastify";
import { IconButton } from "@material-ui/core";
import { Switch } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { UploadCloud } from "react-feather";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import Dividers from "../../../common/divider";
import ProductImageUploadPopup from "../../../Modals/imageUploade/productImageUploadPopup";
import PaginationButtonStyle from "../../../common/paginationButtonStyle";
import CommonColor from "../../../common/CommonColor";
import CustomStyle from "../../../common/CustomStyle";
import { putUpdateFaqs } from "../apis/FaqsApi/FaqsApi";
import FaqsTheme1 from "../ScreenShorts/Faqs/FaqsTheme1.png";
import FaqsTheme2 from "../ScreenShorts/Faqs/faqsTheme2.png";
import FaqsTheme3 from "../ScreenShorts/Faqs/FaqsTheme3.png";
import ThemeImageShow from "../../../common/ThemeImageShow";

const SettingFaqsPerentPopup = (props) => {
  const {
    isEdit,
    settingFormData,
    setSettingFormData,
    backImageToggle,
    setBackImageToggle,
    boxSection,
    setBoxSection,
    isBackgroundColor,
    setIsBackgroundColor,
    theme,
    backImageToggle2,
    setBackImageToggle2,
    setIsImageUpload,
    setTheme,
    id,
    imgShow,
    setImgShow,
  } = useContext(FaqsContext);
  const {
    allPopupState,
    setAllPopupState,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
  } = useContext(ByteCardBuilderContext);
  const { imageUpload, handleSubmitSetting, handleChangeSettings } = props;
  const { setLoaderShow } = useContext(LoaderContext);

  /**
   * input value change
   * @param {event} e
   */

  const handleChangeFaqsSection = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".faqs-section"]: {
          ...prev[".faqs-section"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeFaqsSectionTitleArea = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".faqs-section .section-title-area"]: {
          ...prev[".faqs-section .section-title-area"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeFaqsSectionTitleAreaSubtitle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".faqs-section .section-title-area .section-subtitle"]: {
          ...prev[".faqs-section .section-title-area .section-subtitle"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeFaqsSectionTitleAreaTitle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".faqs-section .section-title-area .section-title"]: {
          ...prev[".faqs-section .section-title-area .section-title"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeFaqsWrapper = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".faq-wrapper"]: {
          ...prev[".faq-wrapper"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeFaqWrapperAccordionItem = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".faq-wrapper .accordion-item"]: {
          ...prev[".faq-wrapper .accordion-item"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeFaqWrapperAccordionItemLastChild = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".faq-wrapper .accordion-item:last-child"]: {
          ...prev[".faq-wrapper .accordion-item:last-child"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeFaqsWrapperAccordionButton = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".faq-wrapper .accordion-button"]: {
          ...prev[".faq-wrapper .accordion-button"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeFaqsWrapperAccordionBody = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".faq-wrapper .accordion-body"]: {
          ...prev[".faq-wrapper .accordion-body"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeCustomStyle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  const resetHandler = () => {
    setSettingFormData(FaqsTheme);
    setBackImageToggle(false);
    setBackImageToggle2(false);
    setBoxSection(false);
    setIsBackgroundColor(true);
  };

  /**
   * delete image permanently
   */
  const deleteImagePermanent = async (imgName) => {
    try {
      let confirmation = window.confirm(DELETE_CONFIRM);
      if (confirmation) {
        setLoaderShow(true);
        let formData;
        if (imgName == "background-image") {
          formData = {
            fileName: settingFormData?.[".faqs-section.bg-image"]?.[
              "background-image"
            ]
              .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
              .pop(),
          };
        }
        if (imgName == "faqsSideImage") {
          formData = {
            fileName: settingFormData?.["faqsSideImage"]
              .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
              .pop(),
          };
        }
        const { data } = await delete_Multi_Image_Permanent(formData);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
            position: "top-center",
          });
          if (imgName == "background-image") {
            setSettingFormData({
              ...settingFormData,
              ".faqs-section.bg-image": { "background-image": "" },
            });
          }
          if (imgName == "faqsSideImage") {
            setSettingFormData({
              ...settingFormData,
              faqsSideImage: "",
            });
          }
          setLoaderShow(false);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setLoaderShow(false);
    }
  };

  /**
   * image reupload
   */
  const reupload = () => {
    setSettingFormData((prev) => {
      return {
        ...prev,
        image: "",
      };
    });
  };

  const handleTheme = async (e) => {
    setLoaderShow(true);
    const { value } = e.target;
    setTheme(value);
    try {
      let formData = {
        theme: value,
        faqsId: id,
      };
      const { data } = await putUpdateFaqs(formData);
      if (data.status === 200) {
        await handleChangeSettings(id, true);
        TostMessage({
          message: { message: THEME_UPDATE_MESSAGE },
          type: "success",
        });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    } finally {
      setLoaderShow(false);
    }
  };
  const handleClose = () => {
    setImgShow(false);
  };
  const themeImg = {
    1: FaqsTheme1,
    2: FaqsTheme2,
    3: FaqsTheme3,
  };
  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col sm="12" xl="12">
            <div className="mt-4 d-flex flex-column justify-content-center position-relative">
              <div
                className="d-flex justify-content-between position-sticky"
                style={{ top: "100px", background: "white", zIndex: 999 }}
              >
                <h6>{isEdit ? "Edit" : "Add New"}</h6>
                <div className="d-flex gap-2">
                  <Button onClick={resetHandler}>Reset</Button>
                  <Button onClick={() => handleSubmitSetting(true)}>
                    Restore
                  </Button>
                  <Button
                    onClick={() => handleSubmitSetting(null, false)}
                    color="success"
                  >
                    Save & View
                  </Button>
                </div>
              </div>
              <Row>
                <Col md="12 mb-3">
                  <label htmlFor="">Theme</label>
                  <select
                    name="theme"
                    id="font-weight"
                    className="form-select"
                    value={theme}
                    onChange={(e) => handleTheme(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value={1}>Theme 1</option>
                    <option value={2}>Theme 2</option>
                    <option value={3}>Theme 3</option>
                  </select>
                </Col>
              </Row>
              {!imgShow && (
                <div>
                  <Button
                    onClick={() => setImgShow(true)}
                    color="primary"
                    className="mt-2"
                  >
                    Show Theme Image
                  </Button>
                </div>
              )}
              {imgShow && (
                <ThemeImageShow
                  themeImg={themeImg[theme]}
                  handleClose={handleClose}
                />
              )}
              <Row className="mt-4">
                <h4 className="mb-3">Make change in main Section</h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Padding (Default : 100)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="padding"
                    type="number"
                    placeholder="Padding"
                    value={settingFormData?.[".faqs-section"]?.["padding"]
                      ?.split(" ")[0]
                      ?.replace(/px/g, "")}
                    onChange={(e) => handleChangeFaqsSection(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <div className="d-flex align-items-center">
                    <Label htmlFor="validationCustom03">
                      {isBackgroundColor
                        ? "Add Background Color"
                        : "Remove Background Color"}
                    </Label>
                    <Switch
                      checked={isBackgroundColor}
                      inputProps={{ "aria-label": "controlled" }}
                      onChange={(e) => {
                        setIsBackgroundColor(e.target.checked);
                        setSettingFormData((prev) => {
                          if (e.target.checked) {
                            if (boxSection) {
                              return {
                                ...prev,
                                [".faqs-section"]: {
                                  "background-color": "#f9f9f9",
                                  padding:
                                    settingFormData?.[".faqs-section"]?.[
                                      "padding"
                                    ],
                                  width: settingFormData?.[".faqs-section"]?.[
                                    "width"
                                  ]?.replace("rem)", ""),
                                  margin: settingFormData?.[".faqs-section"]?.[
                                    "margin"
                                  ]
                                    ?.split(" ")[0]
                                    ?.replace(/px/g, ""),
                                  "border-radius": settingFormData?.[
                                    ".faqs-section"
                                  ]?.["border-radius"]?.replace(/rem/g, ""),
                                },
                              };
                            } else {
                              return {
                                ...prev,
                                [".faqs-section"]: {
                                  "background-color": "#fbfcfd",
                                  padding:
                                    settingFormData?.[".faqs-section"]?.[
                                      "padding"
                                    ],
                                },
                              };
                            }
                          } else {
                            if (boxSection) {
                              return {
                                ...prev,
                                [".faqs-section"]: {
                                  padding:
                                    settingFormData?.[".faqs-section"]?.[
                                      "padding"
                                    ],
                                  width: settingFormData?.[".faqs-section"]?.[
                                    "width"
                                  ]?.replace("rem)", ""),
                                  margin: settingFormData?.[".faqs-section"]?.[
                                    "margin"
                                  ]
                                    ?.split(" ")[0]
                                    ?.replace(/px/g, ""),
                                  "border-radius": settingFormData?.[
                                    ".faqs-section"
                                  ]?.["border-radius"]?.replace(/rem/g, ""),
                                },
                              };
                            } else {
                              return {
                                ...prev,
                                [".faqs-section"]: {
                                  padding:
                                    settingFormData?.[".faqs-section"]?.[
                                      "padding"
                                    ],
                                },
                              };
                            }
                          }
                        });
                      }}
                    />
                  </div>
                  {isBackgroundColor && (
                    <CommonColor
                      color={
                        settingFormData?.[".faqs-section"]?.["background-color"]
                      }
                      setSettingFormData={setSettingFormData}
                      className={".faqs-section"}
                      classProperty={"background-color"}
                      label={"Select Background Color"}
                    />
                  )}
                </Col>
                <p>{"Box Section : convert your section to Box section"}</p>
                <div>
                  <Switch
                    checked={boxSection}
                    inputProps={{ "aria-label": "controlled" }}
                    onChange={(e) => {
                      setBoxSection(e.target.checked);
                      setSettingFormData((prev) => {
                        return {
                          ...prev,
                          ".faqs-section": {
                            padding: settingFormData?.[".faqs-section"]?.[
                              "padding"
                            ]
                              ?.split(" ")[0]
                              ?.replace(/px/g, ""),
                            "background-color":
                              settingFormData?.[".faqs-section"]?.[
                                "background-color"
                              ],
                            width: "10",
                            margin: "0",
                            "border-radius": "3",
                          },
                        };
                      });
                    }}
                  />
                </div>
                {boxSection && (
                  <>
                    <Col md="4 mb-3">
                      <Label htmlFor="validationCustom03">
                        {"Width (Default : 10)"}
                      </Label>
                      <Input
                        className="form-control"
                        name="width"
                        type="number"
                        placeholder="width"
                        value={settingFormData?.[".faqs-section"]?.[
                          "width"
                        ]?.replace("rem)", "")}
                        onChange={(e) => handleChangeFaqsSection(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </Col>
                    <Col md="4 mb-3">
                      <Label htmlFor="validationCustom03">
                        {"Margin (Default : 0)"}
                      </Label>
                      <Input
                        className="form-control"
                        name="margin"
                        type="number"
                        placeholder="margin"
                        value={settingFormData?.[".faqs-section"]?.["margin"]
                          ?.split(" ")[0]
                          ?.replace(/px/g, "")}
                        onChange={(e) => handleChangeFaqsSection(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </Col>
                    <Col md="4 mb-3">
                      <Label htmlFor="validationCustom03">
                        {"Border Radius (Default : 3)"}
                      </Label>
                      <Input
                        className="form-control"
                        name="border-radius"
                        type="number"
                        placeholder="Border Radius"
                        value={settingFormData?.[".faqs-section"]?.[
                          "border-radius"
                        ]?.replace(/rem/g, "")}
                        onChange={(e) => handleChangeFaqsSection(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </Col>
                  </>
                )}
              </Row>
              <hr />
              <Row className="mt-4">
                <Col md="12 mb-3">
                  <h4 className="mb-3">Add Background Image in section</h4>

                  <p>do you want to upload Background image</p>
                  <Switch
                    checked={backImageToggle}
                    inputProps={{ "aria-label": "controlled" }}
                    onChange={(e) => {
                      setBackImageToggle(e.target.checked);
                      setSettingFormData((prev) => {
                        if (e.target.checked) {
                          return {
                            ...prev,
                            image: "",
                            ".faqs-section.bg-image": {},
                            ".faqs-section.bg-image::before": {
                              "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
                            },
                          };
                        } else {
                          return {
                            ...prev,
                            image: "",
                            ".faqs-section.bg-image": {},
                            ".faqs-section.bg-image::before": {
                              // "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
                            },
                          };
                        }
                      });
                    }}
                  />
                  {backImageToggle && (
                    <>
                      {" "}
                      <Col md="6 mb-3">
                        <Dividers divide="IMAGE" />
                        <p className="mb-4">
                          File type required JPG, PNG (1905px X 574px)
                        </p>
                      </Col>
                      <Row>
                        <Col md="4 mb-3">
                          <div
                            className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center"
                            style={{ cursor: "pointer" }}
                          >
                            {!settingFormData?.[".faqs-section.bg-image"]?.[
                              "background-image"
                            ] && (
                              <span
                                onClick={() => {
                                  OpenModalCommonFunc(
                                    Cases.PRODUCT_UPLOAD,
                                    setAllPopupState
                                  );
                                  setIsImageUpload((prev) => {
                                    return {
                                      isList: true,
                                      isDetails: false,
                                    };
                                  });
                                }}
                              >
                                <UploadCloud />
                              </span>
                            )}

                            {settingFormData?.[".faqs-section.bg-image"]?.[
                              "background-image"
                            ] && (
                              <img
                                src={
                                  `${process.env.REACT_APP_BUCKET_URL}/${process.env.REACT_APP_BUCKET_PRO_FOLDER}/${settingFormData?.[".faqs-section.bg-image"]?.["background-image"]}` ||
                                  ""
                                }
                                alt=""
                                style={{
                                  width: "85%",
                                  height: "90px",
                                  objectFit: "contain",
                                  position: "absolute",
                                }}
                                onClick={() =>
                                  deleteImagePermanent("background-image")
                                }
                              />
                            )}
                            {settingFormData?.[".faqs-section.bg-image"]?.[
                              "background-image"
                            ] && (
                              <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="close"
                                style={{
                                  color: "red",
                                  position: "absolute",
                                  top: "-22px",
                                  left: "1px",
                                }}
                                onClick={() =>
                                  deleteImagePermanent("background-image")
                                }
                              >
                                <Close
                                  style={{
                                    fontSize: "25px",
                                    fontWeight: "bold",
                                  }}
                                />
                              </IconButton>
                            )}
                          </div>
                        </Col>
                        <Col md="6 mb-3">
                          <h4 className="mb-3">
                            Add Background color in section
                          </h4>
                          <Col md="6 mb-3">
                            <CommonColor
                              color={
                                settingFormData?.[
                                  ".faqs-section.bg-image::before"
                                ]?.["background-color"]
                              }
                              setSettingFormData={setSettingFormData}
                              className={".faqs-section.bg-image::before"}
                              classProperty={"background-color"}
                              label={"Select Background Color"}
                            />
                          </Col>
                        </Col>
                      </Row>
                    </>
                  )}
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <h4 className="mb-3">Make space in Title bottom</h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Margin Bottom (Default : 50)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="margin-bottom"
                    type="number"
                    placeholder="Margin Bottom"
                    value={settingFormData?.[
                      ".faqs-section .section-title-area"
                    ]?.["margin-bottom"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeFaqsSectionTitleArea(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <h4 className="mb-3">Make change in section subtitle</h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Size (Default : 16)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="font-size"
                    type="number"
                    placeholder="Font Size"
                    value={settingFormData?.[
                      ".faqs-section .section-title-area .section-subtitle"
                    ]?.["font-size"]?.replace(/px/g, "")}
                    onChange={(e) =>
                      handleChangeFaqsSectionTitleAreaSubtitle(e)
                    }
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Weight (Default : 600)"}
                  </Label>
                  <select
                    name="font-weight"
                    id="font-weight"
                    className="form-control"
                    value={
                      settingFormData?.[
                        ".faqs-section .section-title-area .section-subtitle"
                      ]?.["font-weight"]
                    }
                    onChange={(e) =>
                      handleChangeFaqsSectionTitleAreaSubtitle(e)
                    }
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value="">--select--</option>
                    {fontWeight.map((elem) => {
                      return (
                        <option value={elem["font-weight"]}>
                          {elem["font-weight"]}
                        </option>
                      );
                    })}
                  </select>
                </Col>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".faqs-section .section-title-area .section-subtitle"
                      ]?.["color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={
                      ".faqs-section .section-title-area .section-subtitle"
                    }
                    classProperty={"color"}
                    label={"Select Color"}
                  />
                </Col>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".faqs-section .section-title-area .section-subtitle"
                      ]?.["background-color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={
                      ".faqs-section .section-title-area .section-subtitle"
                    }
                    classProperty={"background-color"}
                    label={"Select Background Color"}
                  />

                  {/* <Input
                    className="form-control"
                    name="background-color"
                    type="color"
                    placeholder="Background Color"
                    value={
                      settingFormData?.[
                        ".faqs-section .section-title-area .section-subtitle"
                      ]?.["background-color"]
                    }
                    onChange={(e) =>
                      handleChangeFaqsSectionTitleAreaSubtitle(e)
                    }
                    style={{ borderRadius: "0", color: "black" }}
                  /> */}
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Padding (Default : 5 - 20)"}
                  </Label>
                  <div className="d-flex justify-content-between">
                    <Input
                      className="form-control"
                      name="p1"
                      type="number"
                      placeholder="Padding"
                      value={settingFormData?.[
                        ".faqs-section .section-title-area .section-subtitle"
                      ]?.["p1"]?.replace(/px/g, "")}
                      onChange={(e) =>
                        handleChangeFaqsSectionTitleAreaSubtitle(e)
                      }
                      style={{ borderRadius: "0", color: "black" }}
                    />
                    <Input
                      className="form-control"
                      name="p2"
                      type="number"
                      placeholder="Padding"
                      value={settingFormData?.[
                        ".faqs-section .section-title-area .section-subtitle"
                      ]?.["p2"]?.replace(/px/g, "")}
                      onChange={(e) =>
                        handleChangeFaqsSectionTitleAreaSubtitle(e)
                      }
                      style={{ borderRadius: "0", color: "black" }}
                    />
                  </div>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Border Radius (Default : 15)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="border-radius"
                    type="number"
                    placeholder="Border Radius"
                    value={settingFormData?.[
                      ".faqs-section .section-title-area .section-subtitle"
                    ]?.["border-radius"]?.replace(/px/g, "")}
                    onChange={(e) =>
                      handleChangeFaqsSectionTitleAreaSubtitle(e)
                    }
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Margin Bottom (Default : 15)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="margin-bottom"
                    type="number"
                    placeholder="Margin Bottom"
                    value={settingFormData?.[
                      ".faqs-section .section-title-area .section-subtitle"
                    ]?.["margin-bottom"]?.replace(/px/g, "")}
                    onChange={(e) =>
                      handleChangeFaqsSectionTitleAreaSubtitle(e)
                    }
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <h4 className="mb-3">Make change in section Title</h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Size (Default : 44)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="font-size"
                    type="number"
                    placeholder="Font Size"
                    value={settingFormData?.[
                      ".faqs-section .section-title-area .section-title"
                    ]?.["font-size"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeFaqsSectionTitleAreaTitle(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Weight (Default : 600)"}
                  </Label>
                  <select
                    name="font-weight"
                    id="font-weight"
                    className="form-control"
                    value={
                      settingFormData?.[
                        ".faqs-section .section-title-area .section-title"
                      ]?.["font-weight"]
                    }
                    onChange={(e) => handleChangeFaqsSectionTitleAreaTitle(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value="">--select--</option>
                    {fontWeight.map((elem) => {
                      return (
                        <option value={elem["font-weight"]}>
                          {elem["font-weight"]}
                        </option>
                      );
                    })}
                  </select>
                </Col>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".faqs-section .section-title-area .section-title"
                      ]?.["color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={
                      ".faqs-section .section-title-area .section-title"
                    }
                    classProperty={"color"}
                    label={"Select Color"}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <h4 className="mb-3">Make change in faqs wrapper</h4>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[".faq-wrapper"]?.["background-color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".faq-wrapper"}
                    classProperty={"background-color"}
                    label={"Select Background Color"}
                  />
                </Col>
                <Col md="3 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Padding (Default : 35)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="padding"
                    type="number"
                    placeholder="Padding"
                    value={settingFormData?.[".faq-wrapper"]?.[
                      "padding"
                    ]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeFaqsWrapper(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Border Top (Default : 3 solid pink)"}
                  </Label>
                  <div className="d-flex">
                    <div style={{ width: "100px" }}>
                      <Input
                        className="form-control"
                        name="borderPx"
                        type="number"
                        placeholder="px"
                        value={settingFormData?.[".faq-wrapper"]?.[
                          "borderPx"
                        ]?.replace(/px/g, "")}
                        onChange={(e) => handleChangeFaqsWrapper(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </div>
                    <div style={{ width: "100px" }}>
                      <select
                        name="borderThik"
                        id="borderThik"
                        className="form-control"
                        value={
                          settingFormData?.[".faq-wrapper"]?.["borderThik"]
                        }
                        onChange={(e) => handleChangeFaqsWrapper(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      >
                        <option value="">--select--</option>
                        {Border.map((elem) => {
                          return (
                            <option value={elem.border}>{elem.border}</option>
                          );
                        })}
                      </select>
                    </div>
                    <div>
                      <CommonColor
                        color={
                          settingFormData?.[".faq-wrapper"]?.["borderColor"]
                        }
                        setSettingFormData={setSettingFormData}
                        className={".faq-wrapper"}
                        classProperty={"borderColor"}
                        label={"Select Color"}
                      />
                    </div>
                  </div>
                </Col>
                <Col md="6 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Box Shadow (Default : 0 30 70 0 gray)"}
                  </Label>
                  <div className="d-flex ">
                    <div style={{ width: "100px" }}>
                      <Input
                        className="form-control"
                        name="bs1"
                        type="number"
                        placeholder="px"
                        value={settingFormData?.[".faq-wrapper"]?.[
                          "bs1"
                        ]?.replace(/px/g, "")}
                        onChange={(e) => handleChangeFaqsWrapper(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </div>
                    <div style={{ width: "100px" }}>
                      <Input
                        className="form-control"
                        name="bs2"
                        type="number"
                        placeholder="px"
                        value={settingFormData?.[".faq-wrapper"]?.[
                          "bs2"
                        ]?.replace(/px/g, "")}
                        onChange={(e) => handleChangeFaqsWrapper(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </div>
                    <div style={{ width: "100px" }}>
                      <Input
                        className="form-control"
                        name="bs3"
                        type="number"
                        placeholder="px"
                        value={settingFormData?.[".faq-wrapper"]?.[
                          "bs3"
                        ]?.replace(/px/g, "")}
                        onChange={(e) => handleChangeFaqsWrapper(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </div>
                    <div style={{ width: "100px" }}>
                      <Input
                        className="form-control"
                        name="bs4"
                        type="number"
                        placeholder="px"
                        value={settingFormData?.[".faq-wrapper"]?.[
                          "bs4"
                        ]?.replace(/px/g, "")}
                        onChange={(e) => handleChangeFaqsWrapper(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </div>
                    <div>
                      <CommonColor
                        color={
                          settingFormData?.[".faq-wrapper"]?.["shadowColor"]
                        }
                        setSettingFormData={setSettingFormData}
                        className={".faq-wrapper"}
                        classProperty={"shadowColor"}
                        label={"Select Color"}
                      />
                    </div>
                  </div>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Border Radius (Default : 20)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="border-radius"
                    type="number"
                    placeholder="Border Radius"
                    value={settingFormData?.[".faq-wrapper"]?.[
                      "border-radius"
                    ]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeFaqsWrapper(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <h4 className="mb-3">
                  Make change in faqs wrapper | accordion-item
                </h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Padding Top (Default : 30)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="padding-top"
                    type="number"
                    placeholder="Padding Top"
                    value={settingFormData?.[".faq-wrapper .accordion-item"]?.[
                      "padding-top"
                    ]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeFaqWrapperAccordionItem(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Padding Bottom (Default : 30)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="padding-bottom"
                    type="number"
                    placeholder="Padding Bottom"
                    value={settingFormData?.[".faq-wrapper .accordion-item"]?.[
                      "padding-bottom"
                    ]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeFaqWrapperAccordionItem(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Border (Default : 0 solid white)"}
                  </Label>
                  <div className="d-flex ">
                    <div style={{ width: "100px" }}>
                      <Input
                        className="form-control"
                        name="borderPx"
                        type="number"
                        placeholder="px"
                        value={settingFormData?.[
                          ".faq-wrapper .accordion-item"
                        ]?.["borderPx"]?.replace(/px/g, "")}
                        onChange={(e) => handleChangeFaqWrapperAccordionItem(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </div>
                    <div style={{ width: "100px" }}>
                      <select
                        name="borderThik"
                        id="borderThik"
                        className="form-control"
                        value={
                          settingFormData?.[".faq-wrapper .accordion-item"]?.[
                            "borderThik"
                          ]
                        }
                        onChange={(e) => handleChangeFaqWrapperAccordionItem(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      >
                        <option value="">--select--</option>
                        {Border.map((elem) => {
                          return (
                            <option value={elem.border}>{elem.border}</option>
                          );
                        })}
                      </select>
                    </div>
                    <div>
                      <CommonColor
                        color={
                          settingFormData?.[".faq-wrapper .accordion-item"]?.[
                            "borderColor"
                          ]
                        }
                        setSettingFormData={setSettingFormData}
                        className={".faq-wrapper .accordion-item"}
                        classProperty={"borderColor"}
                        label={"Select Color"}
                      />
                    </div>
                  </div>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Border Bottom (Default : 1 solid pink)"}
                  </Label>
                  <div className="d-flex ">
                    <div style={{ width: "100px" }}>
                      <Input
                        className="form-control"
                        name="borderBtPx"
                        type="number"
                        placeholder="px"
                        value={settingFormData?.[
                          ".faq-wrapper .accordion-item"
                        ]?.["borderBtPx"]?.replace(/px/g, "")}
                        onChange={(e) => handleChangeFaqWrapperAccordionItem(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </div>
                    <div style={{ width: "100px" }}>
                      <select
                        name="borderBtThik"
                        id="borderBtThik"
                        className="form-control"
                        value={
                          settingFormData?.[".faq-wrapper .accordion-item"]?.[
                            "borderBtThik"
                          ]
                        }
                        onChange={(e) => handleChangeFaqWrapperAccordionItem(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      >
                        <option value="">--select--</option>
                        {Border.map((elem) => {
                          return (
                            <option value={elem.border}>{elem.border}</option>
                          );
                        })}
                      </select>
                    </div>
                    <div>
                      <CommonColor
                        color={
                          settingFormData?.[".faq-wrapper .accordion-item"]?.[
                            "borderBtColor"
                          ]
                        }
                        setSettingFormData={setSettingFormData}
                        className={".faq-wrapper .accordion-item"}
                        classProperty={"borderBtColor"}
                        label={"Select Color"}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <h4 className="mb-3">
                  Make change in faqs wrapper | accordion-item:last-child
                </h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Border Bottom (Default : 0 solid white)"}
                  </Label>
                  <div className="d-flex">
                    <div style={{ width: "100px" }}>
                      <Input
                        className="form-control"
                        name="borderPx"
                        type="number"
                        placeholder="px"
                        value={settingFormData?.[
                          ".faq-wrapper .accordion-item:last-child"
                        ]?.["borderPx"]?.replace(/px/g, "")}
                        onChange={(e) =>
                          handleChangeFaqWrapperAccordionItemLastChild(e)
                        }
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </div>
                    <div style={{ width: "100px" }}>
                      <select
                        name="borderThik"
                        id="borderThik"
                        className="form-control"
                        value={
                          settingFormData?.[
                            ".faq-wrapper .accordion-item:last-child"
                          ]?.["borderThik"]
                        }
                        onChange={(e) =>
                          handleChangeFaqWrapperAccordionItemLastChild(e)
                        }
                        style={{ borderRadius: "0", color: "black" }}
                      >
                        <option value="">--select--</option>
                        {Border.map((elem) => {
                          return (
                            <option value={elem.border}>{elem.border}</option>
                          );
                        })}
                      </select>
                    </div>
                    <div>
                      <CommonColor
                        color={
                          settingFormData?.[
                            ".faq-wrapper .accordion-item:last-child"
                          ]?.["borderColor"]
                        }
                        setSettingFormData={setSettingFormData}
                        className={".faq-wrapper .accordion-item:last-child"}
                        classProperty={"borderColor"}
                        label={"Select Color"}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <h4 className="mb-3">
                  Make change in faqs wrapper | accordion-button
                </h4>
                <Col md="7 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Box Shadow (Default : 0 0 0 0 gray)"}
                  </Label>
                  <div className="d-flex ">
                    <div style={{ width: "100px" }}>
                      <Input
                        className="form-control"
                        name="bs1"
                        type="number"
                        placeholder="px"
                        value={settingFormData?.[
                          ".faq-wrapper .accordion-button"
                        ]?.["bs1"]?.replace(/px/g, "")}
                        onChange={(e) =>
                          handleChangeFaqsWrapperAccordionButton(e)
                        }
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </div>
                    <div style={{ width: "100px" }}>
                      <Input
                        className="form-control"
                        name="bs2"
                        type="number"
                        placeholder="px"
                        value={settingFormData?.[
                          ".faq-wrapper .accordion-button"
                        ]?.["bs2"]?.replace(/px/g, "")}
                        onChange={(e) =>
                          handleChangeFaqsWrapperAccordionButton(e)
                        }
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </div>
                    <div style={{ width: "100px" }}>
                      <Input
                        className="form-control"
                        name="bs3"
                        type="number"
                        placeholder="px"
                        value={settingFormData?.[
                          ".faq-wrapper .accordion-button"
                        ]?.["bs3"]?.replace(/px/g, "")}
                        onChange={(e) =>
                          handleChangeFaqsWrapperAccordionButton(e)
                        }
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </div>
                    <div style={{ width: "100px" }}>
                      <Input
                        className="form-control"
                        name="bs4"
                        type="number"
                        placeholder="px"
                        value={settingFormData?.[
                          ".faq-wrapper .accordion-button"
                        ]?.["bs4"]?.replace(/px/g, "")}
                        onChange={(e) =>
                          handleChangeFaqsWrapperAccordionButton(e)
                        }
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </div>
                    <div>
                      <CommonColor
                        color={
                          settingFormData?.[".faq-wrapper .accordion-button"]?.[
                            "shadowColor"
                          ]
                        }
                        setSettingFormData={setSettingFormData}
                        className={".faq-wrapper .accordion-button"}
                        classProperty={"shadowColor"}
                        label={"Select Color"}
                      />
                    </div>
                  </div>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Outline (Default : 0 solid white)"}
                  </Label>
                  <div className="d-flex ">
                    <div style={{ width: "100px" }}>
                      <Input
                        className="form-control"
                        name="outPx"
                        type="number"
                        placeholder="px"
                        value={settingFormData?.[
                          ".faq-wrapper .accordion-button"
                        ]?.["outPx"]?.replace(/px/g, "")}
                        onChange={(e) =>
                          handleChangeFaqsWrapperAccordionButton(e)
                        }
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </div>
                    <div style={{ width: "100px" }}>
                      <select
                        name="outThik"
                        id="outThik"
                        className="form-control"
                        value={
                          settingFormData?.[".faq-wrapper .accordion-button"]?.[
                            "outThik"
                          ]
                        }
                        onChange={(e) =>
                          handleChangeFaqsWrapperAccordionButton(e)
                        }
                        style={{ borderRadius: "0", color: "black" }}
                      >
                        <option value="">--select--</option>
                        {Border.map((elem) => {
                          return (
                            <option value={elem.border}>{elem.border}</option>
                          );
                        })}
                      </select>
                    </div>
                    <div>
                      <CommonColor
                        color={
                          settingFormData?.[".faq-wrapper .accordion-button"]?.[
                            "outColor"
                          ]
                        }
                        setSettingFormData={setSettingFormData}
                        className={".faq-wrapper .accordion-button"}
                        classProperty={"outColor"}
                        label={"Select Color"}
                      />
                    </div>
                  </div>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Weight (Default : 500)"}
                  </Label>
                  <select
                    name="font-weight"
                    id="font-weight"
                    className="form-control"
                    value={
                      settingFormData?.[".faq-wrapper .accordion-button"]?.[
                        "font-weight"
                      ]
                    }
                    onChange={(e) => handleChangeFaqsWrapperAccordionButton(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value="">--select--</option>
                    {fontWeight.map((elem) => {
                      return (
                        <option value={elem["font-weight"]}>
                          {elem["font-weight"]}
                        </option>
                      );
                    })}
                  </select>
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <h4 className="mb-3">
                  Make change in faqs wrapper | accordion-button:not(.collapsed)
                </h4>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".faq-wrapper .accordion-button:not(.collapsed)"
                      ]?.["color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".faq-wrapper .accordion-button:not(.collapsed)"}
                    classProperty={"color"}
                    label={"Select Color"}
                  />
                </Col>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".faq-wrapper .accordion-button:not(.collapsed)"
                      ]?.["background-color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".faq-wrapper .accordion-button:not(.collapsed)"}
                    classProperty={"background-color"}
                    label={"Select Background Color"}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <h4 className="mb-3">
                  Make change in faqs wrapper | accordion-body
                </h4>
                <Col md="2 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Line Height (Default : 1.4)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="line-height"
                    type="number"
                    placeholder="line-height"
                    value={
                      settingFormData?.[".faq-wrapper .accordion-body"]?.[
                        "line-height"
                      ]
                    }
                    onChange={(e) => handleChangeFaqsWrapperAccordionBody(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
              <PaginationButtonStyle
                mainClass={".faqs-section"}
                settingFormData={settingFormData}
                setSettingFormData={setSettingFormData}
              />
            </div>
          </Col>
        </Row>
        <hr />
        <Row>
          {theme == 2 || theme == 3 ? (
            <>
              <Row className="mt-4">
                <Col md="12 mb-3">
                  <h4 className="mb-3">Side image upload</h4>

                  <p>do you want to upload Background image</p>
                  <Switch
                    checked={backImageToggle2}
                    inputProps={{ "aria-label": "controlled" }}
                    onChange={(e) => {
                      setBackImageToggle2(e.target.checked);
                    }}
                  />
                  {backImageToggle2 && (
                    <>
                      {" "}
                      <Col md="6 mb-3">
                        <Dividers divide="IMAGE" />
                        <p className="mb-4">
                          File type required JPG, PNG (1905px X 574px)
                        </p>
                      </Col>
                      <Row>
                        <Col md="4 mb-3">
                          <div
                            className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center"
                            style={{ cursor: "pointer" }}
                          >
                            {!settingFormData?.["faqsSideImage"] && (
                              <span
                                onClick={() => {
                                  OpenModalCommonFunc(
                                    Cases.PRODUCT_UPLOAD,
                                    setAllPopupState
                                  );
                                  setIsImageUpload((prev) => {
                                    return {
                                      isList: false,
                                      isDetails: true,
                                    };
                                  });
                                }}
                              >
                                <UploadCloud />
                              </span>
                            )}

                            {settingFormData?.["faqsSideImage"] && (
                              <img
                                src={settingFormData?.["faqsSideImage"] || ""}
                                alt=""
                                style={{
                                  width: "85%",
                                  height: "90px",
                                  objectFit: "contain",
                                  position: "absolute",
                                }}
                                onClick={() => {
                                  deleteImagePermanent("faqsSideImage");
                                }}
                              />
                            )}
                            {settingFormData?.["faqsSideImage"] && (
                              <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="close"
                                style={{
                                  color: "red",
                                  position: "absolute",
                                  top: "-22px",
                                  left: "1px",
                                }}
                                onClick={() =>
                                  deleteImagePermanent("faqsSideImage")
                                }
                              >
                                <Close
                                  style={{
                                    fontSize: "25px",
                                    fontWeight: "bold",
                                  }}
                                />
                              </IconButton>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </>
                  )}
                </Col>
              </Row>
            </>
          ) : (
            ""
          )}
        </Row>
        <CustomStyle
          handleChange={handleChangeCustomStyle}
          value={settingFormData?.custom}
        />
        <ProductImageUploadPopup
          allPopupState={allPopupState}
          setAllPopupState={setAllPopupState}
          crop={crop}
          setCrop={setCrop}
          completedCrop={completedCrop}
          setCompletedCrop={setCompletedCrop}
          scale={scale}
          rotate={rotate}
          image={settingFormData?.image}
          setImage={setSettingFormData}
          imageUpload={imageUpload}
          isEdit={isEdit}
          reupload={reupload}
          // aspect={3.32 / 1}
        />
      </Container>
    </>
  );
};

export default SettingFaqsPerentPopup;
