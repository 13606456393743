import React, { useContext } from "react";
import { Col, Input, Label, Row } from "reactstrap";
import { Border } from "../../../../constant";
import OurClientContext from "../../../context/OurClientContext/OurClientContext";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import ProductImageUploadPopup from "../../../Modals/imageUploade/productImageUploadPopup";
import { Cases, DELETE_CONFIRM } from "../../../constant";
import { delete_Multi_Image_Permanent } from "../apis/SliderApi/SliderApi";
import { Zoom, toast } from "react-toastify";
import Dividers from "../../../common/divider";
import { UploadCloud } from "react-feather";
import { OpenModalCommonFunc } from "../../../utils";
import { IconButton } from "@mui/material";
import { Close } from "@material-ui/icons";
import { Switch } from "@material-ui/core";
import CommonColor from "../../../common/CommonColor";
import CustomStyle from "../../../common/CustomStyle";

const OurClientTheme2 = (props) => {
  const {
    isEdit,
    settingFormData,
    setSettingFormData,
    backImageToggle,
    setBackImageToggle,
    boxSection,
    setBoxSection,
    isBackgroundColor,
    setIsBackgroundColor,
    setImageIs,
  } = useContext(OurClientContext);
  const {
    allPopupState,
    setAllPopupState,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
  } = useContext(ByteCardBuilderContext);
  const { imageUpload } = props;
  const { setLoaderShow } = useContext(LoaderContext);

  /**
   * input value change
   * @param {event} e
   */

  const handleChangeClient2Section = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".partner-section": {
          ...prev[".partner-section"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeClient2SectionTitleAreaSubtitle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".partner-section .imgdiv": {
          ...prev[".partner-section .imgdiv"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeCustomStyle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  /**
   * delete image permanently
   */
  const deleteImagePermanent = async () => {
    try {
      let confirmation = window.confirm(DELETE_CONFIRM);
      if (confirmation) {
        setLoaderShow(true);
        let formData = {
          fileName: settingFormData?.[".partner-section.bg-image"]?.[
            "background-image"
          ]
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
            .pop(),
        };
        const { data } = await delete_Multi_Image_Permanent(formData);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
            position: "top-center",
          });
          setSettingFormData({
            ...settingFormData,
            ".partner-section.bg-image": { "background-image": "" },
          });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setLoaderShow(false);
    }
  };

  /**
   * image reupload
   */
  const reupload = () => {
    setSettingFormData((prev) => {
      return {
        ...prev,
        image: "",
      };
    });
  };
  return (
    <>
      <Row className="mt-4">
        <h4 className="mb-3">Make change in Main section</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"padding (default : 100)"}
          </Label>
          <Input
            className="form-control"
            name="padding"
            type="number"
            placeholder="padding"
            value={settingFormData?.[".partner-section"]?.["padding"]
              ?.split(" ")[0]
              ?.replace(/px/g, "")}
            onChange={(e) => handleChangeClient2Section(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <div className="d-flex align-items-center">
            <Label htmlFor="validationCustom03">
              {isBackgroundColor
                ? "Add Background Color"
                : "Remove Background Color"}
            </Label>
            <Switch
              checked={isBackgroundColor}
              inputProps={{ "aria-label": "controlled" }}
              onChange={(e) => {
                setIsBackgroundColor(e.target.checked);
                setSettingFormData((prev) => {
                  if (e.target.checked) {
                    if (boxSection) {
                      return {
                        ...prev,
                        ".partner-section": {
                          "background-color": "#ffffff",
                          padding:
                            settingFormData?.[".partner-section"]?.["padding"],
                          width: settingFormData?.[".partner-section"]?.[
                            "width"
                          ]?.replace("rem)", ""),
                          margin: settingFormData?.[".partner-section"]?.[
                            "margin"
                          ]
                            ?.split(" ")[0]
                            ?.replace(/px/g, ""),
                          "border-radius": settingFormData?.[
                            ".partner-section"
                          ]?.["border-radius"]?.replace(/rem/g, ""),
                        },
                      };
                    } else {
                      return {
                        ...prev,
                        ".partner-section": {
                          "background-color": "#ffffff",
                          padding:
                            settingFormData?.[".partner-section"]?.["padding"],
                        },
                      };
                    }
                  } else {
                    if (boxSection) {
                      return {
                        ...prev,
                        ".partner-section": {
                          padding:
                            settingFormData?.[".partner-section"]?.["padding"],
                          width: settingFormData?.[".partner-section"]?.[
                            "width"
                          ]?.replace("rem)", ""),
                          margin: settingFormData?.[".partner-section"]?.[
                            "margin"
                          ]
                            ?.split(" ")[0]
                            ?.replace(/px/g, ""),
                          "border-radius": settingFormData?.[
                            ".partner-section"
                          ]?.["border-radius"]?.replace(/rem/g, ""),
                        },
                      };
                    } else {
                      return {
                        ...prev,
                        ".partner-section": {
                          padding:
                            settingFormData?.[".partner-section"]?.["padding"],
                        },
                      };
                    }
                  }
                });
              }}
            />
          </div>
          {isBackgroundColor && (
            <CommonColor
              color={
                settingFormData?.[".partner-section"]?.["background-color"]
              }
              setSettingFormData={setSettingFormData}
              className={".partner-section"}
              classProperty={"background-color"}
              label={"Select Background Color"}
            />
          )}
        </Col>
        <p>{"Box Section : convert your section to Box section"}</p>
        <div>
          <Switch
            checked={boxSection}
            inputProps={{ "aria-label": "controlled" }}
            onChange={(e) => {
              setBoxSection(e.target.checked);
              setSettingFormData((prev) => {
                return {
                  ...prev,
                  ".partner-section": {
                    padding: settingFormData?.[".partner-section"]?.["padding"]
                      ?.split(" ")[0]
                      ?.replace(/px/g, ""),
                    "background-color":
                      settingFormData?.[".partner-section"]?.[
                        "background-color"
                      ],
                    width: "10",
                    margin: "0",
                    "border-radius": "3",
                  },
                };
              });
            }}
          />
        </div>
        {boxSection && (
          <>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Width (Default : 10)"}
              </Label>
              <Input
                className="form-control"
                name="width"
                type="number"
                placeholder="width"
                value={settingFormData?.[".partner-section"]?.[
                  "width"
                ]?.replace("rem)", "")}
                onChange={(e) => handleChangeClient2Section(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Margin (Default : 0)"}
              </Label>
              <Input
                className="form-control"
                name="margin"
                type="number"
                placeholder="margin"
                value={settingFormData?.[".partner-section"]?.["margin"]
                  ?.split(" ")[0]
                  ?.replace(/px/g, "")}
                onChange={(e) => handleChangeClient2Section(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Border Radius (Default : 3)"}
              </Label>
              <Input
                className="form-control"
                name="border-radius"
                type="number"
                placeholder="Border Radius"
                value={settingFormData?.[".partner-section"]?.[
                  "border-radius"
                ]?.replace(/rem/g, "")}
                onChange={(e) => handleChangeClient2Section(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
          </>
        )}
      </Row>
      <hr />
      <Row className="mt-4">
        <Col md="12 mb-3">
          <h4 className="mb-3">Add Background Image in section</h4>

          <p>do you want to upload Background image</p>
          <Switch
            checked={backImageToggle}
            inputProps={{ "aria-label": "controlled" }}
            onChange={(e) => {
              setBackImageToggle(e.target.checked);
              setSettingFormData((prev) => {
                if (e.target.checked) {
                  return {
                    ...prev,
                    image: "",
                    ".partner-section.bg-image": {},
                    ".partner-section.bg-image::before": {
                      "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
                    },
                  };
                } else {
                  return {
                    ...prev,
                    image: "",
                    ".partner-section.bg-image": {},
                    ".partner-section.bg-image::before": {},
                  };
                }
              });
            }}
          />
          {backImageToggle && (
            <>
              {" "}
              <Col md="6 mb-3">
                <Dividers divide="IMAGE" />
                <p className="mb-4">
                  File type required JPG, PNG (1905px X 429px)
                </p>
              </Col>
              <Row>
                <Col md="4 mb-3">
                  <div
                    className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center"
                    style={{ cursor: "pointer" }}
                  >
                    {!settingFormData?.[".partner-section.bg-image"]?.[
                      "background-image"
                    ] && (
                      <span
                        onClick={() => {
                          OpenModalCommonFunc(
                            Cases.PRODUCT_UPLOAD,
                            setAllPopupState
                          );
                          setImageIs("ourClient2");
                        }}
                      >
                        <UploadCloud />
                      </span>
                    )}

                    {settingFormData?.[".partner-section.bg-image"]?.[
                      "background-image"
                    ] && (
                      <img
                        src={
                          settingFormData?.[".partner-section.bg-image"]?.[
                            "background-image"
                          ] || ""
                        }
                        alt=""
                        style={{
                          width: "85%",
                          height: "90px",
                          objectFit: "contain",
                          position: "absolute",
                        }}
                        onClick={() => deleteImagePermanent()}
                      />
                    )}
                    {settingFormData?.[".partner-section.bg-image"]?.[
                      "background-image"
                    ] && (
                      <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="close"
                        style={{
                          color: "red",
                          position: "absolute",
                          top: "-22px",
                          left: "1px",
                        }}
                        onClick={() => deleteImagePermanent()}
                      >
                        <Close
                          style={{
                            fontSize: "25px",
                            fontWeight: "bold",
                          }}
                        />
                      </IconButton>
                    )}
                  </div>
                </Col>
                <Col md="6 mb-3">
                  <h4 className="mb-3">Add Background color in section</h4>
                  <Col md="6 mb-3">
                    <CommonColor
                      color={
                        settingFormData?.[
                          ".partner-section.bg-image::before"
                        ]?.["background-color"]
                      }
                      setSettingFormData={setSettingFormData}
                      className={".partner-section.bg-image::before"}
                      classProperty={"background-color"}
                      label={"Select Background Color"}
                    />
                  </Col>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in section Div</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding (Default : 30 - 15)"}
          </Label>
          <div className="d-flex justify-content-between">
            <Input
              className="form-control"
              name="p1"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[".partner-section .imgdiv"]?.[
                "p1"
              ]?.replace(/px/g, "")}
              onChange={(e) => handleChangeClient2SectionTitleAreaSubtitle(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="p2"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[".partner-section .imgdiv"]?.[
                "p2"
              ]?.replace(/px/g, "")}
              onChange={(e) => handleChangeClient2SectionTitleAreaSubtitle(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </div>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Border "}</Label>
          <div className="d-flex ">
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="borderPx"
                type="number"
                placeholder="px"
                value={settingFormData?.[".partner-section .imgdiv"]?.[
                  "borderPx"
                ]?.replace(/px/g, "")}
                onChange={(e) => handleChangeClient2SectionTitleAreaSubtitle(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div style={{ width: "100px" }}>
              <select
                name="borderThik"
                id="borderThik"
                className="form-control"
                value={
                  settingFormData?.[".partner-section .imgdiv"]?.["borderThik"]
                }
                onChange={(e) => handleChangeClient2SectionTitleAreaSubtitle(e)}
                style={{ borderRadius: "0", color: "black" }}
              >
                <option value="">--select--</option>
                {Border.map((elem) => {
                  return <option value={elem.border}>{elem.border}</option>;
                })}
              </select>
            </div>
            <div>
              <CommonColor
                color={
                  settingFormData?.[".partner-section .imgdiv"]?.["borderColor"]
                }
                setSettingFormData={setSettingFormData}
                className={".partner-section .imgdiv"}
                classProperty={"borderColor"}
                label={"Border Color"}
              />
            </div>
          </div>
        </Col>
      </Row>
      <hr />
      <CustomStyle
        handleChange={handleChangeCustomStyle}
        value={settingFormData?.custom}
      />
      <ProductImageUploadPopup
        allPopupState={allPopupState}
        setAllPopupState={setAllPopupState}
        crop={crop}
        setCrop={setCrop}
        completedCrop={completedCrop}
        setCompletedCrop={setCompletedCrop}
        scale={scale}
        rotate={rotate}
        image={settingFormData?.image}
        setImage={setSettingFormData}
        imageUpload={imageUpload}
        isEdit={isEdit}
        reupload={reupload}
        // aspect={4.44 / 1}
      />
    </>
  );
};

export default OurClientTheme2;
